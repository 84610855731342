import {
  Paper,
  useTheme,
  IconButton,
  Divider,
  Tooltip,
  Stack,
  Popover,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  alpha,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditOffIcon from "@mui/icons-material/EditOff";
import EditIcon from "@mui/icons-material/Edit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SubtitlesOffOutlinedIcon from "@mui/icons-material/SubtitlesOffOutlined";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useReactFlow } from "reactflow";
import {
  TuneOutlined,
  LabelOffOutlined,
  LabelOutlined,
} from "@mui/icons-material";

import { useState } from "react";

import ConfigLocalPopover from "../configLocalPopover";
const ToolBarMaps = ({
  checkChanges,
  proceedUpdateMode = () => {},
  setOpenAlertModal = () => {},
  setFullScreem,
  fullScreem = false,
  setEditMode,
  editMode = false,
  setLockMode,
  lockMode = false,
  setShowLabel,
  showLabel = true,
  setShowLabelEnlace,
  showLabelEnlace = true,
  children,
  tipo = 0,
  setEasyMapsConfig = () => {},
  easyMapsConfig,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const styles = {
    content: {
      padding: "5px",
      position: "absolute",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      left: "10px",
      top: "10px",
      alignItems: "center",
      gap: 0.5,
      justifyContent: "center",
    },

    card: {
      borderRadius: "6px",
      border: "1px solid " + theme.palette.color.appBarBorder,
      padding: "5px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 0.75,
      backgroundColor: theme.palette.background.default,
      justifyContent: "center",
      zIndex: 10,
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      justifyContent: "center",
      alignItems: "center",
    },
    switch: {
      width: "100%",
      ml: "0px",
      mr: "0px",
    },
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, path, name) => {
    setEasyMapsConfig((data) => {
      if (data?.[path]) {
        data[path][name] = event.target.checked;
      } else {
        data = {};
        data[path] = { [name]: event.target.checked };
      }
      return { ...data };
    });
  };

  return (
    <Stack sx={styles.content}>
      <Paper elevation={0} sx={styles.card}>
        {children}

        <IconButtonCustom
          title={fullScreem ? "Sair da Tela Cheia" : "Tela Cheia"}
          iconOn={FullscreenIcon}
          iconOff={FullscreenExitIcon}
          variable={fullScreem}
          onclick={setFullScreem}
        />
        <IconButtonCustom
          title={
            editMode ? "Desativar modo de edição" : "Ativar modo de edição"
          }
          iconOn={EditIcon}
          iconOff={EditOffIcon}
          variable={editMode}
          onclick={setEditMode}
        />

        <IconButtonCustom
          title={showLabel ? "Ocultar Detalhes" : "Mostrar detalhes"}
          iconOn={SubtitlesOutlinedIcon}
          iconOff={SubtitlesOffOutlinedIcon}
          variable={showLabel}
          onclick={setShowLabel}
        />

        {tipo === 1 && (
          <IconButtonCustom
            title={""}
            iconOn={LabelOutlined}
            iconOff={LabelOffOutlined}
            variable={showLabelEnlace}
            onclick={setShowLabelEnlace}
          />
        )}
        <IconButtonCustom
          title={"Ajustes"}
          iconOn={TuneOutlined}
          iconOff={TuneOutlined}
          variable={Boolean(anchorEl)}
          sendEvent={true}
          onclick={handleClick}
        />
      </Paper>

      <ConfigLocalPopover
        position={fullScreem ? { top: 220, left: 70 } : { top: 280, left: 380 }}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setEasyMapsConfig={setEasyMapsConfig}
        easyMapsConfig={easyMapsConfig}
      />
    </Stack>
  );
};
const IconButtonCustom = ({
  title,
  iconOn: IconOn,
  iconOff: IconOff,
  variable,
  onclick = () => {},
  sendEvent = false,
}) => {
  let theme = useTheme();
  let styles = {
    // icon: {
    //   color: theme.palette.color.icons,
    // },
    button: {
      borderRadius: "5px",
      padding: "5px",
      backgroundColor: theme.palette.background.paperSelectedMaps,
      "&:hover": { backgroundColor: theme.palette.background.hover },
    },
  };
  return (
    <Tooltip title={title} placement="right">
      <IconButton
        aria-label="custom-icon-button"
        sx={{
          ...styles.button,
          ...(variable
            ? {
                color: theme.palette.primary.main,
              }
            : {}),
        }}
        onClick={(event) => onclick(sendEvent ? event : !variable)}
      >
        <IconOn />
        {/* {!variable ? <IconOn /> : <IconOff sx={styles.icon} />} */}
      </IconButton>
    </Tooltip>
  );
};
const InputZoomTop = () => {
  const { zoomIn, zoomOut } = useReactFlow();
  const styles = {
    button: {
      borderRadius: "5px",
      padding: "5px",
    },
  };
  return (
    <Stack>
      <IconButtonCustom
        title={"Zoom In"}
        iconOn={AddIcon}
        iconOff={AddIcon}
        variable={false}
        onclick={() => zoomIn()}
      />
      <Divider sx={{ width: "80%", ml: "10%" }} />
      <IconButtonCustom
        title={"Zoom Out"}
        iconOn={RemoveIcon}
        iconOff={RemoveIcon}
        variable={false}
        onclick={() => zoomOut()}
      />
    </Stack>
  );
};
const InputZoomGeo = ({ zoom }) => {
  const styles = {
    button: {
      borderRadius: "5px",
      padding: "5px",
    },
  };
  return (
    <Stack>
      <IconButtonCustom
        title={"Zoom In"}
        iconOn={AddIcon}
        iconOff={AddIcon}
        variable={false}
        onclick={() => zoom(1)}
      />
      <Divider sx={{ width: "80%", ml: "10%" }} />
      <IconButtonCustom
        title={"Zoom Out"}
        iconOn={RemoveIcon}
        iconOff={RemoveIcon}
        variable={false}
        onclick={() => zoom(-1)}
      />
    </Stack>
  );
};
ToolBarMaps.InputZoomGeo = InputZoomGeo;
ToolBarMaps.InputZoomTop = InputZoomTop;
export default ToolBarMaps;
