import dayjs from "dayjs";
import "dayjs/locale/pt-br";
// const _ = require("lodash");
// const moment = require("moment");

export function convertBitsToBytesBin(prevBits) {
  const bits = parseFloat(
    typeof prevBits === "string"
      ? prevBits.replace(" bits")
      : parseFloat(prevBits)
  );
  let bytes = bits / 8;
  let prefixes = [
    "bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];
  let index = 0;

  while (bytes >= 1024 && index < prefixes.length - 1) {
    bytes /= 1024;
    index++;
  }

  return `${bytes.toFixed(2)} ${prefixes[index]}`;
}
export function convertBitsToBytes(prevBits) {
  const bits = parseFloat(
    typeof prevBits === "string"
      ? prevBits.replace(" bits", "")
      : parseFloat(prevBits)
  );
  let bytes = bits / 8;
  let prefixes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let index = 0;

  while (bytes >= 1000 && index < prefixes.length - 1) {
    bytes /= 1000;
    index++;
  }

  return `${bytes.toFixed(2)} ${prefixes[index]}`;
}
export function converteUnidades(entrada, unidade) {
  let valor = parseFloat(
    typeof entrada === "string" ? entrada.replace(" bps", "") : entrada
  );
  let sufixos = "";
  if (unidade === "bps" || "B") {
    sufixos = ["", "K", "M", "G", "T", "P", "E"];
  } else {
    sufixos = ["", "k", "M", "G", "T", "P", "E"];
  }
  const isNegativo = valor < 0;
  valor = Math.abs(valor);
  let index = 0;
  while (valor >= 1000 && index < sufixos.length - 1) {
    valor /= 1000;
    index++;
  }
  const valorFormatado = valor.toFixed(2);
  const numeroFormatado =
    (isNegativo ? "-" : "") + valorFormatado + " " + sufixos[index] + unidade;

  return numeroFormatado;
}

export function tarifacaoMBitsPorSegundo(bps, valor) {
  const bits = parseFloat(bps?.toString().replace(" bps")) || parseFloat(bps);
  const result = (bits / Math.pow(1000, 2)) * valor;
  return result;
}
export function calculoExcedente(price, c95th, excess, limitTraffic) {
  const limiteExcedente = limitTraffic
    ? parseFloat(limitTraffic) * Math.pow(10, 6)
    : 0;
  const trafegoTotal =
    parseFloat(c95th?.toString().replace(" bps")) || parseFloat(c95th);
  const trafegoSemExcedente =
    trafegoTotal <= limiteExcedente ? trafegoTotal : limiteExcedente;
  const trafegoExcedente =
    trafegoTotal - limiteExcedente < 0 ? 0 : trafegoTotal - limiteExcedente;
  const result =
    (trafegoExcedente / Math.pow(1000, 2)) * excess +
    (trafegoSemExcedente / Math.pow(1000, 2)) * price;
  return result;
}
export function calculoExcedentePlanoFixo(price, c95th, excess, limitTraffic) {
  const limiteExcedente = limitTraffic
    ? parseFloat(limitTraffic) * Math.pow(10, 6)
    : 0;
  const trafegoTotal =
    parseFloat(c95th?.toString().replace(" bps")) || parseFloat(c95th);
  const trafegoExcedente =
    trafegoTotal - limiteExcedente < 0 ? 0 : trafegoTotal - limiteExcedente;
  const result =
    (trafegoExcedente / Math.pow(1000, 2)) * excess + parseFloat(price);
  return result;
}
export const convertTimeStr = (time) => {
  const newTime = dayjs(new Date(time)).toDate();
  return newTime.toLocaleTimeString();
};
export const convertDateStr = (date) => {
  const newDate = dayjs(new Date(date)).toDate();
  return newDate.toLocaleDateString();
};
export const agrupaMetricas = (host) => {
  let metricas = "";
  const {
    availability,
    maxValue,
    minValue,
    averageValue,
    c95th,
    timeCustom,
    zeroDown,
    agregado,
  } = host;

  if (availability)
    metricas += "tempo de disponibilidade, taxa de disponibilidade, ";
  if (maxValue) metricas += "valor máximo, ";
  if (minValue) metricas += "valor mínimo, ";
  if (averageValue) metricas += "valor médio, ";
  if (c95th) metricas += "tarifação 95th (noventa e cinco percentil), ";
  if (timeCustom) metricas += "horário personalizado, ";
  if (zeroDown) metricas += "valor mínimo para disponibilidade, ";
  if (agregado) metricas += "agregado, ";
  metricas = metricas.replace(/,\s*$/, "");

  return metricas || "Nenhuma";
};

// export const roundToIntervalEnd = (timestamp, intervalInMinutes) => {
//   const duration = moment.duration(intervalInMinutes, "minutes").asSeconds();
//   return Math.floor((timestamp + duration) / duration) * duration;
// };

// export const groupByInterval = (data, intervalInMinutes, fator = 1) => {
//   const groupData = (array) => {
//     return _.chain(array)
//       .groupBy((item) => roundToIntervalEnd(item.clock, intervalInMinutes))
//       .map((values, key) => ({
//         clock: key,
//         value: _.sumBy(
//           values,
//           (item) => parseFloat(item.value) / (intervalInMinutes * fator)
//         ),
//       }))
//       .value();
//   };
//   return groupData(data);
// };

export const maskCurrency = (value) => {
  const onlyDigits = value
    .split("")
    .filter((s) => /\d/.test(s))
    .join("")
    .padStart(3, "0");
  const digitsFloat = parseFloat(
    onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
  );

  return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
    digitsFloat
  );
};
