import { converteUnidades } from "../utils";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import React, { useState } from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
// const COLOR_CHART = "#5B2E87";
const COLOR_CHART = "#585858";
export default function TrafficChart({ data, c95th, limitTraffic, plan }) {
  const { unit, setUnit } = useState("b");

  const formatDate = (tickItem) => {
    const result = `${new Date(tickItem).toLocaleDateString()} ${new Date(
      tickItem
    ).toLocaleTimeString()}`;
    return result;
  };

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    //let data = payload.value.split("T")[0];
    let time = dayjs(payload.value).format("DD/MM/YYYY");
    //time = time.split(":")[0] + ":" + time.split(":")[1];
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={20}
          y={10}
          //dy={50}
          fontSize={12}
          textAnchor="end"
          fill="black"
          transform="rotate(-20)"
        >
          {/* {payload.value.replace("T", " ").replace(".000Z", "")} */}
          {time}
        </text>
      </g>
    );
  };
  const CustomizedYaxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          //dy={50}
          fontSize={13}
          textAnchor="end"
          fill="black"
          //transform="rotate(-60)"
        >
          {converteUnidades(payload.value, "bps")}
        </text>
      </g>
    );
  };
  const CustomLabel = (props) => {
    return (
      <g transform={`translate(${props.x},${5})`}>
        <line
          x1={0} // coordenada x inicial da linha
          y1={5} // coordenada y inicial da linha
          x2={24} // coordenada x final da linha
          y2={5} // coordenada y final da linha
          stroke="#e83750" // cor da linha
          strokeWidth={1.5} // largura da linha
          strokeDasharray={props.dasharray} // configuração de traço e espaço
        />
        <text
          x={30} // coordenada x do texto (levando em conta a linha)
          y={10}
          fontSize={15}
          fill="black"
        >
          {props.value}
        </text>
      </g>
    );
  };
  const intervalo = parseInt(data.length / 16);
  return (
    <Box>
      <AreaChart
        data={data}
        width={790}
        height={210}
        margin={{ top: 23, right: 20, left: 15, bottom: 10 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={COLOR_CHART} stopOpacity={1} />
            <stop offset="95%" stopColor={COLOR_CHART} stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="datetime"
          type="category"
          interval={intervalo}
          height={50}
          tick={<CustomizedAxisTick />}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          domain={[0, Math.max(...data.map((a) => a.value))]}
          tick={<CustomizedYaxisTick />}
        />
        {c95th &&
          (plan
            ? parseFloat(c95th.replace(" bps", "")) >
              limitTraffic * Math.pow(10, 6)
            : true) && (
            <ReferenceLine
              y={parseFloat(c95th?.replace(" bps")) || parseFloat(c95th)}
              label={
                <Label
                  value="95th percentil"
                  x={510}
                  dasharray={"0"}
                  content={CustomLabel}
                />
              }
              stroke="#e83750"
              color={"#00000"}
            />
          )}
        {limitTraffic && (
          <ReferenceLine
            strokeDasharray={"3 3"}
            y={parseFloat(limitTraffic) * Math.pow(10, 6)}
            label={
              <Label
                value="Tráfego Limite"
                x={650}
                dasharray={"4"}
                content={CustomLabel}
              />
            }
            stroke="#e83750"
            color={"#00000"}
          />
        )}
        <Area
          type="monotone"
          dataKey="value"
          stroke={COLOR_CHART}
          fill="url(#colorUv)"
          isAnimationActive={false}
        />
      </AreaChart>
    </Box>
  );
}
