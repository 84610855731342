import { alpha } from "@mui/material";
import { PieChart, Pie, Cell } from "recharts";

//const COLOR_CHART = "#260d40";
const COLOR_CHART = "#1f1f1f";

const renderCustomizedLabel = (props) => {
  const RADIAN = Math.PI / 180;

  let { cx, cy, midAngle, outerRadius, fill, payload, percent } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const delta = Math.abs(1 / cos) + 10;
  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + delta) * cos;
  const my = cy + (outerRadius + delta) * sin;
  let ex = mx + Number(cos) * 20;
  let ey = my;
  //gabiarra
  if (ey > 280) ey = 280;
  if (ey < 20) ey = 20;
  //console.log(ey);

  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        //stroke={addAlpha(theme.palette.common.white, 0.2)}
        fill="none"
      />

      <text x={ex + (cos >= 0 ? 1 : -1)} y={ey + 6} textAnchor={textAnchor}>
        {`${payload.name}: \n ${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

export default function ChartPie({ data, tipo = 0 }) {
  const COLORS = ["#260d40", "#49236f", "#6b389e", "#8346c0", "#bbb1c4"];

  const colorGradientChart = [
    alpha(COLOR_CHART, 1),
    alpha(COLOR_CHART, 0.8),
    alpha(COLOR_CHART, 0.6),
    alpha(COLOR_CHART, 0.4),
    alpha(COLOR_CHART, 0.2),
  ];

  const formatData = (percent) => {
    return [
      {
        name: "Disponível",
        value: parseFloat(percent.replace("%", "")),
        status: true,
      },
      {
        name: "Indisponível",
        value: 100.0 - parseFloat(percent.replace("%", "")),
        status: false,
      },
    ];
  };
  if (tipo === 0) {
    data = formatData(data);
  }

  return (
    <PieChart width={600} height={300}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={60}
        label={renderCustomizedLabel}
        outerRadius={100}
        fill="#8884d8"
        isAnimationActive={false}
        dataKey="value"
        startAngle={-270}
        endAngle={90}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={colorGradientChart[index % colorGradientChart.length]}
          />
        ))}
      </Pie>
    </PieChart>
  );
}
