import {
  Paper,
  Typography,
  Stack,
  Box,
  Link,
  useTheme,
  Tooltip,
  Button,
  Chip,
  Divider,
  alpha,
  darken,
  lighten,
} from "@mui/material";

import IconTransform from "../../iconsElement";
import {
  AssignmentIndOutlined,
  AssignmentOutlined,
  ReportProblem,
} from "@mui/icons-material";
export default function CardONU(props) {
  const { onu, search = () => {}, iconsElements = [] } = props;
  const theme = useTheme();

  const SvgMenu = ({ sx }) => {
    return (
      <svg
        style={sx}
        width="190"
        height="40"
        viewBox="0 0 163 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.50119H127.163L163 22.5012H157.798L126.007 2.5477H0V0.50119Z"
          fill={theme.palette.color.iconsDisable}
        />{" "}
        {/* Primeiro path */}
        <path
          d="M146.799 21.8481L120 4.50119H124.937L157.377 24.9094L163 24.8261L160.904 29.5012H154.556L146.799 24.9094V21.8481Z"
          fill={theme.palette.primary.main}
        />{" "}
        {/* Segundo path */}
      </svg>
    );
  };

  const styles = {
    icon: {
      pointerEvents: "none",
      marginBottom: "-32px",
    },
    title: {
      fontSize: "1em",
      maxWidth: "150px",
      overflow: "hidden",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },

    alertTooltip: {
      mt: "5px",
      fontSize: "1.1em",
      maxWidth: "100%",
      textAlign: "left",
      whiteSpace: "wrap",
    },
    subTitle: {
      mt: "-3px",
      fontSize: "0.8em",
      maxWidth: "165px",
      overflow: "hidden",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: theme.palette.text.disabled,
    },

    cardlinked: {
      width: "100%",
      backgroundColor: theme.palette.background.paperSelectedMaps,
      boxShadow: "0px 0px 5px  #0000001a",
      position: "relative",
      padding: "0px 12px",
      display: "flex",
      direction: "row",
      height: "90px",
      alignItems: "center",
      justifyContent: "left",
      gap: 1.5,
      "&:hover": {
        backgroundColor: theme.palette.background.hover,
      },
    },
    textCardType: {},
    cardType: {
      gap: 0.2,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      right: 4,
      top: 5,
      backgroundColor: alpha(theme.palette.color.alerts.warning, 0.2),
      color:
        theme.palette.mode != "dark"
          ? darken(theme.palette.color.alerts.warning, 0.2)
          : lighten(theme.palette.color.alerts.warning, 0.1),

      //width: "80px",
      p: "3px 5px",
      fontSize: "0.8em",
      borderRadius: "2px",

      textTransform: "none",
    },
  };
  const text = () => {
    let alert = !onu.hasCto
      ? "A ONU não possui uma CTO cadastrada! Não é possível exibi-la no mapa ou acessar seus dados."
      : !onu.isValid
      ? "A ONU não possui uma geolocalização cadastrada! Não é possível exibi-la no mapa, mas seus dados estão visíveis na aba de ONUs da sua CTO."
      : null;
    return (
      <Box>
        <Typography
          sx={{ ...styles.title, fontSize: "1.1em", maxWidth: "100%" }}
        >
          {onu.titulo}
        </Typography>
        <Typography
          sx={{
            ...styles.subTitle,
            fontSize: "1em",
            maxWidth: "100%",
            color: "white",
          }}
        >
          {onu?.data.usuario?.value}
        </Typography>
        {alert && (
          <>
            <Divider />
            <Typography sx={styles.alertTooltip}>{alert}</Typography>
          </>
        )}
      </Box>
    );
  };

  return (
    <Tooltip title={text()} placement="left">
      <span style={{ display: "block" }}>
        <Button
          onClick={() => {
            search(onu.id);
          }}
          disabled={!onu.hasCto}
          sx={{
            padding: "0px",
            width: "97%",
          }}
        >
          <Paper
            elevation={0}
            sx={[styles.cardlinked, { opacity: !onu.hasCto ? 0.4 : 1 }]}
          >
            <IconTransform
              img={iconsElements.find((a) => a.id == onu.img)?.img}
              size={"35px"}
              color={theme.palette.color.icons}
            />

            <Stack direction={"column"}>
              <Stack direction="row" alignItems={"center"} gap={0.5}>
                <AssignmentOutlined
                  sx={{
                    fontSize: "13px",
                    color: "primary.main",
                  }}
                />
                <Typography fontSize="12px">
                  {onu?.data.contrato?.value}
                </Typography>
              </Stack>
              <SvgMenu selected={true} sx={styles.icon} />

              <>
                <Typography sx={styles.title}>{onu.titulo}</Typography>
                <Typography sx={styles.subTitle}>
                  {onu?.data.usuario?.value}
                </Typography>
              </>
            </Stack>
          </Paper>

          {(!onu.hasCto || !onu.isValid) && (
            <Stack sx={styles.cardType}>
              <ReportProblem sx={{ fontSize: "inherit" }} />
              <Typography fontSize={"inherit"} lineHeight={0}>
                Sem {!onu.hasCto && "CTO"} {!onu.hasCto && !onu.isValid && "e"}{" "}
                {!onu.isValid && "Coodernadas"}{" "}
              </Typography>
            </Stack>
          )}
        </Button>
      </span>
    </Tooltip>
  );
}
