import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import IconTransform from "../../iconsElement";
import { useTheme } from "@emotion/react";
import { CheckBox, North, South } from "@mui/icons-material";

export default function MenuSort({
  options = [],
  setSort = () => {},
  sort = 0,
  direction = 0,
  setDirection = () => {},
  anchorEl,
  setAnchorEl = () => {},
}) {
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        "& .MuiMenu-list": {
          p: "0px",
        },
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Stack sx={{ padding: "12px", minWidth: "170px" }}>
        <Typography>Ordenar por</Typography>
        <Stack sx={{ paddingX: "10px" }}>
          <FormGroup>
            {options.map((op, index) => (
              <Stack
                direction={"row"}
                gap={1}
                alignItems={"center"}
                key={index + "menus"}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={sort == index}
                      onClick={() => {
                        if (sort == index) {
                          setDirection(!direction);
                        }
                      }}
                      onChange={() => {
                        setSort(index);
                      }}
                    />
                  }
                  label={op}
                />
                {sort == index && (
                  <Tooltip title={direction == 0 ? "Crescente" : "Descrecente"}>
                    <IconButton
                      onClick={() => {
                        setDirection(!direction);
                      }}
                      size="small"
                      color="primary"
                      sx={{ ml: "-20px" }}
                    >
                      {direction == 0 ? (
                        <North fontSize="15px" />
                      ) : (
                        <South fontSize="15px" />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            ))}
          </FormGroup>
        </Stack>
      </Stack>
    </Menu>
  );
}
