const useStyles = (theme) => ({
  switch: {
    ml: "0px",
    mr: "0px",
    p: "10px",
    width: "250px",
    borderRadius: "5px",

    backgroundColor: theme.palette.background.paperSelectedMaps,
  },
  textAdorment: {
    ml: "-5px",
    fontSize: "12px",
    fontWeight: 800,
    color: theme.palette.text.disabled,
    width: "100%",
  },
  container: {
    borderRadius: "6px",
    p: "15px",
    backgroundColor: theme.palette.background.default,
    border: "1px solid" + theme.palette.background.paperLinkedMaps,
    justifyContent: "center",
    alignItems: "start",

    gap: 1,
  },
  title: {
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    fontSize: "16px",
    fontWeight: 500,
    mb: "5px",
  },
});

export default useStyles;
