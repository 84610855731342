import { Text, View, Image } from "@react-pdf/renderer";
import { styles } from "./../styles";
import {
  agrupaMetricas,
  calculoExcedente,
  calculoExcedentePlanoFixo,
  convertBitsToBytes,
  convertBitsToBytesBin,
  converteUnidades,
  tarifacaoMBitsPorSegundo,
} from "../../utils";
const RowTrafic = ({ topic, content }) =>
  (content || content == 0) && (
    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
      <Text style={styles.textBold}>{topic}</Text>
      <Text style={styles.text}>{content}</Text>
    </View>
  );

const RowTarifacao = ({ price, c95th, excess, plan, limitTraffic }) => {
  const limiteExcedente = limitTraffic
    ? parseFloat(limitTraffic) * Math.pow(10, 6)
    : 0;
  const trafegoTotal = c95th ? parseFloat(c95th.replace(" bps")) : "";
  const trafegoSemExcedente =
    trafegoTotal <= limiteExcedente ? c95th : limiteExcedente;
  const trafegoExcedente =
    trafegoTotal - limiteExcedente < 0 ? 0 : trafegoTotal - limiteExcedente;
  return (
    <>
      {price && c95th && (
        <>
          <RowTrafic
            topic={"Tráfego 95th percentil : "}
            content={converteUnidades(c95th, "bps")}
          />
          {plan ? (
            <RowTrafic
              topic={"Preço do plano fixo: "}
              content={`${parseFloat(price).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`}
            />
          ) : (
            <RowTrafic
              topic={"Preço por Mbps:"}
              content={`${parseFloat(price).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`}
            />
          )}
          {excess && plan ? (
            <>
              <RowTrafic
                topic={"Preço por Mbps de tráfego excedente : "}
                content={`${parseFloat(excess).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Limite de tráfego sem cobrança de excedente: "}
                content={`${converteUnidades(limiteExcedente, "bps")}`}
              />
              <RowTrafic
                topic={"Valor do excedente: "}
                content={`${tarifacaoMBitsPorSegundo(
                  trafegoExcedente,
                  excess
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Valor final: "}
                content={`${calculoExcedentePlanoFixo(
                  price,
                  c95th,
                  excess,
                  limitTraffic
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
            </>
          ) : excess ? (
            <>
              <RowTrafic
                topic={"Preço por Mbps de tráfego excedente : "}
                content={`${parseFloat(excess).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Limite de tráfego sem cobrança de excedente: "}
                content={`${converteUnidades(limiteExcedente, "bps")}`}
              />
              <RowTrafic
                topic={"Valor sem adicional de excedente: "}
                content={`${tarifacaoMBitsPorSegundo(
                  trafegoSemExcedente,
                  price
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Valor do excedente: "}
                content={`${tarifacaoMBitsPorSegundo(
                  trafegoExcedente,
                  excess
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Valor final: "}
                content={`${calculoExcedente(
                  price,
                  c95th,
                  excess,
                  limitTraffic
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
            </>
          ) : (
            <RowTrafic
              topic={"Valor total : "}
              content={`${tarifacaoMBitsPorSegundo(c95th, price).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}`}
            />
          )}
        </>
      )}
    </>
  );
};
const RowHorarioPersonalizado = ({ status, horaInicial, horaFinal }) => {
  return (
    <>
      {status && (
        <RowTrafic
          topic={"Hora personalizada: "}
          content={`${horaInicial} - ${horaFinal}`}
        />
      )}
    </>
  );
};
export default function ReportTrafic({
  itemName,
  hosts,
  horaInicial,
  horaFinal,
  dataFinal,
  dataInicial,
  unit,
  graphcs,
  indiceGraphcs,
  etapa,
  etapas,
  ultimaData,
  index,
}) {
  const metricas = agrupaMetricas(hosts[0]);
  return (
    <>
      <View break>
        <Text style={styles.title}>{`ITEM ${
          index + 1
        }: ${itemName.toUpperCase()}`}</Text>
        <Text style={[styles.text, { textIndent: "20px" }]}>
          {`Para cálculos de ${itemName} associados ao equipamento(s) ${hosts
            .map((host) => host.hostName)
            .join(
              ", "
            )}, o SistemaMW usa as métricas: ${metricas}. O intervalo de coleta para o cálculo dessas métricas compreende o dia ${dataInicial} ${horaInicial} ao dia ${dataFinal} ${horaFinal}. `}
        </Text>
      </View>

      {hosts?.map(
        (
          {
            hostName,
            c95th,
            availability,
            maxValue,
            minValue,
            averageValue,
            timeCustom,
            price,
            trafficFull,
            excess,
            plan,
            limitTraffic,
            zeroDown,
          },
          index
        ) => (
          <View
            key={"traffic-view" + index}
            style={{ marginVertical: 10, width: "100%" }}
          >
            <View wrap={false}>
              <Text
                style={styles.subtitle}
              >{`Resultados para o host: ${hostName}`}</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                wrap={false}
              >
                <RowTarifacao
                  c95th={c95th}
                  price={price}
                  excess={excess}
                  plan={plan}
                  limitTraffic={limitTraffic}
                />
                <RowTrafic
                  topic={"Período do relatório: "}
                  content={
                    etapa === etapas && timeCustom?.status
                      ? `${dataInicial} ${timeCustom.horaInicial} à ${ultimaData} ${timeCustom.horaFinal}`
                      : timeCustom?.status
                      ? `${dataInicial} ${timeCustom.horaInicial} à ${dataFinal} ${timeCustom.horaFinal}`
                      : `${dataInicial} ${horaInicial} à ${dataFinal} ${horaFinal}`
                  }
                />
                {availability && (
                  <RowTrafic
                    topic={"Tempo de análise: "}
                    content={availability?.rangeAnalyzed}
                  />
                )}
                {maxValue && (
                  <RowTrafic
                    topic={"Valor máximo: "}
                    content={converteUnidades(maxValue, unit)}
                  />
                )}
                {minValue && (
                  <RowTrafic
                    topic={"Valor mínimo: "}
                    content={converteUnidades(minValue, unit)}
                  />
                )}
                {averageValue && (
                  <RowTrafic
                    topic={"Valor médio: "}
                    content={converteUnidades(averageValue, unit)}
                  />
                )}
                {trafficFull && (
                  <RowTrafic
                    topic={"Tráfego total: "}
                    content={
                      unit === "bps"
                        ? convertBitsToBytesBin(trafficFull) +
                          " / " +
                          convertBitsToBytes(trafficFull)
                        : converteUnidades(trafficFull, unit)
                    }
                  />
                )}
                <RowHorarioPersonalizado {...timeCustom} />
                {availability && (
                  <>
                    <RowTrafic
                      topic={"Tempo de indisponibilidade: "}
                      content={availability?.downtime + ""}
                    />
                    <RowTrafic
                      topic={"Taxa de disponibilidade: "}
                      content={availability?.availability + ""}
                    />
                  </>
                )}
                {zeroDown?.status && (
                  <>
                    {" "}
                    <RowTrafic
                      topic={"Valor mínimo para disponibilidade: "}
                      content={converteUnidades(zeroDown?.valor, unit)}
                    />
                  </>
                )}
              </View>
            </View>
            {graphcs && (
              <View style={{ marginVertical: 5 }} wrap={false}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={styles.legendBold}
                  >{`Gráfico ${indiceGraphcs}: `}</Text>
                  <Text style={styles.legend}>{`${
                    unit == "bps"
                      ? "95th percentil do"
                      : "Dados coletados a partir do item"
                  }  '${itemName}' para o equipamento ${hostName} ao longo do tempo. `}</Text>
                </View>
                <Image
                  style={{ height: "auto", width: "500px" }}
                  src={`data:application/pdf;base64,${
                    graphcs?.find((graphc) => graphc.host == hostName)?.graphcs
                  }`}
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={styles.legend}
                  >{`Fonte: Dados coletados pelo Zabbix`}</Text>
                </View>
              </View>
            )}
          </View>
        )
      )}
    </>
  );
}
