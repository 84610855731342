import {
  Paper,
  useTheme,
  IconButton,
  Popover,
  Typography,
  Divider,
  alpha,
} from "@mui/material";
import IconTransform from "../../../../componentes/easyMaps/iconsElement";
import { Fragment } from "react";

export default function SubMenuMaps({
  checkChanges,
  setOpenAlertModal = () => {},
  openSub,
  setOpenSub,
  position,
  setElementSelected,
  iconsElement,
  setConnectionSelected,
  saveUpdateCallback = () => {},
}) {
  const theme = useTheme();
  const filterElements = () => {
    return iconsElement.filter((a) => a.nome != "_cto");
  };

  const ListElement = [
    "home",
    "cloud",
    "router",
    "antena",
    "server",
    "hub",
    "switch",
  ];
  const styles = {
    icon: {
      color: theme.palette.color.icons,
    },
    button: {
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      width: "80px",
      "&:hover": { backgroundColor: alpha(theme.palette.secondary.main, 0.3) },
    },

    card: {
      borderRadius: "7px",
      padding: "5px 5px",
      display: "flex",
      direction: "row",
      alignItems: "center",
      gap: "5px",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
      border: "1px solid " + theme.palette.color.appBarBorder,
    },
  };

  function createElement(tipo, img) {
    setOpenSub(false);
    setConnectionSelected(null);
    setElementSelected({ img: img, tipo: tipo });
  }

  return (
    <Popover
      id="basic-menu"
      anchorReference="anchorPosition"
      anchorPosition={{ top: position?.y - 30 || 0, left: position?.x || 0 }}
      open={openSub}
      sx={{
        "& .MuiPopover-paper": { backgroundColor: "transparent", p: "0px" },
      }}
      onClose={() => {
        setOpenSub(false);
      }}
      elevation={0}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Paper elevation={0} sx={styles.card}>
        {filterElements()?.map((icon, index) => (
          <Fragment key={"op" + index}>
            <IconButton
              key={icon.id}
              onClick={() => {
                if (checkChanges)
                  setOpenAlertModal({
                    open: true,
                    callback: (tipo, iconId) => {
                      saveUpdateCallback();
                      createElement(tipo, iconId);
                    },
                    params: [0, icon.id],
                    severity: "info",
                    buttonText: "Salvar",
                    buttonCancelText: "Voltar",
                    text: "As alterações feitas não foram salvas. Deseja Salvá-las?",
                  });
                else createElement(0, icon.id);
              }}
              sx={styles.button}
            >
              <IconTransform img={icon.img} />
              <Typography sx={{ fontSize: "0.875rem" }}>
                {icon.nome.replace("_", "")}
              </Typography>
            </IconButton>
            {filterElements().length - 1 != index && (
              <Divider orientation="vertical" sx={{ height: "20px" }} />
            )}
          </Fragment>
        ))}
      </Paper>
    </Popover>
  );
}
