import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  Fragment,
  useMemo,
} from "react";
import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import {
  LayerGroup,
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import simplify from "simplify-js";
import * as ReactDOMServer from "react-dom/server";
import ToolBarMaps from "../../../../../componentes/easyMaps/toolbarMapsPublicRp";
import L, { MarkerCluster } from "leaflet";
import "../../../../../componentes/leaflet-migrations";
import "../../../../../componentes/leaflet-fullscreen/Leaflet.fullscreen";
import "leaflet-routing-machine";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import RightBarEasyMaps from "../../../../../componentes/easyMaps/rightBarEasyMaps";
import TitleBar from "../../../../../componentes/easyMaps/titleBarPublic";
import { NodeIcon } from "./nodeIcon";
//import * as turf from "@turf/turf";
import ContextMenu from "../../../../../componentes/easyMaps/contextMenu";
import AlertModal from "../../../../../componentes/alertModal";
import { ClusterIcon } from "./clusterIcon";
import DescriptionBar from "../../../../../componentes/easyMaps/descriptionBar";
import { PolylineCustom } from "./polyLine";
import { useOutletContext } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

const LeafletMapa = forwardRef((props, ref) => {
  const {
    title,
    setFullScreem,
    fullScreem,
    elements = [],
    connections = [],
    setElementSelected = () => {},
    setElementONUSelected = () => {},
    connectionSelected,
    setConnectionSelected = () => {},
    elementSelected,
    elementONUSelected,
    iconsElements = [],
    mapId,
    setConnections,
    setSnackbar,
    editMode,
    setEditMode,
    checkChanges,
    setCheckChanges,
  } = props;

  const [lockMode, setLockMode] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [zoomIcons, setZoomIcons] = useState(5);
  const [contextMenu, setContextMenu] = useState(null);
  const [elementsConnected, setElementsConnected] = useState([]);
  const [elementsONUs, setElementsONUs] = useState([]);
  const [openRightBar, setOpenRightBar] = useState(false);
  const [contextMenuFlow, setContextMenuFlow] = useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(null);
  const [openConnection, setOpenConnection] = useState(null);
  const [showLabelEnlace, setShowLabelEnlace] = useState(true);
  const mapRef = useRef(null);
  const theme = useTheme();
  const { config, configLocal, setConfigLocal } = useOutletContext();
  // useEffect(() => {
  //   if (!elements) return;

  //   visibleItems();
  // }, [elements]);

  // function visibleItems() {
  //   if (!mapRef.current) return;
  //   if (elements.length === 0) return;

  //   const bounds = mapRef.current.getBounds();
  //   const marginFactor = 0.8; // Fator para aumentar os limites em 10% ao redor

  //   // Expande os limites com base no fator de margem
  //   const extendedBounds = bounds.pad(marginFactor);

  //   // Filtra os elementos visíveis dentro dos limites expandidos
  //   const visible = [...elements, ...elementsONUs].filter((element) => {
  //     const lat = element?.elementConfig?.config?.coordenadas?.lat;
  //     const long = element?.elementConfig?.config?.coordenadas?.long;
  //     return lat && long && extendedBounds.contains(L.latLng(lat, long));
  //   });

  //   if (visible.length !== elementsVisible?.length) {
  //     setElementsVisible(visible);
  //   }
  // }

  useEffect(() => {
    if (!mapRef.current) return;
    let size = map_range(mapRef.current.getZoom(), 0, 15, 2, 7);
    setZoomIcons(size);
    SetMaxBounds();
    mapRef.current.invalidateSize();
  }, [mapRef.current]);

  useEffect(() => {
    if (!elementSelected?.id) setElementSelected(null);
    if (editMode == false) {
      setElementSelected((data) => elements.find((e) => e?.id == data?.id));
    }

    setConnectionSelected(null);
    setElementsConnected(null);
  }, [editMode, showLabel]);

  useEffect(() => {
    if (!elementSelected?.id) {
      setElementsONUs([]);
      setElementONUSelected(null);
      return;
    }
    let cood = elementSelected?.elementConfig?.config?.coordenadas;
    if (cood.lat != null && cood.long != null)
      focusZoomCoordenates(cood.lat, cood.long); // precisava ser mais rapido por isso não usei focusZoom()
    setElementsONUs(elementSelected.elementsClients);
  }, [elementSelected]);

  useEffect(() => {
    if (!elementONUSelected) return;
    let cood = elementONUSelected?.elementConfig?.config?.coordenadas;
    if (cood.lat != null && cood.long != null)
      focusZoomCoordenates(cood.lat, cood.long); // precisava ser mais rapido por isso não usei focusZoom()
  }, [elementONUSelected]);

  const SetMaxBounds = () => {
    if (!mapRef.current) return;

    var southWest = L.latLng(-89.98155760646617, -180),
      northEast = L.latLng(89.99346179538875, 180);
    var bounds = L.latLngBounds(southWest, northEast);

    mapRef.current.setMaxBounds(bounds);

    return null;
  };

  const styles = {
    paperContainer: {
      padding: "16px ",
      display: "flex",
      alignItems: "center",
      position: "relative",
      width: "100%",
      borderRadius: 3,
      mt: 5.5,
    },
    body: {
      width: "100%",
      height: "100%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      left: "0px",
      top: "0px",
      //zIndex: 0,
      overflow: "hidden",
    },

    mapInfoStack: {
      padding: "12px 16px",
      width: "250px",
    },
    mapInfoDataStack: {
      padding: "12px 16px",
      width: "120px",
    },
    mapInfoTitle: {
      fontSize: "0.75rem",
      lineHeight: 1.25,
      fontWeight: 600,
      letterSpacing: "0.033em",
      color: "primary.main",
    },
    mapInfoText: {
      fontSize: "0.875rem",
      lineHeight: 1.5,
      fontWeight: 400,
      textTransform: "capitalize",
    },
    fontPopup: {
      margin: "0px",
      fontSize: "10px",
      fontWeight: 500,
      color: theme.palette.color.textEasyMaps,
    },
    titlePopup: {
      margin: "0px",
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.color.textEasyMaps,
      width: "100%",
      textAlign: "center",
      marginBottom: "10px",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limite de 2 linhas
      WebkitBoxOrient: "vertical",
    },
  };

  const formatPoints = (element) => {
    let nodeStart = null,
      nodeEnd = null;

    if (element.referenceId == elementSelected?.id) nodeStart = elementSelected;
    else
      nodeStart = [...elements, ...elementsONUs].find(
        (node) => node.id == element.referenceId
      );

    if (element.destinationId == elementSelected?.id) nodeEnd = elementSelected;
    else
      nodeEnd = [...elements, ...elementsONUs].find(
        (node) => node.id == element.destinationId
      );

    let nodeStartPosition = {
      lat: parseFloat(nodeStart?.elementConfig?.config?.coordenadas?.lat),
      long: parseFloat(nodeStart?.elementConfig?.config?.coordenadas?.long),
      active: false,
    };
    let nodeEndPosition = {
      lat: parseFloat(nodeEnd?.elementConfig?.config?.coordenadas?.lat),
      long: parseFloat(nodeEnd?.elementConfig?.config?.coordenadas?.long),
      active: false,
    };

    let points = [
      nodeStartPosition,
      ...(element.positionHandlers ?? []).map((a) => ({
        lat: a.y,
        long: a.x,
        active: false,
      })),
      nodeEndPosition,
    ];

    return points;
  };

  useImperativeHandle(ref, () => ({
    focusZoomExt(id) {
      if (!id) return;
      let element = elements.find((e) => e.id == id);
      if (element) {
        setElementSelected(element);
      } else {
        element = elements.find(
          (e) => !!e.elementsClients.find((client) => client.id == id)
        );
        if (!element) return;
        setElementSelected(element);
        setElementONUSelected(
          element?.elementsClients.find((client) => client.id == id)
        );
      }
    },
  }));

  function map_range(value, low1, high1, low2, high2) {
    return parseInt(low2 + ((high2 - low2) * (value - low1)) / (high1 - low1));
  }

  function zoom(action) {
    if (!mapRef.current) return;
    const map = mapRef.current;
    let zoom = map.getZoom();
    // garante que o mapa não fique deslizando
    if (zoom > 19 && action > 0) return;
    if (zoom < 1 && action < 0) return;
    map.setZoom(zoom + action);
  }
  function focusZoom(id) {
    if (!id) return;
    let element = elements.find((e) => e.id == id);
    if (!element) {
      element = elementsONUs.find((e) => e.id == id);
    }
    if (!element) return;
    let cood = element.elementConfig.config.coordenadas;
    if (cood.lat != null && cood.long != null)
      focusZoomCoordenates(cood.lat, cood.long);
  }
  function focusZoomCoordenates(lat, lng) {
    if (lat == null || lng == null) return;
    const map = mapRef.current;
    if (!elementSelected) map?.setView([lat, lng], 17);
    else map?.setView([lat, parseFloat(lng) + 0.0003], 17);
  }

  const onNodeClick = (event, node) => {
    setConnectionSelected(null);
    setOpenConnection(null);

    if (node?.id === elementSelected?.id) {
      setElementSelected(null);
      setElementsONUs([]);
      setElementONUSelected(null);
      return;
    }

    setElementSelected(JSON.parse(JSON.stringify(node)));
    setElementONUSelected(null);

    if (!editMode) {
      const selected = node;
      let idsElements = [selected?.id];
      let idsConnections = [];
      let elementsConnect = [];

      connections.forEach(({ referenceId, destinationId, id }) => {
        if (node?.id === destinationId || node?.id === referenceId) {
          const findElement = elements?.find(
            ({ id }) =>
              id === (node?.id === destinationId ? referenceId : destinationId)
          );
          elementsConnect.push(findElement);
          idsElements.push(findElement?.id);
          idsConnections.push(id);
        }
      });
      setElementSelected(JSON.parse(JSON.stringify(selected)));
      setElementsConnected(elementsConnect);
      setOpenRightBar(true);
    }
  };

  const onPaneClick = (e) => {
    setContextMenu(null);

    if (connectionSelected) setConnectionSelected(null);
    if (elementsConnected) setElementsConnected(null);
    if (elementSelected) {
      setElementSelected(null);
      setElementsONUs([]);
      setElementONUSelected(null);
    }
  };
  const onNodeContextMenu = useCallback(
    (event, node) => {
      //event.preventDefault();
      if (editMode) {
        setContextMenu(
          contextMenu === null
            ? {
                id: node.id,
                top: event.containerPoint.y + 60,
                left: event.containerPoint.x + 310,
                node,
              }
            : null
        );
      }
    },
    [setContextMenu, editMode]
  );

  const changeEdge = (edgeId, updateData) => {
    setConnections((cons) => {
      return cons.map((con) => {
        if (con.id === edgeId)
          return {
            ...con,
            config: {
              ...con.config,
              ...updateData,
            },
          };
        else return con;
      });
    });
  };

  // const updateEdgeEnd = (index, edgeUpdate) => {
  //   setCheckChanges(true);
  //   setConnectionSelected((data) => {
  //     data.positionHandlers[index] = edgeUpdate;
  //     return { ...data };
  //   });
  // };
  // const removeEdge = (index) => {
  //   setConnectionSelected((data) => {
  //     data.positionHandlers.splice(index, 1);
  //     return { ...data };
  //   });
  // };
  // function keepOnlyLastCall(fn, delay) {
  //   return function (...args) {
  //     if (timer.current) {
  //       clearTimeout(timer.current);
  //     } // Limpa o timeout anterior
  //     timer.current = setTimeout(() => fn(...args), delay); // Define um novo timeout
  //   };
  // }
  //const handleDragWithLastOnly = keepOnlyLastCall(visibleItems, 17); // Executa apenas a última após 20ms

  function Events() {
    const map = useMapEvents({
      click(e) {
        onPaneClick(e);
      },
      zoom(e) {
        let size = map_range(mapRef.current.getZoom(), 0, 15, 1, 9);
        setZoomIcons(size);
        //visibleItems();
        // prefetchTiles();
      },
      contextmenu(e) {
        L.DomEvent.stopPropagation(e);
      },
    });

    return null;
  }

  return (
    <>
      <Box sx={{ overflow: "visible", zIndex: 1202 }}>
        <TitleBar
          title={title}
          id={mapId}
          setSnackbar={setSnackbar}
          center={elementSelected != null || connectionSelected != null}
        />
      </Box>
      <Box sx={styles.body}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <ToolBarMaps
            setEasyMapsConfig={setConfigLocal}
            easyMapsConfig={configLocal}
            editMode={editMode}
            setEditMode={setEditMode}
            fullScreem={fullScreem}
            setFullScreem={setFullScreem}
            showLabel={showLabel}
            setShowLabel={setShowLabel}
            lockMode={lockMode}
            setLockMode={setLockMode}
            setShowLabelEnlace={setShowLabelEnlace}
            showLabelEnlace={showLabelEnlace}
            setOpenAlertModal={setOpenAlertModal}
            checkChanges={checkChanges}
            tipo={0}
          >
            <ToolBarMaps.InputZoomGeo zoom={zoom} />
          </ToolBarMaps>

          {!editMode && (
            <DescriptionBar
              key={"descrptionbar"}
              variant="ftth"
              elementSelected={!!elementSelected}
            />
          )}

          <MapContainer
            maxBoundsViscosity={1}
            worldCopyJump={true}
            key={fullScreem ? "mapaFull" : "mapaSingle"}
            fullscreenControl={false}
            ref={mapRef}
            id="map"
            center={[-10, -48]}
            zoom={4}
            inertia={true}
            boxZoom={true}
            minZoom={3}
            maxZoom={19}
            fadeAnimation={false}
            zoomControl={false}
            easeLinearity={0.6}
            doubleClickZoom={false}
            enableHighAccuracy={true}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 0,
            }}
          >
            <TileLayer
              url={
                theme.palette.mode == "dark"
                  ? config?.mapaTile?.dark ||
                    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  : config?.mapaTile?.light ||
                    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              }
              //url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom={19}
              detectRetina={true}
              //url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
              //satelite
              //url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              //attribution="&copy; OpenStreetMap contributors"
            />
            <Events />
            {elements.length > 0 && (
              <ClusterIcon
                noCluster={mapRef?.current?.getZoom() > 17 ? 0 : 50}
                key={"claster"}
                //zoom={8}
                mapRef={mapRef}
                showLabel={showLabel}
                //={!!elementSelected?.id}
                setElementSelected={setElementSelected}
                configLocal={configLocal}
              >
                {elements.map(
                  (element, index) =>
                    element?.id != elementSelected?.id && (
                      <NodeIcon
                        configLocal={configLocal}
                        //disable={!!elementSelected?.id}
                        key={element.id}
                        showLabel={showLabel}
                        //size={9}
                        iconsElement={iconsElements}
                        element={element}
                        color={theme.palette.color.icons}
                        editable={editMode}
                        onNodeClick={(e, element) => {
                          onNodeClick(null, element);
                        }}
                        onNodeContextMenu={onNodeContextMenu}
                        selected={element.id == elementSelected?.id}
                      />
                    )
                )}
              </ClusterIcon>
            )}
            {/* exibe as ONUs fora do cluster */}
            {elementsONUs
              .filter(
                (onu) =>
                  onu.elementConfig.config.coordenadas.lat != null &&
                  onu.elementConfig.config.coordenadas.long != null
              )
              .map(
                (element, index) =>
                  element?.id != elementONUSelected?.id && (
                    <NodeIcon
                      configLocal={configLocal}
                      //disable={!!elementONUSelected}
                      key={element.id}
                      showLabel={showLabel}
                      //size={9}
                      iconsElement={iconsElements}
                      element={element}
                      color={theme.palette.color.icons}
                      editable={false}
                      onNodeClick={(e, element) => {
                        L.DomEvent.stopPropagation(e);
                        setElementONUSelected(element);
                        setOpenConnection(element.id);
                      }}
                      onNodeContextMenu={onNodeContextMenu}
                      selected={false}
                    />
                  )
              )}

            {elementONUSelected && elementONUSelected?.isValid == true && (
              <NodeIcon
                configLocal={configLocal}
                disable={false}
                key={elementONUSelected.id}
                showLabel={showLabel}
                //size={9}
                iconsElement={iconsElements}
                element={elementONUSelected}
                color={theme.palette.color.icons}
                editable={false}
                onNodeClick={(e, element) => {
                  L.DomEvent.stopPropagation(e);
                  setElementONUSelected(null);
                  setOpenConnection(null);
                }}
                onNodeContextMenu={onNodeContextMenu}
                selected={true}
              />
            )}
            {elementSelected && (
              <NodeIcon
                configLocal={configLocal}
                //disable={false}
                key={elementSelected.id}
                showLabel={showLabel}
                //size={9}
                iconsElement={iconsElements}
                element={elementSelected}
                color={theme.palette.color.icons}
                editable={editMode}
                onNodeClick={() => {
                  setElementSelected(null);
                  setElementsONUs([]);
                  setElementONUSelected(null);
                }}
                onNodeContextMenu={onNodeContextMenu}
                selected={true}
              />
            )}
            {elementSelected &&
              connections
                .filter((conn) => {
                  const onu = elementsONUs.find(
                    (ele) => ele.id === conn.destinationId
                  );

                  return (
                    conn.referenceId === elementSelected.id &&
                    onu &&
                    onu.elementConfig?.config?.coordenadas?.lat != null &&
                    onu.elementConfig?.config?.coordenadas?.long != null
                  );
                })
                .map((conn, index) => {
                  let position = formatPoints(conn);
                  if (!editMode) {
                    let destination = elementSelected.elementsClients.find(
                      (a) => a.id == conn.destinationId
                    );

                    return (
                      <PolylineCustom
                        configLocal={configLocal}
                        variant="ftth"
                        percent={destination?.data?.potencia?.rx}
                        host={
                          [...elementSelected.elementsClients].find(
                            (ele) => ele.id == conn.destinationId
                          )?.titulo
                        }
                        data={{
                          ...conn?.data,
                          powerRx: destination?.data?.potencia?.rx,
                          powerTx: destination?.data?.potencia?.tx,
                        }}
                        isDown={!!!destination?.data?.status.value}
                        key={conn.id + "selected" + index}
                        positions={position.map((a) => [
                          a.lat || 0,
                          a.long || 0,
                        ])}
                        animation={"pontilhada"}
                        onClick={(e) => {
                          L.DomEvent.stopPropagation(e);
                          let elemento = elementsONUs.find(
                            (ele) => ele.id == conn.destinationId
                          );
                          setElementONUSelected(elemento);
                          setOpenConnection(elemento.id);
                        }}
                      />
                    );
                  }
                })}
          </MapContainer>

          <RightBarEasyMaps
            setElementONUSelected={setElementONUSelected}
            elementONUSelected={elementONUSelected}
            setFocusNodeId={focusZoom}
            open={openRightBar}
            setOpen={setOpenRightBar}
            elements={elements}
            //connections={elementsConnected}
            connections={connections}
            setElement={setElementSelected}
            setConnections={setElementsConnected}
            iconsElement={iconsElements}
            element={!editMode && elementSelected?.id ? elementSelected : null}
            fullScreem={fullScreem}
            openConnection={openConnection}
            setOpenConnection={setOpenConnection}
            link={`/easyMaps/${
              elementSelected?.mapRef?.tipo === 0 ? "geo" : "top"
            }/${elementSelected?.mapRefId ?? ""}`}
          />
        </Box>

        <ContextMenu
          contextMenu={contextMenuFlow}
          setContextMenu={setContextMenuFlow}
          editMode={editMode}
          setEditMode={setEditMode}
        />

        <AlertModal
          openModal={openAlertModal?.open ?? false}
          setOpenModal={(data) => setOpenAlertModal({ open: data })}
          buttonText={openAlertModal?.buttonText}
          buttonCancelText={openAlertModal?.buttonCancelText}
          confirmar={false}
          handleButton={() => {
            setCheckChanges(false);
            setOpenAlertModal({ open: false });
            if (openAlertModal.callback) {
              openAlertModal.callback(...(openAlertModal?.params || []));
              return;
            }
            setElementSelected(null);
            setElementONUSelected(null);
            setConnectionSelected(null);
          }}
          severity={openAlertModal?.severity}
          singleButton={false}
          textContent={openAlertModal?.text}
        />
      </Box>
    </>
  );
});

export default LeafletMapa;
