import {
  Paper,
  Stack,
  Tabs,
  Tab,
  Box,
  Divider,
  Typography,
  useTheme,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";

import React, { useEffect } from "react";
import { useState } from "react";
import CardMaps from "./cardMaps";
import CardAlerts from "./cardAlerts";
import CardHosts from "./cardHosts";

//icons
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import SearchIcon from "@mui/icons-material/Search";

import {
  AbcOutlined,
  Dangerous,
  Error,
  FilterList,
  FilterListOff,
  LocalFireDepartment,
  NotificationImportant,
  ReportProblem,
} from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default function SliderBarMaps(props) {
  const {
    map,
    iconsElements,
    setFocusNodeId = () => {},
    mapRef,
    elements,
  } = props;

  const [alertasTotais, setAlertasTotais] = useState([]);
  const [mapasConectados, setMapasConectados] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [filterHosts, setFilterHosts] = useState("");
  const [filterAlerts, setFilterAlerts] = useState(0);
  const [filterMaps, setFilterMaps] = useState("");
  const [valueTab, setValueTab] = useState(1);
  const theme = useTheme();
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const styles = {
    textAdorment: {
      ml: "-5px",
      fontSize: "12px",
      fontWeight: 800,
      color: theme.palette.color.bgIcon,
    },
    data: {
      overflow: "auto",
      height: "100%",
      boxSizing: "content-box", // Garante que o padding seja incluído
      paddingRight: "0.4rem",

      paddingBottom: "15px",
    },
    menuItem: {
      display: "flex",
      alignItems: "center",
    },
    iconItem: { mr: 1 },
    containerData: {
      overflow: "hidden",
      height: "100%",
    },
    paper: {
      borderRadius: "0px",
      height: "calc(100vh - 65px)",
      position: "sticky",
      top: "65px",
      maxWidth: "310px!important",
      minWidth: "310px!important",
      padding: "0px 10px",
      backgroundColor: theme.palette.background.slideBar,
      borderRight: "1px solid" + theme.palette.color.appBarBorder,
    },
    cardNoMaps: {
      background: theme.palette.background.paperSelectedMaps,
      height: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: "0.875rem",
      color: theme.palette.color.text,
      //textTransform: "none",
    },
    card: {
      borderRadius: "5px",
      backgroundColor: theme.palette.background.paperSelectedMaps,
      boxShadow: "0px 0px 3px  #0000001a",
      border: "none",
      "&::before": {
        display: "none",
      },
    },
    tabs: {
      "& button": {
        color: theme.palette.text.disabled,
        textTransform: "none",
        minHeight: "20px",
        Height: "100%",
        borderRadius: "5px",
        paddingX: "10px",
      },
      "& .MuiTab-icon": { marginRight: "2px" },

      "& button.Mui-selected": {
        backgroundColor: theme.palette.background.tab,
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
        border: "none",
      },
    },
  };
  useEffect(() => {
    setAlertasTotais(
      elements?.flatMap((ele) =>
        ele.alerts?.map((alert) => ({ ...alert, elementId: ele.id }))
      )
    );
    setMapasConectados(elements?.filter((e) => e.tipo == 1 || e.tipo == 2));
    setHosts(elements?.filter((e) => e.tipo == 0));
  }, [elements]);

  const CardNoMaps = () => {
    return (
      <Paper elevation={0} sx={styles.cardNoMaps}>
        <Typography
          sx={styles.text}
          color={theme.palette.mode == "dark" ? "#ffffff6e" : ""}
        >
          Não há mapas
        </Typography>
      </Paper>
    );
  };

  function search(id) {
    setFocusNodeId(id);
    if (mapRef?.current) {
      mapRef.current.focusZoomExt(id);
    }
  }

  return (
    <Stack sx={styles.paper}>
      <Stack direction="column" gap={2} height="calc(100vh - 65px)">
        <Stack sx={[styles.card, { p: 0.5, mt: 2, height: "50px" }]}>
          <Tabs
            variant="fullWidth"
            value={valueTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            sx={styles.tabs}
          >
            <Tab
              icon={valueTab == 1 ? <MapOutlinedIcon /> : null}
              label="Mapas"
              value={1}
              sx={styles.text}
              iconPosition={"start"}
            />
            <Tab
              sx={[styles.text, { "& .MuiTab-icon": { marginRight: "1px" } }]}
              icon={valueTab == 2 ? <BoltOutlinedIcon /> : null}
              label="Alertas"
              value={2}
              iconPosition={"start"}
            />
            <Tab
              sx={styles.text}
              icon={valueTab == 3 ? <StorageOutlinedIcon /> : null}
              label="Hosts"
              value={3}
              iconPosition={"start"}
            />
          </Tabs>
        </Stack>

        <Stack gap={2} sx={styles.containerData}>
          {valueTab == 1 && (
            <>
              <TextField
                id="outlined-basic"
                placeholder="Busca"
                autoComplete="off"
                variant="outlined"
                size="small"
                value={filterMaps}
                onChange={(event) => setFilterMaps(event.target.value)}
                sx={{ width: "100%" }}
                slotProps={{
                  input: {
                    endAdornment: <SearchIcon />,
                  },
                }}
              />
              {mapasConectados?.length == 0 && <CardNoMaps />}
              <Stack sx={styles.data} gap={1}>
                {mapasConectados
                  ?.filter((mapa) =>
                    mapa?.titulo
                      ?.toLowerCase()
                      ?.includes(filterMaps.toLowerCase())
                  )
                  ?.map((map, index) => (
                    <Button
                      onClick={() => {
                        search(map.id);
                      }}
                      sx={{
                        padding: "0px",
                      }}
                      key={"elementoMap" + index}
                    >
                      <CardMaps
                        key={"mapConnect" + index}
                        variant="linked"
                        title={map?.titulo}
                        alertas={map?.alerts?.length}
                        link={"#"}
                        tipo={map?.tipo}
                      />
                    </Button>
                  ))}{" "}
              </Stack>

              {/* <Stack direction="row" gap={2} alignItems="center">
                <MapOutlinedIcon color="primary" />
                <Typography sx={styles.text}>Mapas do Grupo</Typography>
            </Stack>*/}
            </>
          )}

          {valueTab == 2 && (
            <>
              <Stack direction="row" gap={2} alignItems="center">
                <FilterList color="primary" />
                <Typography sx={styles.text}>Filtros</Typography>
              </Stack>
              <TextField
                select
                size="small"
                value={filterAlerts}
                onChange={(event) => setFilterAlerts(event.target.value)}
                placeholder="Severidade"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography sx={styles.textAdorment}>ALERT</Typography>
                      </InputAdornment>
                    ),
                  },
                }}
              >
                <MenuItem value={0}>
                  <FilterListOff sx={{ mr: 1 }} />
                  Todos
                </MenuItem>
                <Divider />
                <MenuItem value={5}>
                  <LocalFireDepartment sx={{ mr: 1 }} />
                  Desastre
                </MenuItem>
                <MenuItem value={4}>
                  <Dangerous sx={{ mr: 1 }} />
                  Alto impacto
                </MenuItem>
                <MenuItem value={3}>
                  <Error sx={{ mr: 1 }} />
                  Moderado
                </MenuItem>
                <MenuItem value={2}>
                  <ReportProblem sx={{ mr: 1 }} />
                  Alerta
                </MenuItem>
              </TextField>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
                localeText={{
                  todayButtonLabel: "Agora",
                  clearButtonLabel: "Limpar",
                }}
              >
                <DateTimePicker
                  label={!dataInicial ? "Data inicial" : null}
                  value={dataInicial ? dayjs(dataInicial) : null}
                  maxDate={dayjs(dayjs().format("YYYY-MM-DD"))}
                  slotProps={{
                    textField: { size: "small" },
                    actionBar: { actions: ["clear", "accept"] },
                  }}
                  onChange={(newValue) =>
                    setDataInicial(newValue ? dayjs(newValue) : null)
                  }
                />
                <DateTimePicker
                  label="Data Final"
                  value={dataFinal ? dayjs(dataFinal) : null}
                  maxDate={dayjs(dayjs().format("YYYY-MM-DD"))}
                  onChange={(newValue) =>
                    setDataFinal(newValue ? dayjs(newValue) : null)
                  }
                  slotProps={{
                    textField: { size: "small" },
                    actionBar: { actions: ["today", "clear", "accept"] },
                  }}
                />
              </LocalizationProvider>

              <Stack direction={"column"} gap={1} sx={styles.data}>
                {alertasTotais
                  ?.filter((alert) => {
                    if (filterAlerts == 0) return true;
                    return alert.severity == filterAlerts;
                  })
                  .filter((alert) => {
                    return (
                      (dataFinal
                        ? dayjs(parseInt(alert.clock + "000"))
                            .second(0)
                            .isSameOrBefore(dataFinal)
                        : true) &&
                      (dataInicial
                        ? dayjs(parseInt(alert.clock + "000"))
                            .second(0)
                            .isSameOrAfter(dataInicial)
                        : true)
                    );
                  })
                  ?.map((alerta, index) => (
                    <CardAlerts
                      key={"aletas" + index}
                      alert={alerta}
                      setFocusNodeId={search}
                    />
                  ))}
              </Stack>
            </>
          )}

          {valueTab == 3 && (
            <>
              <TextField
                id="outlined-basic"
                placeholder="Busca"
                autoComplete="off"
                variant="outlined"
                size="small"
                value={filterHosts}
                onChange={(event) => setFilterHosts(event.target.value)}
                sx={{ width: "100%" }}
                slotProps={{
                  input: {
                    endAdornment: <SearchIcon />,
                  },
                }}
              />
              <Stack direction={"column"} gap={1} sx={styles.data}>
                {hosts
                  ?.filter((host) =>
                    host?.titulo
                      ?.toLowerCase()
                      ?.includes(filterHosts.toLowerCase())
                  )
                  ?.map((host, index) => (
                    <Button
                      onClick={() => {
                        search(host.id);
                      }}
                      sx={{
                        padding: "0px",
                      }}
                      key={"elementoHost" + index}
                    >
                      <CardHosts host={host} iconsElement={iconsElements} />
                    </Button>
                  ))}
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
