import {
  FormControlLabel,
  Popover,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import useStyles from "./styles";

const ConfigLocalPopover = ({
  position,
  anchorEl,
  setAnchorEl,
  setEasyMapsConfig = () => {},
  easyMapsConfig,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, path, name) => {
    setEasyMapsConfig((data) => {
      if (data?.[path]) {
        data[path][name] = event.target.checked;
      } else {
        data = {};
        data[path] = { [name]: event.target.checked };
      }
      return { ...data };
    });
  };

  return (
    <Popover
      id={"popoverConfig"}
      anchorReference="anchorPosition"
      anchorPosition={position}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        zIndex: 1501,
        "& .MuiPopover-paper": { backgroundColor: "transparent", p: "0px" },
      }}
      // }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <Stack sx={styles.container}>
        <Typography sx={styles.title}>Ajustes do Mapa</Typography>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={
                easyMapsConfig?.animacao?.alertas == null ||
                easyMapsConfig?.animacao?.alertas == true
                  ? true
                  : false
              }
            />
          }
          sx={styles.switch}
          labelPlacement="start"
          label={
            <Typography sx={[styles.textAdorment, { ml: 0.3 }]}>
              ANIMAÇÃO DE ALERTAS
            </Typography>
          }
          onChange={(event) => handleChange(event, "animacao", "alertas")}
        />
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={
                easyMapsConfig?.animacao?.linha == null ||
                easyMapsConfig?.animacao?.linha == true
                  ? true
                  : false
              }
            />
          }
          sx={styles.switch}
          labelPlacement="start"
          label={
            <Typography sx={[styles.textAdorment, { ml: 0.3 }]}>
              ANIMAÇÃO DE LINHAS
            </Typography>
          }
          onChange={(event) => handleChange(event, "animacao", "linha")}
        />
      </Stack>
    </Popover>
  );
};

export default ConfigLocalPopover;
