import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
} from "@mui/material";

import { HowToReg } from "@mui/icons-material";

import { useNotification } from "../../../componentes/notificationProvider";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import api from "../../../services/api";
import { validarEmail, validarSenha } from "../../../utils/validateFields";
import { LoadingButton } from "@mui/lab";

export default function ModalCreateUser(params) {
  const {
    openCreateUserModal,
    setOpenCreateUserModal,
    permissoes,
    role,
    rows,
    setRows,
  } = params;
  const notify = useNotification();
  const isSuperAdmin = role === "adm001";
  const [selectedPermissoes, setSelectedPermissoes] = useState(null);
  const [cadastroLogin, setCadastroLogin] = useState("");
  const [cadastroSenha, setCadastroSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [cadastroEmail, setCadastroEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const styles = {
    modal: { display: "flex", justifyContent: "center", alignItems: "center" },
    paper: {
      padding: 2,
      maxWidth: "432px",
      width: "90%",
      minWidth: "232px",
      maxHeight: "90vh",
      gap: 2,
      flexDirection: "column",
      display: "flex",
      alignItems: "felx-start",
    },
    formControl: {
      width: "100%",
      marginTop: "12px",
    },
    boxTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      color: "primary.main",
      width: "100%",
    },
    subtitle: {
      fontSize: "14px",
      fontWeight: 500,
      color: "text.primary",
    },
    dialogTitle: {
      padding: 0,
    },
    dialogActions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      gap: 0,
      padding: 0,
      width: "100%",
    },
    dialogContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: 0,
      gap: 1.5,
    },
    boxRowFields: {
      display: "flex",
      flexDirection: "column",
      with: "100%",
      gap: 1,
    },
    boxPasswords: {
      display: "flex",
      flexDirection: "column",
      with: "100%",
      gap: 2,
    },
    textfield: {
      width: "100%",
    },
    boxHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      mb: 1,
    },
  };

  async function handleApiNovoUsuario() {
    setIsSubmit(true);
    if (
      !cadastroEmail ||
      !cadastroSenha ||
      !cadastroLogin ||
      !confirmarSenha ||
      confirmarSenha !== cadastroSenha ||
      !selectedPermissoes?.value ||
      !validarEmail(cadastroEmail) ||
      !validarSenha(cadastroSenha)
    )
      return;
    try {
      setLoading(true);
      const response = await api.post("/usuario/create", {
        login: cadastroLogin,
        senha: cadastroSenha,
        email: cadastroEmail,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
        codigoPermissao: selectedPermissoes.value,
      });

      if (response.data) {
        setRows([...rows, response.data]);
        handleClose();
        notify("Dados salvos com sucesso!", "success");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error)
        notify(error.response.data.error, "error");
      else notify("Erro ao atualizar dados!", "error");
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setOpenCreateUserModal(false);
    setCadastroEmail("");
    setCadastroLogin("");
    setCadastroSenha("");
    setSelectedPermissoes(null);
    setShowPassword(false);
    setShowConfirmPassword(false);
    setConfirmarSenha("");
    setIsSubmit(false);
  };

  const handleClickShowPassword = (type) => {
    if (type === "password") {
      setShowPassword((show) => !show);
    } else {
      setShowConfirmPassword((show) => !show);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleApiNovoUsuario();
    }
  };
  return (
    <Modal
      open={openCreateUserModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={styles.modal}
    >
      <Paper sx={styles.paper}>
        <DialogTitle sx={styles.dialogTitle} color="primary.main">
          <Box sx={styles.boxHeader}>
            <Box sx={styles.boxTitle}>
              <IconButton size="small">
                <HowToReg sx={{ color: "primary.main" }} />
              </IconButton>
              <Typography fontWeight={500}>Cadastrar Usuário</Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenCreateUserModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Grid2 container columnSpacing={1.5} rowSpacing={2}>
            <Grid2 item size={{ xs: 12, sm: 12 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.subtitle}>Login</Typography>{" "}
                <TextField
                  size="small"
                  id="outlined-adornment-login"
                  type="text"
                  value={cadastroLogin}
                  onChange={(e) => setCadastroLogin(e.target.value)}
                  placeholder="Login"
                  error={isSubmit && !cadastroLogin}
                  helperText={isSubmit && !cadastroLogin && "Campo inválido"}
                  disabled={loading}
                />
              </Box>
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 12 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.subtitle}>Email</Typography>{" "}
                <TextField
                  size="small"
                  id="outlined-adornment-email"
                  type="email"
                  autoComplete="off"
                  value={cadastroEmail}
                  onChange={(e) => setCadastroEmail(e.target.value)}
                  placeholder="Email"
                  error={
                    isSubmit && (!cadastroEmail || !validarEmail(cadastroEmail))
                  }
                  helperText={
                    isSubmit &&
                    (!cadastroEmail || !validarEmail(cadastroEmail)) &&
                    "Formato de Email inválido"
                  }
                  disabled={loading}
                />
              </Box>
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 12 }}>
              <Box sx={styles.boxRowFields}>
                <Grid2 item size={{ xs: 12, sm: 12 }}>
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>Permissões</Typography>{" "}
                    <Autocomplete
                      id="multiple-limit-tags"
                      size="small"
                      value={selectedPermissoes?.value}
                      onChange={(e, value) => setSelectedPermissoes(value)}
                      options={permissoes}
                      getOptionLabel={(option) => option.name}
                      getOptionDisabled={(option) =>
                        !isSuperAdmin ? option.value === "adm001" : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={styles.textfield}
                          placeholder="Nível de acesso"
                          error={isSubmit && !selectedPermissoes?.value}
                          helperText={
                            isSubmit &&
                            !selectedPermissoes?.value &&
                            "Campo obrigatório"
                          }
                        />
                      )}
                      disabled={loading}
                    />
                  </Box>
                </Grid2>
                <Typography sx={styles.subtitle}>Senha</Typography>{" "}
                <Box sx={styles.boxPasswords}>
                  <TextField
                    size="small"
                    id="input-senha"
                    type={showPassword ? "text" : "password"}
                    autoComplete="off"
                    value={cadastroSenha}
                    onChange={(e) => setCadastroSenha(e.target.value)}
                    placeholder="senha"
                    slotProps={{
                      input: {
                        autoComplete: "new-password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              edge="end"
                              onClick={() =>
                                handleClickShowPassword("password")
                              }
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                    error={
                      isSubmit &&
                      (!cadastroSenha || !validarSenha(cadastroSenha))
                    }
                    helperText={
                      isSubmit &&
                      (!cadastroSenha || !validarSenha(cadastroSenha)) &&
                      "A senha tem que ter no mínimo 6 dígitos e uma letra maiúscula"
                    }
                    disabled={loading}
                  />
                  <TextField
                    size="small"
                    id="input-confirmar-senha"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmarSenha}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                    placeholder="Confirmar Senha"
                    slotProps={{
                      input: {
                        autoComplete: "new-password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              edge="end"
                              onClick={() =>
                                handleClickShowPassword("confirmPassword")
                              }
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                    error={
                      isSubmit &&
                      ((cadastroSenha && !confirmarSenha) ||
                        cadastroSenha !== confirmarSenha)
                    }
                    helperText={
                      isSubmit &&
                      ((cadastroSenha && !confirmarSenha) ||
                        cadastroSenha !== confirmarSenha) &&
                      "As senhas não conferem!"
                    }
                    disabled={loading}
                  />
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button
            sx={{ color: "primary.main" }}
            onClick={handleClose}
            variant="text"
            disabled={loading}
          >
            Cancelar
          </Button>
          <LoadingButton
            onClick={handleApiNovoUsuario}
            variant="contained"
            loading={loading}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Paper>
    </Modal>
  );
}
