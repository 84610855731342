import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  Fragment,
  useMemo,
} from "react";
import {
  Autocomplete,
  Box,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  LayerGroup,
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import simplify from "simplify-js";
import * as ReactDOMServer from "react-dom/server";
import ToolBarMaps from "../../../../../componentes/easyMaps/toolbarMaps";
import L from "leaflet";
import "../../../../../componentes/leaflet-migrations";
import "../../../../../componentes/leaflet-fullscreen/Leaflet.fullscreen";
import "leaflet-routing-machine";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import MenuMaps from "../../../../../componentes/easyMaps/menuMaps";
import RightBarEasyMaps from "../../../../../componentes/easyMaps/rightBarEasyMaps";
import CreationSliderBarEasyMaps from "../../../../../componentes/easyMaps/creationSidebarEasymaps";
import EnlaceSideBarEasyMaps from "../../../../../componentes/easyMaps/enlaceSidebarEasyMaps";
import TitleBar from "../../../../../componentes/easyMaps/titleBar";
import DescriptionBar from "../../../../../componentes/easyMaps/descriptionBar";
import NodeIcon from "./nodeIcon";
import PointIcon from "./pointIcon";
//import * as turf from "@turf/turf";
import ElementMenu from "../../../../../componentes/easyMaps/elementMenu";
import ContextMenu from "../../../../../componentes/easyMaps/contextMenu";
import AlertModal from "../../../../../componentes/alertModal";
import { convertBitsToBytes } from "../../../../../utils/easyMaps/convertToBytes";
import { PolylineCustom, PolylineSimple } from "./polyLine";
import { useOutletContext } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

const LeafletMapa = forwardRef((props, ref) => {
  const {
    title,
    setFullScreem,
    fullScreem,
    socketCliente,
    elements = [],
    connections = [],
    setElementSelected = () => {},
    connectionSelected,
    setConnectionSelected = () => {},
    elementSelected,
    iconsElements = [],
    setIconsElement,
    mapId,
    setElements,
    setConnections,
    produtos = [],
    api,
    setSnackbar,
    mapas = [],
    editMode,
    setEditMode,
    setMapas = () => {},
    updateDataElementSocket = () => {},
    checkChanges,
    setCheckChanges,
  } = props;

  const [lockMode, setLockMode] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [zoomIcons, setZoomIcons] = useState(5);
  const [contextMenu, setContextMenu] = useState(null);
  const [elementsConnected, setElementsConnected] = useState([]);
  const [openRightBar, setOpenRightBar] = useState(false);
  const [loadRouting, setLoadRouting] = useState(false);
  const [contextMenuFlow, setContextMenuFlow] = useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(null);
  const [openConnection, setOpenConnection] = useState(null);
  const [showLabelEnlace, setShowLabelEnlace] = useState(true);
  const mapRef = useRef(null);
  const creationRef = useRef();
  const enlaceBarRef = useRef();
  const { config, configLocal, setConfigLocal } = useOutletContext();
  const [pointsClone, setPointsClone] = useState([]);
  const [menuCreation, setMenuCreation] = useState(false);
  const [disableLines, setDisableLines] = useState(false);
  const theme = useTheme();

  const styles = {
    paperContainer: {
      padding: "16px ",
      display: "flex",
      alignItems: "center",
      position: "relative",
      width: "100%",
      borderRadius: 3,
      mt: 5.5,
    },
    body: {
      width: "100%",
      height: "100%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      left: "0px",
      top: "0px",
      //zIndex: 0,
      overflow: "hidden",
    },

    mapInfoStack: {
      padding: "12px 16px",
      width: "250px",
    },
    mapInfoDataStack: {
      padding: "12px 16px",
      width: "120px",
    },
    mapInfoTitle: {
      fontSize: "0.75rem",
      lineHeight: 1.25,
      fontWeight: 600,
      letterSpacing: "0.033em",
      color: "primary.main",
    },
    mapInfoText: {
      fontSize: "0.875rem",
      lineHeight: 1.5,
      fontWeight: 400,
      textTransform: "capitalize",
    },
    fontPopup: {
      margin: "0px",
      fontSize: "10px",
      fontWeight: 500,
      color: theme.palette.color.textEasyMaps,
    },
    titlePopup: {
      margin: "0px",
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.color.textEasyMaps,
      width: "100%",
      textAlign: "center",
      marginBottom: "10px",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limite de 2 linhas
      WebkitBoxOrient: "vertical",
    },
  };

  const points = useMemo(() => {
    if (connectionSelected) {
      let nodeStart = elements.find(
        (node) => node.id == connectionSelected.referenceId
      );
      let nodeEnd = elements.find(
        (node) => node.id == connectionSelected.destinationId
      );

      let nodeStartPosition = {
        lat: parseFloat(
          nodeStart?.elementConfig?.config?.coordenadas?.lat || 0
        ),
        long: parseFloat(
          nodeStart?.elementConfig?.config?.coordenadas?.long || 0
        ),
        active: false,
      };
      let nodeEndPosition = {
        lat: parseFloat(nodeEnd?.elementConfig?.config?.coordenadas?.lat || 0),
        long: parseFloat(
          nodeEnd?.elementConfig?.config?.coordenadas?.long || 0
        ),
        active: false,
      };

      let points = [
        nodeStartPosition,
        ...(connectionSelected.positionHandlers ?? []).map((a) => ({
          lat: a.y || 0,
          long: a.x || 0,
          active: false,
        })),
        nodeEndPosition,
      ];

      return points;
    } else {
      return [];
    }
  }, [connectionSelected]);

  useImperativeHandle(ref, () => ({
    focusZoomExt(id) {
      setElementSelected(elements.find((e) => e.id == id) ?? null);
      focusZoom(id);
    },
  }));

  useEffect(() => {
    if (pointsClone) {
      setPointsClone([...points]);
    } else {
      setPointsClone([]);
    }
  }, [points]);

  useEffect(() => {
    if (!elementSelected?.id) setElementSelected(null);
    if (editMode == false) {
      setElementSelected((data) => elements.find((e) => e?.id == data?.id));
    }
    setConnectionSelected(null);
    setElementsConnected(null);
  }, [editMode, showLabel]);

  useEffect(() => {
    if (menuCreation == false) setElementSelected(null);
    setElementsConnected(null);
  }, [menuCreation]);

  // function isPointInBounds(point) {
  //   if (!mapRef.current || point.lat == null || point.lng == null) return;
  //   const map = mapRef.current;
  //   // Obtém os limites do mapa (LatLngBounds)
  //   const bounds = map.getBounds();
  //   // Cria um objeto LatLng com o ponto fornecido
  //   const latLngPoint = L.latLng(point.lat, point.lng);

  //   // Verifica se o ponto está dentro dos limites
  //   return bounds.contains(latLngPoint);
  // }
  function isPointInBounds(point) {
    if (!point || point.lat == null || point.lng == null) return false;

    // Define os limites
    const southWest = L.latLng(-89.98155760646617, -180);
    const northEast = L.latLng(89.99346179538875, 180);
    const bounds = L.latLngBounds(southWest, northEast);

    // Cria um objeto LatLng com o ponto fornecido
    const latLngPoint = L.latLng(point.lat, point.lng);

    // Verifica se o ponto está dentro dos limites
    return bounds.contains(latLngPoint);
  }
  // -----------------------mapContext-----------------------

  function Events() {
    const map = useMap();
    useMapEvents({
      click(e) {
        onPaneClick(e);
      },
      zoomend(e) {
        setDisableLines(false);
        setZoomIcons(calculeZoom(map));
      },

      contextmenu(e) {
        onContextMenu(e);
      },
    });

    return null;
  }

  const SetZoom = () => {
    const map = useMap();
    useEffect(() => {
      if (!map) return;
      setZoomIcons(calculeZoom(map));
    }, [map]);

    return null;
  };

  const SetMaxBounds = () => {
    const map = useMap(); // Acesse o objeto `map`
    useEffect(() => {
      if (!map) return;

      // Define os limites máximos do mapa
      const southWest = L.latLng(-89.98155760646617, -180);
      const northEast = L.latLng(89.99346179538875, 180);
      const bounds = L.latLngBounds(southWest, northEast);

      map.setMaxBounds(bounds);
      map.invalidateSize();
    }, [map]);

    return null;
  };
  // ----------------------- fim mapContext-----------------------

  const calculeZoom = (map) => {
    return map_range(map.getZoom() || 0, 0, 19, 1, 9);
  };
  const formatPoints = (element) => {
    let nodeStart = null,
      nodeEnd = null;

    if (element.referenceId == elementSelected?.id) nodeStart = elementSelected;
    else nodeStart = elements.find((node) => node.id == element.referenceId);

    if (element.destinationId == elementSelected?.id) nodeEnd = elementSelected;
    else nodeEnd = elements.find((node) => node.id == element.destinationId);

    let nodeStartPosition = {
      lat: parseFloat(nodeStart?.elementConfig?.config?.coordenadas?.lat),
      long: parseFloat(nodeStart?.elementConfig?.config?.coordenadas?.long),
      active: false,
    };
    let nodeEndPosition = {
      lat: parseFloat(nodeEnd?.elementConfig?.config?.coordenadas?.lat),
      long: parseFloat(nodeEnd?.elementConfig?.config?.coordenadas?.long),
      active: false,
    };

    let points = [
      nodeStartPosition,
      ...(element.positionHandlers ?? []).map((a) => ({
        lat: a.y,
        long: a.x,
        active: false,
      })),
      nodeEndPosition,
    ];

    return points;
  };
  function map_range(value, low1, high1, low2, high2) {
    return parseInt(low2 + ((high2 - low2) * (value - low1)) / (high1 - low1));
  }
  const simplifyRoute = (points, tolerance) => {
    const simplifiedPoints = points.map((point) => ({
      x: point.lat,
      y: point.lng,
    }));
    const simplified = simplify(simplifiedPoints, tolerance, true);
    return simplified.map((point) => L.latLng(point.x, point.y));
  };
  const cleanRouting = (enlace) => {
    setConnectionSelected((data) => {
      data.positionHandlers = [];
      return { ...data };
    });
  };
  async function autoRouting(enlace) {
    if (!mapRef.current) return;
    setLoadRouting(true);
    const precissao = {
      0: 0.01,
      1: 0.001,
      2: 0.0001,
      3: 0.00001,
    };

    let coordenadasEnd = elements?.find((con) => con.id == enlace.destinationId)
      ?.elementConfig?.config?.coordenadas;
    coordenadasEnd = L.latLng(coordenadasEnd.lat, coordenadasEnd.long);

    let coordenadasStart = elements?.find((con) => con.id == enlace.referenceId)
      ?.elementConfig?.config?.coordenadas;
    coordenadasStart = L.latLng(coordenadasStart.lat, coordenadasStart.long);

    setConnectionSelected((conn) => {
      conn.positionHandlers = [];
      return { ...conn };
    });

    // Cria uma instância do L.Routing.control
    const router = new L.Routing.osrmv1();
    // Calcula a distância entre os pontos
    const distancia = L.latLng(coordenadasStart).distanceTo(coordenadasEnd);

    // Define o limiar de simplificação com base na distância
    let limiar = config?.limiarRouter != null ? config?.limiarRouter : 1;
    const distanciaKm = distancia / 1000;
    // Define o limiar de simplificação com base na distância
    if (distanciaKm > 1500) {
      limiar = Math.min(limiar, 0);
    } else if (distanciaKm > 400) {
      limiar = Math.min(limiar, 1);
    } else if (distanciaKm > 100) {
      limiar = Math.min(limiar, 2);
    }

    // Cria a rota
    router.route(
      [{ latLng: coordenadasStart }, { latLng: coordenadasEnd }],
      function (err, routes) {
        if (err) {
          console.error(err);
          return;
        }
        const points = routes[0].coordinates;
        const simplifiedPoints = simplifyRoute(points, precissao[limiar]);

        const pointsFormated = simplifiedPoints.map((point) => {
          return {
            x: point.lng,
            y: point.lat,
            active: false,
          };
        });
        setConnectionSelected((conn) => {
          conn.positionHandlers = pointsFormated;
          return { ...conn };
        });
        setLoadRouting(false);
      }
    );
  }
  //-------------- funcçoes de callbac dos alertas
  function newSelectionCallback(destination, tipo) {
    saveUpdateCallback();
    if (tipo == "elemento") {
      setConnectionSelected(null);
      onNodeClick(null, destination);
    } else if (tipo == "enlace") {
      setElementSelected(null);
      setConnectionSelected(
        connections.find((conn) => conn.id == destination.id)
      );
    }
  }
  function deleteCallback(id, tipo) {
    if (tipo == "enlace") handleDeleteLink(id);
    if (tipo == "elemento") handleDeleteElement(id);
  }
  const proceedUpdateMode = () => {
    saveUpdateCallback();
    setEditMode(!editMode);
    setFullScreem(false);
  };
  const saveUpdateCallback = () => {
    if (elementSelected) {
      creationRef?.current?.update();
    }
    if (connectionSelected) {
      enlaceBarRef?.current?.update();
    }
  };
  function zoom(action) {
    if (!mapRef.current) return;
    const map = mapRef.current;
    let zoom = map.getZoom();
    // garante que o mapa não fique deslizando
    if (zoom > 17 && action > 0) return;
    if (zoom < 1 && action < 0) return;
    map.setZoom(zoom + action);
  }
  function focusZoom(id) {
    if (!id) return;
    let element = elements.find((node) => node.id == id);
    if (!element) return;
    let cood = element.elementConfig.config.coordenadas;
    focusZoomCoordenates(cood.lat, cood.long);
  }
  function focusZoomCoordenates(lat, lng) {
    const map = mapRef.current;
    setDisableLines(true);
    map.flyTo([lat, lng], 14);
  }
  const onNodeClick = (event, node) => {
    setConnectionSelected(null);
    const selected = elements.find(({ id }) => id === node?.id);
    if (selected?.id === elementSelected?.id) {
      setElementSelected(null);
      return;
    }
    setElementSelected(JSON.parse(JSON.stringify(selected)));
    if (!editMode) {
      const selected = elements?.find(({ id }) => id === node?.id);
      let idsElements = [selected?.id];
      let idsConnections = [];
      let elementsConnect = [];

      connections.forEach(({ referenceId, destinationId, id }) => {
        if (node?.id === destinationId || node?.id === referenceId) {
          const findElement = elements?.find(
            ({ id }) =>
              id === (node?.id === destinationId ? referenceId : destinationId)
          );
          elementsConnect.push(findElement);
          idsElements.push(findElement?.id);
          idsConnections.push(id);
        }
      });
      setElementSelected(JSON.parse(JSON.stringify(selected)));
      setElementsConnected(elementsConnect);

      const updateEntangled = (isEntangled) => ({ entangled: isEntangled });

      setOpenRightBar(true);
    }
  };
  const onPaneClick = (e) => {
    setContextMenu(null);
    if (!editMode) {
      if (connectionSelected) setConnectionSelected(null);
      if (elementsConnected) setElementsConnected(null);
      if (elementSelected) setElementSelected(null);
    }
  };
  const onNodeContextMenu = useCallback(
    (event, node) => {
      //event.preventDefault();
      if (editMode) {
        setContextMenu(
          contextMenu === null
            ? {
                id: node.id,
                top: event.containerPoint.y + 60,
                left: event.containerPoint.x + 310,
                node,
              }
            : null
        );
      }
    },
    [setContextMenu, editMode]
  );
  const onContextMenu = useCallback(
    (event) => {
      //event.preventDefault();
      setContextMenuFlow(
        contextMenu === null
          ? {
              mouseX: event.containerPoint.x + 310,
              mouseY: event.containerPoint.y + 60,
            }
          : null
      );
    },
    [setContextMenuFlow, editMode]
  );
  //-----funcoes para o socket
  async function handleCreateLink(connection) {
    try {
      //return;
      socketCliente.emit("createLink", connection, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          if (response.data?.link) {
            setConnections((connections) => [
              response.data?.link,
              ...connections,
            ]);
            setConnectionSelected(response.data?.link);
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    }
  }
  const handleUpdateLink = async (
    connection,
    edgeSelected,
    line,
    capacidade,
    animation,
    hostOriginId
  ) => {
    try {
      let updateConnection = connection;
      updateConnection.config = {
        type: line ?? "reta",
        sourceHandle: connection?.config?.sourceHandle ?? "right",
        targetHandle: connection?.config?.targetHandle ?? "left",
        capacidade,
        animation: animation ?? "desativada",
        hostOriginId,
      };
      socketCliente.emit("updateLink", updateConnection, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar a conexão",
            severity: "error",
          });
        } else {
          if (response.data) {
            updateDataElementSocket();
            let updateConnection = response.data.link;
            setConnections((connections) => {
              return connections.map((con) =>
                con.id === updateConnection.id
                  ? { data: con.data, ...updateConnection }
                  : con
              );
            });
            setConnectionSelected(null);
            setCheckChanges(false);
            setSnackbar({
              children: "A conexão foi atualizada com sucesso!",
              severity: "success",
            });
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    }
  };
  // remover enlace
  const handleDeleteLink = async (id) => {
    try {
      socketCliente.emit("deleteLink", { id }, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          setElementSelected(null);
          //setRequestSend(false);
          //console.log(response.data);
          if (response.data) {
            let updateConnection = response.data.link;
            setConnections((cons) =>
              cons.filter(({ id }) => id !== updateConnection?.id)
            );
            setConnectionSelected(null);
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    }
  };
  //para caso de duplicar elemento
  const handleDuplicateElement = async (id) => {
    try {
      let data = JSON.parse(
        JSON.stringify(elements.find((ele) => ele.id === id))
      );
      data.titulo = data?.titulo + " (cópia)";
      data.configuracao = {
        ...data.elementConfig?.config,
        coordenadas: {
          long: parseFloat(data.elementConfig.config?.coordenadas?.long) + 0.5,
          lat: parseFloat(data.elementConfig.config?.coordenadas?.lat) + 0.5,
        },
      };

      socketCliente.emit("createElement", data, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          if (response.data) {
            updateDataElementSocket();
            setElements((elements) => [
              {
                ...response.data.element,
                data: data.data,
                alerts: data.alerts,
              },
              ...elements,
            ]);
            setElementSelected(
              JSON.parse(JSON.stringify(response.data?.element))
            );
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    }
  };
  const handleDeleteElement = async (id) => {
    try {
      socketCliente.emit("deleteElement", { id }, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          if (response.data) {
            setConnections((cons) =>
              cons.filter(
                (con) =>
                  con.referenceId !== response.data?.element?.id &&
                  con.destinationId !== response.data?.element?.id
              )
            );
            setElements((eles) =>
              eles.filter(({ id }) => response.data?.element?.id !== id)
            );
            setElementSelected(null);
            setConnectionSelected(null);
            setSnackbar({
              children: "Elemneto deletado com sucesso!",
              severity: "success",
            });
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    }
  };
  const handleUpdatePositionElement = async (element, position) => {
    setCheckChanges(true);
    setElementSelected((data) => {
      // Verifica se `elementConfig` e `config` existem; caso contrário, cria-os
      data.elementConfig = data.elementConfig || {};
      data.elementConfig.config = data.elementConfig.config || {};

      // Atualiza as coordenadas
      data.elementConfig.config.coordenadas = {
        lat: position.lat,
        long: position.lng,
      };

      return { ...data };
    });
  };
  const changeEdge = (edgeId, updateData) => {
    setConnections((cons) => {
      return cons.map((con) => {
        if (con.id === edgeId)
          return {
            ...con,
            config: {
              ...con.config,
              ...updateData,
            },
          };
        else return con;
      });
    });
  };
  const updateEdge = useCallback(
    (index, edgeUpdate) => {
      setPointsClone((prev) => {
        let newArray = [...prev];
        newArray[index + 1] = {
          long: edgeUpdate.newPosition.lng,
          lat: edgeUpdate.newPosition.lat,
          active: true,
        };
        return newArray;
      });
    },
    [pointsClone]
  );
  const updateEdgeEnd = (index, edgeUpdate) => {
    setCheckChanges(true);
    setConnectionSelected((data) => {
      data.positionHandlers[index] = edgeUpdate;
      return { ...data };
    });
  };
  const removeEdge = (index) => {
    setConnectionSelected((data) => {
      data.positionHandlers.splice(index, 1);
      return { ...data };
    });
  };
  const addMapInMaps = (map) => {
    setMapas([...mapas, { nome: map?.titulo, tipo: map?.tipo, id: map?.id }]);
  };
  return (
    <>
      <Box sx={{ overflow: "visible", zIndex: 1202 }}>
        <TitleBar
          title={title}
          id={mapId}
          setSnackbar={setSnackbar}
          center={elementSelected != null || connectionSelected != null}
        />
      </Box>
      <Box sx={styles.body}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <ToolBarMaps
            setEasyMapsConfig={setConfigLocal}
            easyMapsConfig={configLocal}
            proceedUpdateMode={proceedUpdateMode}
            editMode={editMode}
            setEditMode={setEditMode}
            fullScreem={fullScreem}
            setFullScreem={setFullScreem}
            showLabel={showLabel}
            setShowLabel={setShowLabel}
            lockMode={lockMode}
            setLockMode={setLockMode}
            setShowLabelEnlace={setShowLabelEnlace}
            showLabelEnlace={showLabelEnlace}
            setOpenAlertModal={setOpenAlertModal}
            checkChanges={checkChanges}
            tipo={0}
          >
            <ToolBarMaps.InputZoomGeo zoom={zoom} />
          </ToolBarMaps>
          {!editMode && (
            <DescriptionBar
              elementSelected={elementSelected}
              key={"descrptionbar"}
            />
          )}
          <MapContainer
            maxBoundsViscosity={1}
            worldCopyJump={true}
            key={fullScreem ? "mapaFull" : "mapaSingle"}
            fullscreenControl={false}
            ref={mapRef}
            id="map"
            center={[-10, -48]}
            zoom={4}
            inertia={true}
            boxZoom={true}
            minZoom={3}
            maxZoom={19}
            //preferCanvas={true} // se true a animção da linha não funfa, exceto se na linha especificar o svg como render
            fadeAnimation={false}
            zoomControl={false}
            easeLinearity={0.6}
            doubleClickZoom={false}
            enableHighAccuracy={true}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 0,
            }}
          >
            <TileLayer
              url={
                theme.palette.mode == "dark"
                  ? config?.mapaTile?.dark ||
                    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  : config?.mapaTile?.light ||
                    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              }
              //url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              //url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
              //satelite
              //url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              maxZoom={19}
              attribution="&copy; OpenStreetMap contributors"
            />
            <Events />
            <SetMaxBounds />
            <SetZoom />
            <LayerGroup>
              {elements.map(
                (element, index) =>
                  (element?.id != elementSelected?.id || !editMode) && (
                    <NodeIcon
                      configLocal={JSON.parse(JSON.stringify(configLocal))}
                      key={element.id + "noedit" + index}
                      showLabel={showLabel}
                      size={zoomIcons}
                      iconsElement={iconsElements}
                      element={JSON.parse(JSON.stringify(element))}
                      color={theme.palette.color.icons}
                      editable={editMode}
                      onNodeClick={(e, element) => {
                        !checkChanges
                          ? onNodeClick(null, element)
                          : setOpenAlertModal({
                              open: true,
                              callback: newSelectionCallback,
                              params: [element, "elemento"],
                              severity: "info",
                              buttonText: "Salvar",
                              buttonCancelText: "Voltar",
                              text: "As alterações feitas não foram salvas. Deseja Salvá-las?",
                            });
                      }}
                      onNodeContextMenu={onNodeContextMenu}
                      selected={element.id == elementSelected?.id}
                    />
                  )
              )}

              {editMode && elementSelected && (
                <NodeIcon
                  configLocal={JSON.parse(JSON.stringify(configLocal))}
                  key={elementSelected.id}
                  showLabel={showLabel}
                  size={zoomIcons}
                  iconsElement={iconsElements}
                  element={JSON.parse(JSON.stringify(elementSelected))}
                  color={theme.palette.color.icons}
                  editable={true}
                  onNodeClick={() => setElementSelected(null)}
                  onNodeContextMenu={onNodeContextMenu}
                  handleUpdatePositionElement={handleUpdatePositionElement}
                  selected={true}
                />
              )}
            </LayerGroup>
            {connections.map((conn, index) => {
              let position = formatPoints(conn);
              if (!editMode) {
                /* linha fora o modo de edição */
                //calcula a porcentagem de utilização

                let capacidade = parseFloat(conn?.config?.capacidade) ?? 0;
                let consumo =
                  (parseFloat(conn?.data?.inputTraffic || 0) >
                  parseFloat(conn?.data?.outputTraffic || 0)
                    ? parseFloat(conn?.data?.inputTraffic || 0)
                    : parseFloat(conn?.data?.outputTraffic || 0)) /
                    1000000000 ?? 0;
                let percent = (consumo / capacidade) * 100;

                let elemntOrigin = elements.find(
                  (a) => a.id == conn.referenceId
                );
                let elemntOriginVerify = false;
                let hostId = "null";

                if (conn?.config?.hostOriginId) {
                  hostId = conn?.config?.hostOriginId;
                } else {
                  hostId =
                    Array.isArray(conn?.item) && conn?.item?.length > 0
                      ? conn?.item[0]?.hostOriginId
                      : "null";
                }

                elemntOriginVerify = Boolean(
                  (elemntOrigin.data?.ping.find((a) => a.hostid == hostId)
                    ?.lastvalue ||
                    elemntOrigin.data?.snmp?.find((a) => a.hostid == hostId)
                      ?.snmp_available) &&
                    conn?.data?.status
                );

                return (
                  <PolylineCustom
                    disable={disableLines}
                    editMode={false}
                    configLocal={JSON.parse(JSON.stringify(configLocal))}
                    host={
                      elements.find((ele) => ele.id == conn.referenceId)?.titulo
                    }
                    data={JSON.parse(JSON.stringify(conn?.data || null))}
                    percent={conn?.data?.status ? percent : null}
                    // isDown={
                    //   conn?.data?.inputTraffic &&
                    //   conn?.data?.outputTraffic &&
                    //   consumo == 0
                    //     ? true
                    //     : false
                    // }
                    isDown={!elemntOriginVerify}
                    key={conn.id + "selected" + index}
                    positions={position.map((a) => [a.lat || 0, a.long || 0])}
                    animation={conn?.config?.animation ?? "desativada"}
                    onClick={(e) => {
                      let elemento = elements.find(
                        (ele) => ele.id == conn.referenceId
                      );

                      setElementSelected(elemento);
                      setOpenConnection(conn.id);
                    }}
                  />
                );
              } else if (editMode && conn.id != connectionSelected?.id) {
                /* linha no modo de edição, não selecionada */
                return (
                  <PolylineSimple
                    key={conn.id + "noselected" + index}
                    color="#9a7ca0ff"
                    positions={position.map((a) => [a.lat, a.long])}
                    onClick={(e) => {
                      let bk = {
                        ...conn,
                        positionHandlers: JSON.parse(
                          JSON.stringify(conn.positionHandlers)
                        ),
                      };

                      if (!checkChanges) {
                        setElementSelected(null);
                        setConnectionSelected(bk);
                      } else {
                        setOpenAlertModal({
                          open: true,
                          callback: newSelectionCallback,
                          params: [bk, "enlace"],
                          severity: "info",
                          buttonText: "Salvar",
                          buttonCancelText: "Voltar",
                          text: "As alterações feitas não foram salvas. Deseja Salvá-las?",
                        });
                      }
                    }}
                  />
                );
              }
            })}
            {editMode && connectionSelected && (
              <Fragment key={pointsClone.length}>
                {pointsClone?.map(
                  (point, index) =>
                    index != 0 &&
                    index != pointsClone.length - 1 && (
                      <PointIcon
                        point={point}
                        index={index - 1}
                        updateEdge={updateEdge}
                        updateEdgeEnd={updateEdgeEnd}
                        removeEdge={removeEdge}
                        key={index + "pointsEdited"}
                      />
                    )
                )}
                pointsClone && (
                {pointsClone
                  .slice(0, pointsClone.length - 1)
                  .map((point, index) => {
                    return (
                      <PolylineSimple
                        key={"lineEdit" + index + pointsClone[index].lat}
                        positions={[
                          [pointsClone[index].lat, pointsClone[index].long],
                          [
                            pointsClone[index + 1].lat,
                            pointsClone[index + 1].long,
                          ],
                        ]}
                        dbClick={(e) => {
                          let newPosition = {
                            y: e.latlng.lat,
                            x: e.latlng.lng,
                          };

                          setConnectionSelected((data) => {
                            data?.positionHandlers?.splice(
                              index,
                              0,
                              newPosition
                            );
                            return { ...data };
                          });
                        }}
                      />
                    );
                  })}
                )
              </Fragment>
            )}
          </MapContainer>

          {editMode && (
            <MenuMaps
              iconsElement={iconsElements?.filter((icon) =>
                icon?.nome?.startsWith("_")
              )}
              setConnectionSelected={setConnectionSelected}
              setElementSelected={setElementSelected}
              checkChanges={checkChanges}
              setOpenAlertModal={setOpenAlertModal}
              saveUpdateCallback={saveUpdateCallback}
            />
          )}

          <RightBarEasyMaps
            setFocusNodeId={focusZoom}
            open={openRightBar}
            setOpen={setOpenRightBar}
            elements={elements}
            //connections={elementsConnected}
            connections={connections}
            setElement={setElementSelected}
            setConnections={setElementsConnected}
            iconsElement={iconsElements}
            element={!editMode && elementSelected?.id ? elementSelected : null}
            fullScreem={fullScreem}
            openConnection={openConnection}
            setOpenConnection={setOpenConnection}
            link={`/easyMaps/mapa/${
              elementSelected?.mapRef?.tipo === 0 ? "geo" : "top"
            }/${elementSelected?.mapRefId ?? ""}`}
          />

          <CreationSliderBarEasyMaps
            isPointInBounds={isPointInBounds}
            ref={creationRef}
            setOpenAlertModal={setOpenAlertModal}
            handleUpdatePositionElement={handleUpdatePositionElement}
            updateDataElementSocket={updateDataElementSocket}
            focusZoomCoordenates={focusZoomCoordenates}
            iconsElement={iconsElements}
            setIconsElement={setIconsElement}
            mapId={mapId}
            socket={socketCliente}
            setElementSelected={setElementSelected}
            element={editMode ? elementSelected : null}
            setElements={setElements}
            setConnections={setConnections}
            connections={connections}
            elements={elements}
            produtos={produtos}
            api={api}
            setSnackbar={setSnackbar}
            maps={mapas}
            addMapInMaps={addMapInMaps}
            checkChanges={checkChanges}
            setCheckChanges={setCheckChanges}
            centerLeaflatMap={mapRef?.current?.getCenter()}
            deleteElementSocket={(id) =>
              setOpenAlertModal({
                open: true,
                callback: deleteCallback,
                params: [id, "elemento"],
                severity: "error",
              })
            }
          />
          <EnlaceSideBarEasyMaps
            ref={enlaceBarRef}
            setOpenAlertModal={setOpenAlertModal}
            loadRouting={loadRouting}
            setLoadRouting={setLoadRouting}
            autoRouting={autoRouting}
            cleanRouting={cleanRouting}
            connection={editMode ? connectionSelected : null}
            setConnection={setConnectionSelected}
            setSnackbar={setSnackbar}
            api={api}
            socket={socketCliente}
            produtos={produtos}
            iconsElement={iconsElements}
            setIconsElement={setIconsElement}
            elements={elements}
            saveConnection={handleUpdateLink}
            changeEdge={changeEdge}
            fullScreem={fullScreem}
            checkChanges={checkChanges}
            setCheckChanges={setCheckChanges}
            handleDelete={(id) =>
              setOpenAlertModal({
                open: true,
                callback: deleteCallback,
                params: [id, "enlace"],
                severity: "error",
              })
            }
          />
        </Box>
        {contextMenu && editMode && (
          <ElementMenu
            open={contextMenu !== null}
            onClose={onPaneClick}
            handleDelete={(id) => {
              setOpenAlertModal({
                open: true,
                callback: deleteCallback,
                params: [id, "elemento"],
                severity: "error",
              });
            }}
            handleCreate={handleDuplicateElement}
            {...contextMenu}
          />
        )}
        <ContextMenu
          contextMenu={contextMenuFlow}
          setContextMenu={setContextMenuFlow}
          editMode={editMode}
          setEditMode={setEditMode}
        />

        <AlertModal
          openModal={openAlertModal?.open ?? false}
          setOpenModal={(data) => setOpenAlertModal({ open: data })}
          buttonText={openAlertModal?.buttonText}
          buttonCancelText={openAlertModal?.buttonCancelText}
          confirmar={false}
          handleButton={() => {
            setCheckChanges(false);
            setOpenAlertModal({ open: false });
            if (openAlertModal.callback) {
              openAlertModal.callback(...(openAlertModal?.params || []));
              return;
            }
            setElementSelected(null);
            setConnectionSelected(null);
          }}
          severity={openAlertModal?.severity}
          singleButton={false}
          textContent={openAlertModal?.text}
        />
      </Box>
    </>
  );
});

export default LeafletMapa;
