import {
  Add,
  Brush,
  CancelOutlined,
  Check,
  ColorLens,
  DeleteOutline,
  PlayArrow,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  darken,
  Divider,
  IconButton,
  InputAdornment,
  lighten,
  Paper,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import ColorPicker, { themes } from "react-pick-color";
import useStyles from "./styles";

const Escala = memo(
  (
    {
      escala = [],
      setEscala = () => {},
      variant = "traffic",
      setCheckChanges = () => {},
    },
    key
  ) => {
    let theme = useTheme();
    const styles = useStyles(theme);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElColor, setAnchorElColor] = useState(null);
    const [escalaSelected, setEscalaSeletec] = useState(null);
    const [unit, setUnit] = useState(variant == "traffic" ? "%" : "dBm");

    const escalaDefaultTrafego = [
      { cor: theme.palette.enlaces.offline.main, label: "Offline" },
      { cor: theme.palette.enlaces.p0.main, label: "0" },
      { cor: theme.palette.enlaces.p1.main, label: "1" },
      { cor: theme.palette.enlaces.p5.main, label: "5" },
      { cor: theme.palette.enlaces.p15.main, label: "15" },
      { cor: theme.palette.enlaces.p25.main, label: "25" },
      { cor: theme.palette.enlaces.p50.main, label: "50" },
      { cor: theme.palette.enlaces.p70.main, label: "70" },
      { cor: theme.palette.enlaces.p80.main, label: "80" },
      { cor: theme.palette.enlaces.p90.main, label: "90" },
    ];
    const escalaDefaultPotencia = [
      { cor: theme.palette.enlaces.offline.main, label: "Sem Dados" },
      { cor: theme.palette.enlaces.p0.main, label: "0" },
      { cor: theme.palette.enlaces.p1.main, label: "-1" },
      { cor: theme.palette.enlaces.p5.main, label: "-5" },
      { cor: theme.palette.enlaces.p15.main, label: "-15" },
      { cor: theme.palette.enlaces.p25.main, label: "-25" },

      { cor: theme.palette.enlaces.p90.main, label: "-40" },
    ];
    const escalaDefaultFTTH = [
      { cor: theme.palette.enlaces.offline.main, label: "Sem Dados" },
      { cor: theme.palette.enlaces.p5.main, label: "-16" },
      { cor: theme.palette.enlaces.p20.main, label: "-23" },
      { cor: theme.palette.enlaces.p50.main, label: "-33" },
      { cor: theme.palette.enlaces.p90.main, label: "-40" },
    ];

    const handleClick = (event, escala, index) => {
      setAnchorEl(event.currentTarget);
      setEscalaSeletec({ ...escala, index });
    };
    const handleClose = () => {
      setAnchorEl(null);
      setEscalaSeletec(null);
    };

    const update = () => {
      if (!escalaSelected) return;
      setCheckChanges(true);
      setEscala((data) =>
        data.map((esc, index) =>
          escalaSelected.index != index
            ? esc
            : { cor: escalaSelected.cor, label: escalaSelected.label }
        )
      );
      orderByEscala(variant == "traffic");
      clear();
    };
    const remove = () => {
      if (!escalaSelected) return;
      setCheckChanges(true);
      setEscala((data) =>
        data.filter((esc, index) => escalaSelected.index != index)
      );
      clear();
    };
    const redefinir = () => {
      setCheckChanges(true);
      if (variant == "traffic") setEscala(escalaDefaultTrafego);
      if (variant == "power") setEscala(escalaDefaultPotencia);
      if (variant == "FTTH") setEscala(escalaDefaultFTTH);
    };
    const add = () => {
      setCheckChanges(true);
      setEscala((data) => [
        ...data.slice(0, 1),
        {
          cor: theme.palette.mode == "dark" ? "#474545" : "#c7baba",
          label: "0",
        },
        ...data.slice(1),
      ]);
    };
    const clear = () => {
      setAnchorEl(null);
      setEscalaSeletec(null);
    };
    const orderByEscala = (isAscending = true) => {
      setEscala((data) =>
        [...data].sort((a, b) => {
          if (a.label === "Offline" || a.label === "Sem Dados") return -1;
          if (b.label === "Offline" || b.label === "Sem Dados") return 1;

          const comp = parseFloat(a.label) - parseFloat(b.label);
          return isAscending ? comp : -comp;
        })
      );
    };

    return (
      <>
        <Stack sx={styles.barEscala} key={key}>
          {escala.map((cor, index) => (
            <Stack
              key={"barColor" + index}
              direction="row"
              sx={{
                justifyContent: "center",
                height: "25px",
              }}
            >
              <Divider orientation="vertical" />
              <Stack gap={0.4}>
                <Tooltip
                  title={
                    index != 0 && !(variant == "FTTH" && cor.label == "-40")
                      ? "Clique para editar"
                      : "Não editável"
                  }
                >
                  <Button
                    sx={styles.buttonEscala}
                    onClick={(e) =>
                      index != 0 &&
                      !(variant == "FTTH" && cor.label == "-40") &&
                      handleClick(e, cor, index)
                    }
                  >
                    <Box
                      sx={[
                        styles.boxEscala,
                        {
                          backgroundColor: cor.cor,
                          "&:hover": {
                            backgroundColor:
                              theme.palette.mode == "dark"
                                ? lighten(cor.cor, 0.5)
                                : darken(cor.cor, 0.3),
                          },
                        },
                      ]}
                    />
                  </Button>
                </Tooltip>
                <Typography
                  sx={{ fontSize: "11px", ml: "2px", fontWeight: 500 }}
                >
                  {" "}
                  {cor.label + (index != 0 ? unit : "")}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Stack direction={"row"} gap={1} mt={3}>
          <Button sx={styles.buttonEscalaConfig} onClick={redefinir}>
            Redefinir
          </Button>
          <Tooltip
            title={escala.length >= 10 ? "Número máximo atingido" : null}
          >
            <span>
              <Button
                sx={styles.buttonEscalaConfig}
                onClick={add}
                startIcon={<Add />}
                disabled={escala.length >= 10}
              >
                Nova escala
              </Button>
            </span>
          </Tooltip>
        </Stack>{" "}
        <Popover
          id={Boolean(escalaSelected) ? "simple-popover" : undefined}
          open={Boolean(escalaSelected)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={styles.popoverEscala}
        >
          <PlayArrow sx={styles.iconEscala} />
          <Paper sx={styles.paperEscala}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Typography sx={styles.titleEscala}>Escala: </Typography>
              <TextField
                sx={[styles.textFieldEscala, styles.textFieldEscalaNumber]}
                value={escalaSelected?.label}
                type="number"
                onChange={(e) =>
                  setEscalaSeletec((data) => ({
                    ...data,
                    label: e.target.value,
                  }))
                }
                size="small"
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="start">
                        <Typography sx={styles.textAdorment}>{unit}</Typography>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Typography sx={styles.titleEscala}>Cor: </Typography>
              <TextField
                sx={styles.textFieldEscala}
                value={escalaSelected?.cor}
                size="small"
                onChange={(e) =>
                  setEscalaSeletec((data) => ({ ...data, cor: e.target.value }))
                }
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Button
                          sx={{
                            minWidth: "15px",
                            minHeight: "16px",
                            width: "15px",
                            height: "16px",
                            borderRadius: "4px",
                            backgroundColor: escalaSelected?.cor,
                          }}
                          onClick={(e) => setAnchorElColor(e.currentTarget)}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <IconButton
                size="small"
                onClick={(e) => setAnchorElColor(e.currentTarget)}
              >
                <Brush />
              </IconButton>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"end"}
              width={"100%"}
              mt={1}
              mb={-1}
            >
              <Button
                onClick={remove}
                sx={styles.buttomEnd}
                startIcon={<DeleteOutline />}
              >
                Remover
              </Button>
              <Button
                onClick={clear}
                sx={styles.buttomEnd}
                startIcon={<CancelOutlined />}
              >
                Cancelar
              </Button>
              <Button
                onClick={update}
                sx={styles.buttomEnd}
                color="white"
                startIcon={<Check />}
              >
                Salvar
              </Button>
            </Stack>
          </Paper>
        </Popover>
        <Popover
          id={Boolean(anchorElColor) ? "simple-popover-color" : undefined}
          open={Boolean(anchorElColor)}
          anchorEl={anchorElColor}
          onClose={() => setAnchorElColor(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ColorPicker
            color={escalaSelected?.cor}
            onChange={(color) =>
              setEscalaSeletec((data) => ({ ...data, cor: color.hex }))
            }
            theme={theme.palette.mode == "dark" ? themes.dark : themes.light}
          />
        </Popover>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.escala) === JSON.stringify(nextProps.escala)
    );
  }
);

export default Escala;
