import { Text, View, Image } from "@react-pdf/renderer";
import { styles } from "./../styles";
import {
  agrupaMetricas,
  calculoExcedente,
  calculoExcedentePlanoFixo,
  convertBitsToBytes,
  convertBitsToBytesBin,
  converteUnidades,
  tarifacaoMBitsPorSegundo,
} from "../../utils";
const RowTrafic = ({ topic, content }) =>
  (content || content == 0) && (
    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
      <Text style={styles.textBold}>{topic}</Text>
      <Text style={styles.text}>{content}</Text>
    </View>
  );

const RowTarifacao = ({ price, c95th, excess, plan, limitTraffic }) => {
  const limiteExcedente = limitTraffic
    ? parseFloat(limitTraffic) * Math.pow(10, 6)
    : 0;
  const trafegoTotal = c95th ? parseFloat(c95th.replace(" bps")) : "";
  const trafegoSemExcedente =
    trafegoTotal <= limiteExcedente ? c95th : limiteExcedente;
  const trafegoExcedente =
    trafegoTotal - limiteExcedente < 0 ? 0 : trafegoTotal - limiteExcedente;
  return (
    <>
      {price && c95th && (
        <>
          <RowTrafic
            topic={"Tráfego 95th percentil : "}
            content={converteUnidades(c95th, "bps")}
          />
          {plan ? (
            <RowTrafic
              topic={"Preço do plano fixo: "}
              content={`${parseFloat(price).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`}
            />
          ) : (
            <RowTrafic
              topic={"Preço por Mbps:"}
              content={`${parseFloat(price).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`}
            />
          )}
          {excess && plan ? (
            <>
              <RowTrafic
                topic={"Preço por Mbps de tráfego excedente : "}
                content={`${parseFloat(excess).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Limite de tráfego sem cobrança de excedente: "}
                content={`${converteUnidades(limiteExcedente, "bps")}`}
              />
              <RowTrafic
                topic={"Valor do excedente: "}
                content={`${tarifacaoMBitsPorSegundo(
                  trafegoExcedente,
                  excess
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Valor final: "}
                content={`${calculoExcedentePlanoFixo(
                  price,
                  c95th,
                  excess,
                  limitTraffic
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
            </>
          ) : excess ? (
            <>
              <RowTrafic
                topic={"Preço por Mbps de tráfego excedente : "}
                content={`${parseFloat(excess).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Limite de tráfego sem cobrança de excedente: "}
                content={`${converteUnidades(limiteExcedente, "bps")}`}
              />
              <RowTrafic
                topic={"Valor sem adicional de excedente: "}
                content={`${tarifacaoMBitsPorSegundo(
                  trafegoSemExcedente,
                  price
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Valor do excedente: "}
                content={`${tarifacaoMBitsPorSegundo(
                  trafegoExcedente,
                  excess
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
              <RowTrafic
                topic={"Valor final: "}
                content={`${calculoExcedente(
                  price,
                  c95th,
                  excess,
                  limitTraffic
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}
              />
            </>
          ) : (
            <RowTrafic
              topic={"Valor total : "}
              content={`${tarifacaoMBitsPorSegundo(c95th, price).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}`}
            />
          )}
        </>
      )}
    </>
  );
};
const RowHorarioPersonalizado = ({ status, horaInicial, horaFinal }) => {
  return (
    <>
      {status && (
        <RowTrafic
          topic={"Hora personalizada: "}
          content={`${horaInicial} - ${horaFinal}`}
        />
      )}
    </>
  );
};
export default function AggregateReport({
  itemName,
  hosts,
  horaInicial,
  horaFinal,
  dataFinal,
  dataInicial,
  unit,
  graphcs,
  indiceGraphcs,
  etapa,
  etapas,
  ultimaData,
}) {
  //console.log(agregaHosts, conteudoHost, itens);
  return (
    <>
      <View break>
        {hosts?.map(
          (
            {
              hostName,
              c95th,
              availability,
              maxValue,
              minValue,
              averageValue,
              timeCustom,
              price,
              trafficFull,
              excess,
              plan,
              limitTraffic,
              zeroDown,
            },
            index
          ) => (
            <View key={"aggregate-view"} style={{ width: "100%" }}>
              <Text
                style={styles.title}
              >{`AGREGADO DOS ITENS DE TRÁFEGO`}</Text>
              <View wrap={false}>
                <Text style={styles.textBold}>{`Hosts:`}</Text>
                {hostName?.split(",")?.map((host, index) => (
                  <Text style={styles.textBold}>{`${index + 1}: ${host}`}</Text>
                ))}
                <Text style={styles.textBold}>{`Itens:`}</Text>
                {itemName?.split(",")?.map((item, index) => (
                  <Text style={styles.textBold}>{`${index + 1}: ${item}`}</Text>
                ))}
                {/* <Text style={styles.textBold}>{`Itens: ${itemName}`}</Text> */}
                {/* <Text
                  style={[styles.textBold, { marginTop: 10 }]}
                >{`Resultados para o Host: ${hostName}`}</Text> */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 10,
                  }}
                  wrap={false}
                >
                  <RowTarifacao
                    c95th={c95th}
                    price={price}
                    plan={plan}
                    excess={excess}
                    limitTraffic={limitTraffic}
                  />
                  <RowTrafic
                    topic={"Período do relatório: "}
                    content={
                      etapa === etapas && timeCustom?.status
                        ? `${dataInicial} ${timeCustom.horaInicial} à ${ultimaData} ${timeCustom.horaFinal}`
                        : timeCustom?.status
                        ? `${dataInicial} ${timeCustom.horaInicial} à ${dataFinal} ${timeCustom.horaFinal}`
                        : `${dataInicial} ${horaInicial} à ${dataFinal} ${horaFinal}`
                    }
                  />
                  {availability && (
                    <RowTrafic
                      topic={"Tempo de análise: "}
                      content={availability?.rangeAnalyzed}
                    />
                  )}
                  {maxValue && (
                    <RowTrafic
                      topic={"Valor máximo: "}
                      content={converteUnidades(maxValue, unit)}
                    />
                  )}
                  {minValue && (
                    <RowTrafic
                      topic={"Valor mínimo: "}
                      content={converteUnidades(minValue, unit)}
                    />
                  )}
                  {averageValue && (
                    <RowTrafic
                      topic={"Valor médio: "}
                      content={converteUnidades(averageValue, unit)}
                    />
                  )}
                  {trafficFull && (
                    <RowTrafic
                      topic={"Tráfego total: "}
                      content={
                        unit === "bps"
                          ? convertBitsToBytesBin(trafficFull) +
                            " / " +
                            convertBitsToBytes(trafficFull)
                          : converteUnidades(trafficFull, unit)
                      }
                    />
                  )}
                  <RowHorarioPersonalizado {...timeCustom} />
                  {availability && (
                    <>
                      <RowTrafic
                        topic={"Tempo de indisponibilidade: "}
                        content={availability?.downtime + ""}
                      />
                      <RowTrafic
                        topic={"Taxa de disponibilidade: "}
                        content={availability?.availability + ""}
                      />
                    </>
                  )}
                  {zeroDown?.status && (
                    <>
                      {" "}
                      <RowTrafic
                        topic={"Valor mínimo para disponibilidade: "}
                        content={converteUnidades(zeroDown?.valor, unit)}
                      />
                    </>
                  )}
                </View>
              </View>
              {graphcs && (
                <View style={{ marginVertical: 5 }} wrap={false}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={styles.legendBold}
                    >{`Gráfico ${indiceGraphcs}: `}</Text>
                    <Text
                      style={styles.legend}
                    >{`Histórico do agregado para o host ${hostName}`}</Text>
                  </View>
                  <Image
                    style={{ height: "auto", width: "500px" }}
                    src={`data:application/pdf;base64,${
                      graphcs?.find((graphc) => graphc.host == hostName)
                        ?.graphcs
                    }`}
                  />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={styles.legend}
                    >{`Fonte: Dados coletados pelo Zabbix`}</Text>
                  </View>
                </View>
              )}
            </View>
          )
        )}
      </View>
    </>
  );
}
