import {
  alpha,
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { use, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

const DescriptionBar = (
  { elementSelected = false, variant = "traffic" },
  key
) => {
  const { config } = useOutletContext();
  const [cores, setCores] = useState([]);
  const [unidade, setUnidade] = useState("");
  let theme = useTheme();

  useEffect(() => {
    if (!config) return;

    if (variant === "traffic") {
      setCores(config.escalas.trafego);
      setUnidade("%");
    } else if (variant === "power") {
      setCores(config.escalas.potencia);
      setUnidade("dBm");
    } else if (variant === "ftth") {
      setCores(config.escalas.ftth);
      setUnidade("dBm");
    }
  }, [config]);

  const styles = {
    bar: {
      position: "absolute",
      backgroundColor: alpha(theme.palette.background.default, 0.7),
      p: 0.5,
      left: elementSelected ? 0 : null,
      right: elementSelected ? null : 0,
      bottom: 0,

      zIndex: 10,
      flexDirection: "row",
    },
    box: {
      minHeight: "10px",
      minWidth: "60px",
      ml: "-1px",
    },
  };

  return (
    <Stack sx={styles.bar} key={key}>
      {cores?.map((cor, index) => (
        <Stack
          key={"barColor" + index}
          direction="row"
          sx={{ justifyContent: "center", height: "25px" }}
        >
          <Divider orientation="vertical" />
          <Stack gap={0.4}>
            <Box
              sx={[
                styles.box,
                {
                  backgroundColor: cor.cor,
                },
              ]}
            />
            <Typography sx={{ fontSize: "10px", ml: "2px" }}>
              {" "}
              {cor.label + (index != 0 ? unidade : "")}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default DescriptionBar;
