import { alpha } from "@mui/material";

export const zabbixSeverty = (colorMode) => {
  return {
    warning: "#F49506",
    warningTranslucid: colorMode
      ? alpha("#d6a607", 0.3)
      : alpha("#d6a607", 0.6),
    average: "#FF7917",
    averageTranslucid: colorMode
      ? alpha("#ff7817ff", 0.3)
      : alpha("#ff7817ff", 0.7),
    high: "#EE1127",
    highTranslucid: colorMode
      ? alpha("#ee1127ff", 0.3)
      : alpha("#ee1127ff", 0.7),

    disaster: "#EE1127",
    selectedTranslucid: "#9653da",
    selected: !colorMode ? "#be95e4" : "#a76ddd",
    default: !colorMode ? alpha("#e2c5ff", 0.6) : alpha("#2b2b2bff", 0.3),
    //default: !colorMode ? "#794EA4" : "#242424ce",
  };
};

export const colorsAlerts = (colorMode) => {
  return {
    warning: colorMode ? "#ed6c02ff" : "#f57c00",
    info: colorMode ? "#0096d1" : "#2e98c2", //"#123746" : "#8fdfffa9",
    success: colorMode ? "#2e7d32" : "#388e3c",
    error: colorMode ? "#e83750" : "#ec4b60",
  };
};
export const appBarEasyMaps = (colorMode) => {
  return {
    backgroundColor: !colorMode ? "#ffffff" : "#121212",
  };
};
