import { useTheme } from "@emotion/react";
import { Business, Close, ModeEdit, Person } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import api from "../../../services/api";
import { useNotification } from "../../../componentes/notificationProvider";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import ImageButton from "../../../componentes/imageButton";
import {
  maskCnpj,
  maskCPF,
  maskPhone,
  removeMask,
} from "../../../utils/maskFields";

import { validarEmail } from "../../../utils/validateFields";

import { validarCpf } from "../../../utils/validateCpf";
import { validarCnpj } from "../../../utils/validateCnpj";
import useStyles from "./styles";

function BoxPreview(params) {
  const { foto, setFoto = () => {}, pessoaFisica } = params;
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <Paper sx={styles.paperPreview} variant="outlined">
      <Box sx={styles.boxCover}></Box>

      <ImageButton
        startIcon={<ModeEdit />}
        component="label"
        size="small"
        setFoto={setFoto}
        sx={styles.button}
        isButton={true}
      >
        Editar Foto
      </ImageButton>

      <Box sx={styles.boxAvatar}>
        <Avatar src={foto} color="secondary" sx={styles.avatar} />{" "}
        <Tooltip title={pessoaFisica ? "pessoa física" : "pessoa jurídica"}>
          <Box variant="contained" component="label" sx={styles.boxIcon}>
            {pessoaFisica ? <Person /> : <Business />}
          </Box>
        </Tooltip>
      </Box>
    </Paper>
  );
}
export default function ModalEditProfile(params) {
  const theme = useTheme();
  const notify = useNotification();
  const {
    openEditProfileModal,
    setOpenEditProfileModal,
    selectedRow = {},
    setRows = () => {},
  } = params;
  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [dataDeNascimento, setDataDeNascimento] = useState(null);
  const [cpf, setCpf] = useState("");
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [foto, setFoto] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasProfile, setHasProfile] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [pessoaFisica, setPessoaFisica] = useState(true);
  const styles = useStyles(theme);

  useEffect(() => {
    setIsSubmit(false);
    setId(selectedRow?.id);
    setLogin(selectedRow?.login);
    setEmail(selectedRow?.email);
    if (selectedRow?.PerfilCliente) {
      setNome(selectedRow?.PerfilCliente?.nome);
      setSobrenome(selectedRow?.PerfilCliente?.sobrenome);
      setNomeFantasia(selectedRow?.PerfilCliente?.nomeFantasia);
      setDataDeNascimento(dayjs(selectedRow?.PerfilCliente?.dataDeNascimento));
      setTelefone(selectedRow?.PerfilCliente?.telefone);
      setCpf(selectedRow?.PerfilCliente?.cpf);
      setCnpj(selectedRow?.PerfilCliente?.cnpj);
      setFoto(selectedRow?.PerfilCliente?.foto);
      setPessoaFisica(selectedRow?.PerfilCliente?.pessoaFisica);
      setHasProfile(true);
      return;
    }
    setHasProfile(false);
  }, [selectedRow]);

  async function handleApiUpdateProfile(data) {
    try {
      setLoading(true);

      const response = await api.put("/usuarioPerfil/update", {
        ...data,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.status === 200) {
        setRows((rows) =>
          rows.map((row) => {
            return row.id === id ? { ...row, ...response.data } : row;
          })
        );
      }
      notify("Perfil atualizado com sucesso!", "success");
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error)
        notify(error.response.data.error, "error");
      else notify("Erro ao atualizar dados!", "error");
    } finally {
      setLoading(false);
      setOpenEditProfileModal(false);
    }
  }

  const handleClose = () => {
    setOpenEditProfileModal(false);
    setIsSubmit(false);
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    let data = {};
    if (!login || !email || !validarEmail(email)) return;
    if (!hasProfile)
      data = {
        id,
        login,
        email,
      };
    else if (pessoaFisica) {
      if (
        !nome ||
        !sobrenome ||
        !cpf ||
        !validarCpf(cpf) ||
        !dataDeNascimento ||
        !telefone
      )
        return;
      else
        data = {
          id,
          nome,
          sobrenome,
          dataDeNascimento,
          cpf: removeMask(cpf),
          login,
          email,
          telefone: removeMask(telefone),
          foto,
        };
    } else {
      if (!nomeFantasia || !cnpj || !validarCnpj(cnpj) || !telefone) return;
      else
        data = {
          id,
          nomeFantasia,
          cnpj: removeMask(cnpj),
          login,
          email,
          telefone: removeMask(telefone),
          foto,
        };
    }

    handleApiUpdateProfile(data);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Modal open={openEditProfileModal} onClose={handleClose} sx={styles.modal}>
      <Paper sx={styles.paper}>
        <DialogTitle color="primary" sx={styles.dialogTitle}>
          <Box sx={styles.boxHeader}>
            <Box sx={styles.boxTitle}>
              <IconButton size="small">
                <Person sx={{ color: "primary.main" }} />
              </IconButton>
              <Typography fontWeight={500}>Editar Detalhes</Typography>
            </Box>

            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenEditProfileModal(false);
              }}
              sx={styles.iconButton}
            >
              <Close />
            </IconButton>
          </Box>

          <Divider />

          {hasProfile ? (
            <Box sx={{ ...styles.boxRowFields, mt: 1 }}>
              <Typography sx={styles.subtitle}>Prévia</Typography>
              <BoxPreview
                foto={foto}
                setFoto={setFoto}
                pessoaFisica={pessoaFisica}
              />
            </Box>
          ) : (
            <Alert sx={styles.alert} severity="info">
              Usuário não possui dados de perfil cadastrados
            </Alert>
          )}
        </DialogTitle>

        <DialogContent sx={styles.dialogContent}>
          <Grid2 container columnSpacing={1.5} rowSpacing={2}>
            {pessoaFisica && hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>Nome</Typography>{" "}
                  <TextField
                    placeholder="Nome"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    disabled={loading}
                    value={nome}
                    onChange={(e) => {
                      setNome(e.target.value);
                    }}
                    error={isSubmit && !nome}
                    helperText={isSubmit && !nome && "Campo inválido"}
                  />
                </Box>
              </Grid2>
            )}

            {pessoaFisica && hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>Sobrenome</Typography>{" "}
                  <TextField
                    placeholder="Sobrenome"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    disabled={loading}
                    value={sobrenome}
                    onChange={(e) => {
                      setSobrenome(e.target.value);
                    }}
                    error={isSubmit && !sobrenome}
                    helperText={isSubmit && !sobrenome && "Campo inválido"}
                  />
                </Box>
              </Grid2>
            )}

            {!pessoaFisica && hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 12 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>Nome fantasia</Typography>{" "}
                  <TextField
                    placeholder="Nome fantasia"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    disabled={loading}
                    value={nomeFantasia}
                    onChange={(e) => {
                      setNomeFantasia(e.target.value);
                    }}
                    error={isSubmit && !nomeFantasia}
                    helperText={isSubmit && !nomeFantasia && "Campo inválido"}
                  />
                </Box>
              </Grid2>
            )}

            {pessoaFisica && hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>
                    Data de nascimento
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"pt-br"}
                  >
                    <DatePicker
                      placeholder="Data de nascimento"
                      sx={styles.textfield}
                      maxDate={dayjs()}
                      openTo="year"
                      autoComplete="off"
                      disabled={loading}
                      value={dataDeNascimento}
                      onChange={(newValeu) => {
                        setDataDeNascimento(dayjs(new Date(newValeu)));
                      }}
                      error={isSubmit && !dataDeNascimento}
                      helperText={
                        isSubmit && !dataDeNascimento && "Campo inválido"
                      }
                    />
                  </LocalizationProvider>
                </Box>
              </Grid2>
            )}

            {hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>Telefone</Typography>{" "}
                  <TextField
                    placeholder="Telefone"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    disabled={loading}
                    value={maskPhone(telefone)}
                    onChange={(e) => {
                      setTelefone(e.target.value);
                    }}
                    error={isSubmit && !telefone}
                    helperText={isSubmit && !telefone && "Campo inválido"}
                  />
                </Box>
              </Grid2>
            )}

            {pessoaFisica && hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 12 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>CPF</Typography>
                  <TextField
                    placeholder="CPF"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    disabled={loading}
                    value={maskCPF(cpf)}
                    onChange={(e) => {
                      setCpf(e.target.value);
                    }}
                    error={isSubmit && (!cpf || !validarCpf(cpf))}
                    helperText={
                      isSubmit && (!cpf || !validarCpf(cpf)) && "CPF inválido"
                    }
                  />
                </Box>
              </Grid2>
            )}

            {!pessoaFisica && hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>CNPJ</Typography>
                  <TextField
                    placeholder="CNPJ"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    disabled={loading}
                    value={maskCnpj(cnpj)}
                    onChange={(e) => {
                      setCnpj(e.target.value);
                    }}
                    error={isSubmit && (!cnpj || !validarCnpj(cnpj))}
                    helperText={
                      isSubmit &&
                      (!cnpj || !validarCnpj(cnpj)) &&
                      "CNPJ inválido"
                    }
                  />
                </Box>
              </Grid2>
            )}

            <Grid2 item size={{ xs: 12, sm: 12 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.subtitle}>Login</Typography>{" "}
                <TextField
                  width={"100%"}
                  placeholder="Login"
                  size="small"
                  sx={styles.textfield}
                  autoComplete="off"
                  disabled={loading}
                  value={login}
                  onChange={(e) => {
                    setLogin(e.target.value);
                  }}
                  error={isSubmit && !login}
                  helperText={isSubmit && !login && "Campo inválido"}
                />
              </Box>
            </Grid2>

            <Grid2 item size={{ xs: 12, sm: 12 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.subtitle}>E-mail</Typography>
                <TextField
                  width={"100%"}
                  placeholder="E-mail"
                  onKeyDown={handleKeyDown}
                  size="small"
                  sx={styles.textfield}
                  autoComplete="off"
                  disabled={loading}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={isSubmit && (!email || !validarEmail(email))}
                  helperText={
                    isSubmit &&
                    (!email || !validarEmail(email)) &&
                    "Campo inválido"
                  }
                />
              </Box>
            </Grid2>
          </Grid2>
        </DialogContent>

        <DialogActions sx={styles.dialogActions}>
          <Button
            disabled={loading}
            onClick={() => {
              handleClose();
            }}
          >
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleSubmit}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Paper>
    </Modal>
  );
}
