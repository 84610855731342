import {
  Box,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Stack,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import api from "../../../services/api";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import SnackBar from "../../../componentes/snackbar";

// import ConfigureRelatorioFlow from "../agendarRelatorio/novoRelatorio/configureRelatorioFlow";
import ConfigureOsItens from "../../../componentes/easyReportOld/stepsRelatorio/configureOsItens";
import Agendamento from "../../../componentes/easyReportOld/stepsRelatorio/agendamento";
// import ConfigureRelatorio from "../agendarRelatorio/novoRelatorio/configureRelatorio";
import GeneratePdfRelatorio from "./generatePdfRelatorio";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import AdicionaTitulo from "../../../componentes/easyReportOld/stepsRelatorio/adicionaTitulo";
import ConfiguraRelatorioFlow from "../../../componentes/easyReportOld/stepsRelatorio/configuraRelatorioFlow";
import ConfiguraRelatorioMon from "../../../componentes/easyReportOld/stepsRelatorio/configuraRelatorioMon";

// const icon = <CheckBoxOutlineBlank fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function GerarRelatorio() {
  const [activeStep, setActiveStep] = useState(0);
  // const [hosts, setHosts] = useState([]);
  // const [clientes, setClientes] = useState([]);
  const initialHost = {
    id: null,
    nome: "",
    itens: [],
  };
  const initialClientes = {
    id: 0,
    client: "",
    types: [],
  };
  const [titulo, setTitulo] = useState("");
  const [produto, setProduto] = useState("");
  const [tipoProduto, setTipoProduto] = useState("");
  const [produtos, setProdutos] = useState([]);
  const [selectedHosts, setSelectedHosts] = useState([initialHost]);
  const [selectedClientes, setSelectedClientes] = useState([initialClientes]);
  const [loadingHosts, setLoadingHosts] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [listTags, setListTags] = useState([]);
  const [listItens, setListItens] = useState([]);
  // const [loadingClientes, setLoadingClientes] = useState(false);
  // const [disableClientes, setDisableClientes] = useState(false);
  const [fna, setFna] = useState("");
  const [ggc, setGgc] = useState("");
  const [ixPpt, setIxPpt] = useState("");
  const [transIp, setTransIp] = useState("");
  const [ocaNetflix, setOcaNetflix] = useState("");
  const [tarifacaoExcedente, setTarifacaoExcedente] = useState({
    status: false,
    valor: "",
    valorExcedente: "",
    limite: "",
  });
  const [ocultarGraficos, setOcultarGraficos] = useState(false);
  const [agruparPorNome, setAgruparPorNome] = useState({
    status: false,
    cliente: "",
  });
  // const [filterStatusFlow, setFilterStatusFlow] = useState(false);
  // const [interfacesFlow, setInterfacesFlow] = useState([]);
  const [filterInterfaceFlow, setFilterInterfaceFlow] = useState([]);
  const [dataInicial, setDataInicial] = useState(
    dayjs(new Date()).subtract(1, "day").startOf("day").toDate()
  );
  const [dataFinal, setDataFinal] = useState(
    dayjs(new Date()).add(0, "day").startOf("day").toDate()
  );
  const [etapas, setEtapas] = useState(null);
  const [periodoColeta, setPeriodoColeta] = useState({
    repetir: "",
    periodo: null,
  });
  const [disableButtom, setDissableButton] = useState([true, true, true, true]);
  const [relatorio, setRelatorio] = useState([]);
  const [terminaEm, setTerminaEm] = useState(0);
  const [loadingButton, setLoadingButton] = useState(false);

  // const maskCurrency = (value) => {
  //   const onlyDigits = value
  //     .split("")
  //     .filter((s) => /\d/.test(s))
  //     .join("")
  //     .padStart(3, "0");
  //   const digitsFloat = parseFloat(
  //     onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
  //   );

  //   return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
  //     digitsFloat
  //   );
  // };

  // useEffect(() => {
  //   setFilterInterfaceFlow([]);
  // }, [filterStatusFlow]);
  //controla botao de continuar no primeiro passo
  useEffect(() => {
    let newArray = [...disableButtom];
    if (titulo && produto) {
      newArray[0] = false;
      setDissableButton(newArray);
    } else {
      newArray[0] = true;
      setDissableButton(newArray);
    }
  }, [titulo, produto]);
  //controla o botao de continuar segundo passo
  useEffect(() => {
    let newArray = [...disableButtom];

    if (tipoProduto === "easyflow") {
      if (agruparPorNome.status) {
        agruparPorNome.cliente !== ""
          ? (newArray[1] = false)
          : (newArray[1] = true);

        setDissableButton(newArray);
      } else {
        //verifica se 'id' e 'client' estão vazios
        const todosCamposPreenchidos = selectedClientes.every((client) => {
          // Verifica se 'id' e 'nome' não estão vazios
          if (!client.id || !client.client) {
            return false;
          }
          // Verifica se 'itens' não está vazio e todos os 'id' dentro de 'itens' estão preenchidos
          return (
            client.typesValue.length > 0 &&
            client.typesValue.some((typesValue) => typesValue === true)
          );
        });

        if (todosCamposPreenchidos) {
          newArray[1] = false;
          setDissableButton(newArray);
        } else {
          newArray[1] = true;
          setDissableButton(newArray);
        }
      }
    } else {
      const todosCamposPreenchidos = selectedHosts.every((host) => {
        // Verifica se 'id' e 'nome' não estão vazios
        if (!host.id || !host.nome) {
          return false;
        }
        // Verifica se 'itens' não está vazio e todos os 'id' dentro de 'itens' estão preenchidos
        return (
          host.itens.length > 0 && host.itens.every((item) => !!item.itemid)
        );
      });

      if (todosCamposPreenchidos) {
        newArray[1] = false;
        newArray[2] = false;
        setDissableButton(newArray);
      } else {
        newArray[1] = true;
        newArray[2] = true;
        setDissableButton(newArray);
      }
    }
  }, [selectedHosts, selectedClientes, agruparPorNome]);
  //controla o botao de continuar quarto passo

  useEffect(() => {
    let newArray = [...disableButtom];

    const checkPeriodoPersonalizado = !(
      periodoColeta.repetir === "personalizado" && !periodoColeta.periodo
    );

    const checkTerminaApos = !!(terminaEm == 0 && etapas);

    const checkTerminaEm = !!(
      terminaEm == 1 && dayjs(dataInicial).isBefore(dataFinal)
    );

    if (
      (checkPeriodoPersonalizado && checkTerminaApos) ||
      (checkPeriodoPersonalizado && checkTerminaEm)
    ) {
      newArray[tipoProduto === "easyflow" ? 2 : 3] = false;
      setDissableButton(newArray);
    } else {
      newArray[tipoProduto === "easyflow" ? 2 : 3] = true;
      setDissableButton(newArray);
    }
  }, [dataFinal, dataInicial, periodoColeta, etapas]);

  // criar lista de itens para configurações

  function pesoDaTag(tag) {
    switch (tag) {
      case "traffic":
        return 1;
      case "number":
        return 2;
      case "boolean":
        return 3;
      default:
        return 4; // Qualquer outro valor
    }
  }
  useEffect(() => {
    const configuracoes = {
      variacaoAbrupta: { status: false },
      tarifacao: { status: true, valor: 0 },
      tarifacaoExcedente: {
        status: false,
        valorExcedente: 0,
        limite: 0,
        valor: 0,
      },
      tarifacaoPlanoFixo: {
        status: false,
        valorExcedente: 0,
        limite: 0,
        valor: 0,
      },
      //zeroDown: false,
      zeroDown: { status: false, valor: 0 },
      horarioPersonalizado: { status: false },
      valorMinimo: true,
      valorMaximo: true,
      valorMedio: true,
      disponibilidade: false,
      ocultarGraficos: false,
    };

    //remove array mais externo
    const flatResponse = selectedHosts.flatMap((obj) => obj?.itens);
    // cria o array de itens
    const newTagArray = Array.from(
      new Map(
        flatResponse.map((item) => [
          item.tag,
          item,
          (item.configuracoes = configuracoes),
        ])
      ).values()
    );
    const newItemArray = Array.from(
      new Map(
        flatResponse.map((item) => [
          item.nome,
          item,
          (item.configuracoes = configuracoes),
        ])
      ).values()
    );

    setListItens(newItemArray);
    //criar um valor unico para todos todos os itens de tráfico

    setListTags(
      newTagArray.sort((a, b) => pesoDaTag(a.tag) - pesoDaTag(b.tag))
    );
  }, [selectedHosts]);

  useEffect(() => {
    handleApiClienteProduto();
    // handleApiGetHosts();
    // handleApiFindClientsFlowDB();
    // handleApiFindInterfacesFlow();
  }, []);

  useEffect(() => {
    if (!agruparPorNome.status) {
      setAgruparPorNome({ status: false, cliente: "" });
    }
  }, [agruparPorNome.status]);
  // const handleSelectHostsChange = (index, event) => {
  //   const { name, value } = event.target;
  //   let newSelectedHost = [...selectedHosts];
  //   newSelectedHost[index][name] = value;
  //   setSelectedHosts(newSelectedHost);
  // };

  // const handleSelectClientesChange = (index, event) => {
  //   const { name, value } = event.target;

  //   let newSelectedCliente = [...selectedClientes];
  //   newSelectedCliente[index][name] = value;
  //   setSelectedClientes(newSelectedCliente);
  // };

  //adiciona configurações
  const handleListItensChange = (index, event) => {
    const { name, checked, value } = event.target;
    let newListItens = JSON.parse(JSON.stringify(listTags));

    switch (name) {
      //tarifacao
      case "tarifacao":
        newListItens[index].configuracoes[name].status = checked;
        if (checked) {
          newListItens[index].configuracoes["tarifacaoExcedente"].status =
            !checked;
          newListItens[index].configuracoes["tarifacaoPlanoFixo"].status =
            !checked;
        }
        break;
      case "tarifacao-valor":
        newListItens[index].configuracoes["tarifacao"].valor = value;
        break;
      //tarifacao excedente
      case "tarifacaoExcedente":
        newListItens[index].configuracoes[name].status = checked;
        if (checked) {
          newListItens[index].configuracoes["tarifacao"].status = !checked;
          newListItens[index].configuracoes["tarifacaoPlanoFixo"].status =
            !checked;
        }
        break;
      case "tarifacaoExcedente-valor":
        newListItens[index].configuracoes["tarifacaoExcedente"].valor = value
          ? value
          : 0;
        break;
      case "tarifacaoExcedente-valorExcedente":
        newListItens[index].configuracoes["tarifacaoExcedente"].valorExcedente =
          value ? value : 0;
        break;
      case "tarifacaoExcedente-limite":
        newListItens[index].configuracoes["tarifacaoExcedente"].limite = value
          ? value
          : 0;
        break;
      case "tarifacaoPlanoFixo":
        newListItens[index].configuracoes[name].status = checked;
        if (checked) {
          newListItens[index].configuracoes["tarifacao"].status = !checked;
          newListItens[index].configuracoes["tarifacaoExcedente"].status =
            !checked;
        }
        break;
      case "tarifacaoPlanoFixo-valor":
        newListItens[index].configuracoes["tarifacaoPlanoFixo"].valor = value
          ? value
          : 0;
        break;
      case "tarifacaoPlanoFixo-valorExcedente":
        newListItens[index].configuracoes["tarifacaoPlanoFixo"].valorExcedente =
          value ? value : 0;
        break;
      case "tarifacaoPlanoFixo-limite":
        newListItens[index].configuracoes["tarifacaoPlanoFixo"].limite = value
          ? value
          : 0;
        break;
      //horario
      case "horarioPersonalizado":
        newListItens[index].configuracoes[name].status = checked;
        break;

      case "horaInicial":
        newListItens[index].configuracoes["horarioPersonalizado"].horaInicial =
          value;
        break;

      case "horaFinal":
        newListItens[index].configuracoes["horarioPersonalizado"].horaFinal =
          value;
        break;
      // variacaoAbrupta
      case "variacaoAbrupta":
        newListItens[index].configuracoes[name].status = checked;
        break;
      case "variacao":
        newListItens[index].configuracoes["variacaoAbrupta"].variacao = value;
        break;
      // valor minimo para disponibilidade
      case "zeroDown":
        newListItens[index].configuracoes[name].status = checked;
        if (checked)
          newListItens[index].configuracoes["disponibilidade"] = true;
        break;
      case "zeroDown-valor":
        newListItens[index].configuracoes["zeroDown"].valor = value;
        break;
      default:
        newListItens[index].configuracoes[name] = checked;
        break;
    }
    setListTags(newListItens);
  };

  // const addSelectedHosts = () => {
  //   if (selectedHosts.length) {
  //     setSelectedHosts([
  //       ...selectedHosts,
  //       {
  //         id: "",
  //         nome: "",
  //         itens: [],
  //         randomKey: Math.random(),
  //       },
  //     ]);
  //   }
  // };

  // const addSelectedClientes = () => {
  //   if (selectedHosts.length) {
  //     setSelectedClientes([
  //       ...selectedClientes,
  //       {
  //         client: "",
  //         types: [],
  //         randomKey: Math.random(),
  //       },
  //     ]);
  //   }
  // };

  // const removeSelectedHosts = (index) => {
  //   const newSelectedHost = [...selectedHosts];
  //   newSelectedHost.splice(index, 1);

  //   setSelectedHosts(newSelectedHost);
  // };

  // const removeSelectedClientes = (index) => {
  //   const newSelectedClientes = [...selectedClientes];
  //   newSelectedClientes.splice(index, 1);

  //   setSelectedClientes(newSelectedClientes);
  // };

  // const handleTarifacaoExedente = (event) => {
  //   const { name, value, checked } = event.target;
  //   const newTarifacao = { ...tarifacaoExcedente };
  //   newTarifacao[name] =
  //     name === "status"
  //       ? checked
  //       : name === "limite"
  //       ? value
  //       : maskCurrency(value);
  //   setTarifacaoExcedente(newTarifacao);
  // };

  // const handleOcultarGraficos = (event) => {
  //   const { name, value, checked } = event.target;
  //   const newFeature = checked;
  //   setOcultarGraficos(newFeature);
  // };

  // const handleAgruparPorNome = (event) => {
  //   const { name, value, checked } = event.target;
  //   const newAgruparPorNome = { ...agruparPorNome };
  //   newAgruparPorNome[name] =
  //     name === "status" ? checked : name === "cliente" ? value : null;

  //   setAgruparPorNome(newAgruparPorNome);
  // };

  // async function handleApiGetHosts() {
  //   {
  //     try {
  //       setLoadingHosts(true);

  //       const response = await api.get("/easyReport/HostName/find");

  //       if (response.data.status === "Error") {
  //         console.error(response.data);
  //         setSnackbar({
  //           children: "Error: Não foi possível buscar hosts",
  //           severity: "error",
  //         });
  //       } else {
  //         setHosts(response.data);
  //       }
  //     } catch (error) {
  //       setSnackbar({
  //         children: "Error: Falha ao buscar hosts no conectar com o servidor",
  //         severity: "error",
  //       });

  //       console.error(error);
  //     } finally {
  //       setLoadingHosts(false);
  //     }
  //   }
  // }
  // async function handleApiFindClientsFlowDB() {
  //   try {
  //     setLoadingClientes(true);

  //     const response = await api.get("/easyReport/findClientsFlowDB");

  //     if (response.data.status === "Error") {
  //       setSnackbar({
  //         children: "Error: Não foi possível buscar Clientes",
  //         severity: "error",
  //       });
  //     } else {
  //       setClientes(response.data);
  //     }
  //   } catch (error) {
  //     console.error(error);

  //     setSnackbar({
  //       children: "Error: Não foi possível se conectar com o servidor",
  //       severity: "error",
  //     });
  //   } finally {
  //     setLoadingClientes(false);
  //     setDisableClientes(false);
  //   }
  // }
  // async function handleApiFindInterfacesFlow(params) {
  //   try {
  //     const response = await api.get("/easyReport/findInterfacesFlowDB");

  //     if (response.data.status === "Error") {
  //       setSnackbar({
  //         children: "Error: Não foi possível buscar Interfaces do Flow",
  //         severity: "error",
  //       });
  //     } else {
  //       setInterfacesFlow(response.data);
  //     }
  //   } catch (error) {
  //     console.error(error);

  //     setSnackbar({
  //       children: "Error: Não foi possível Buscar Interfaces do Flow",
  //       severity: "error",
  //     });
  //   }
  // }

  async function handleApiClienteProduto() {
    try {
      const response = await api.post("/cliente/produto/findMany");
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar produtos",
          severity: "error",
        });
      } else {
        setProdutos(response.data);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Falha ao buscar produtos no conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    }
  }

  async function handleApiCreateRelatorioFlow() {
    try {
      setLoadingButton(true);
      const data = {
        titulo,
        status: 1,
        tipo: 1,
        acessoProdutoId: produto,
        periodoColeta,
        dataInicial: dayjs(dataInicial),
        dataFinal: dayjs(dataFinal),
        hosts: agruparPorNome.status
          ? [
              {
                nome: agruparPorNome.cliente.trim(),
                hostId: null,
                itens: {
                  filterInterfaceFlow: filterInterfaceFlow.map((filter) => {
                    return filter.interface;
                  }),
                  agruparPorNome: agruparPorNome.status,
                  inet: ["IPv4", "IPv6"],
                  tarifacao: {
                    FNA: {
                      valor: fna ? parseFloat(fna.replace(/[.,]/g, ".")) : 0,
                    },
                    GGC: {
                      valor: ggc ? parseFloat(ggc.replace(/[.,]/g, ".")) : 0,
                    },
                    "IX/PTT": {
                      valor: ixPpt
                        ? parseFloat(ixPpt.replace(/[.,]/g, "."))
                        : 0,
                    },
                    "TRANS/IP": {
                      valor: transIp
                        ? parseFloat(transIp.replace(/[.,]/g, "."))
                        : 0,
                    },
                    "OCA|NETFLIX": {
                      valor: ocaNetflix
                        ? parseFloat(ocaNetflix.replace(/[.,]/g, "."))
                        : 0,
                    },
                  },
                  tarifacaoExcedente: {
                    status: tarifacaoExcedente.status,
                    valor: parseFloat(
                      tarifacaoExcedente.valor.replace(/[.,]/g, ".")
                    ),
                    valorExcedente: parseFloat(
                      tarifacaoExcedente.valorExcedente.replace(/[.,]/g, ".")
                    ),
                    limite: parseFloat(
                      tarifacaoExcedente.limite.replace(/[.,]/g, ".")
                    ),
                  },
                  horarioPersonalizado: {
                    status: false,
                  },
                  hideCharts: ocultarGraficos,
                },
              },
            ]
          : selectedClientes.map((host) => {
              const hostData = {
                nome: host.client,

                hostId: null,
                itens: {
                  filterInterfaceFlow: filterInterfaceFlow.map((filter) => {
                    return filter.interface;
                  }),

                  inet: host.types.filter((type, id) => {
                    if (host?.typesValue[id] === true) {
                      return type;
                    }
                  }),
                  tarifacao: {
                    FNA: {
                      valor: fna ? parseFloat(fna.replace(/[.,]/g, ".")) : 0,
                    },
                    GGC: {
                      valor: ggc ? parseFloat(ggc.replace(/[.,]/g, ".")) : 0,
                    },
                    "IX/PTT": {
                      valor: ixPpt
                        ? parseFloat(ixPpt.replace(/[.,]/g, "."))
                        : 0,
                    },
                    "TRANS/IP": {
                      valor: transIp
                        ? parseFloat(transIp.replace(/[.,]/g, "."))
                        : 0,
                    },
                    "OCA|NETFLIX": {
                      valor: ocaNetflix
                        ? parseFloat(ocaNetflix.replace(/[.,]/g, "."))
                        : 0,
                    },
                  },
                  tarifacaoExcedente: {
                    status: tarifacaoExcedente.status,
                    valor: parseFloat(
                      tarifacaoExcedente.valor.replace(/[.,]/g, ".")
                    ),
                    valorExcedente: parseFloat(
                      tarifacaoExcedente.valorExcedente.replace(/[.,]/g, ".")
                    ),
                    limite: parseFloat(
                      tarifacaoExcedente.limite.replace(/[.,]/g, ".")
                    ),
                  },
                  horarioPersonalizado: {
                    status: false,
                  },
                  hideCharts: ocultarGraficos,
                },
              };
              return hostData;
            }),
      };
      const response = await api.post(
        "/easyReport/findHistoryFlowDB",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === "Error") {
        console.error(response.data);
        setLoadingButton(false);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setRelatorio(response.data);
      }
    } catch (error) {
      setLoadingButton(false);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    } finally {
    }
  }
  async function handleApiCreateRelatorio() {
    try {
      setLoadingButton(true);
      const data = {
        titulo,
        status: 1,
        tipo: 0,
        acessoProdutoId: produto,
        periodoColeta,
        dataInicial: dayjs(dataInicial),
        dataFinal: dayjs(dataFinal),
        hosts: selectedHosts.map((host) => {
          let newItensArray = [];
          host.itens.map((item) => {
            let newItem = [];
            listTags.map((itemConfig) => {
              if (item.tag === itemConfig.tag) {
                const novoItem = {
                  ...item,
                  configuracoes: {
                    ...itemConfig.configuracoes,
                    hideCharts: ocultarGraficos,
                  },
                };
                novoItem.itemid = item.itemid;
                novoItem.item = item.nome;
                newItem.push(novoItem);
              }
            });

            delete newItem[0]?.itemid;
            delete newItem[0]?.value_type;
            delete newItem[0]?.units;
            //delete newItem[0]?.nome;
            delete newItem[0]?.name;
            //delete newItem[0]?.tag;

            newItensArray.push(newItem);
          });

          let returnData = {
            ...host,
            itens: newItensArray.flat(),
            hostId: host.id,
          };
          delete returnData.randomKey;
          delete returnData.id;
          return returnData;
        }),
      };

      const response = await api.post("/easyReport/findHistoryItem", {
        ...data,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });
      if (response.data.status === "Error") {
        console.error(response.data);
        setLoadingButton(false);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setRelatorio(response.data);
      }
    } catch (error) {
      setLoadingButton(false);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    } finally {
    }
  }

  //sim isso é um array de componentes!
  const steps = [
    {
      label: "Adicione um título e selecione o produto",
      description: (
        <AdicionaTitulo
          produtos={produtos}
          setTitulo={setTitulo}
          setProduto={setProduto}
          setTipoProduto={setTipoProduto}
          titulo={titulo}
          produto={produto}
        />
      ),
    },
    ...(tipoProduto === "easyflow"
      ? [
          {
            label: "Configure o Relatório",
            description: (
              <ConfiguraRelatorioFlow
                fna={fna}
                setFna={setFna}
                ggc={ggc}
                setGgc={setGgc}
                ixPpt={ixPpt}
                setIxPpt={setIxPpt}
                transIp={transIp}
                setTransIp={setTransIp}
                ocaNetflix={ocaNetflix}
                setOcaNetflix={setOcaNetflix}
                agruparPorNome={agruparPorNome}
                setAgruparPorNome={setAgruparPorNome}
                selectedClientes={selectedClientes}
                setSelectedClientes={setSelectedClientes}
                filterInterfaceFlow={filterInterfaceFlow}
                setFilterInterfaceFlow={setFilterInterfaceFlow}
                ocultarGraficos={ocultarGraficos}
                setOcultarGraficos={setOcultarGraficos}
                tarifacaoExcedente={tarifacaoExcedente}
                setTarifacaoExcedente={setTarifacaoExcedente}
                setSnackbar={setSnackbar}
              />
            ),
          },
        ]
      : [
          {
            label: "Configure o Relatório",
            description: (
              <ConfiguraRelatorioMon
                loadingHosts={loadingHosts}
                setLoadingHosts={setLoadingHosts}
                setSnackbar={setSnackbar}
                selectedHosts={selectedHosts}
                setSelectedHosts={setSelectedHosts}
                setOcultarGraficos={setOcultarGraficos}
                ocultarGraficos={ocultarGraficos}
              />
            ),
          },
          {
            label: "Configure os Itens",
            description: (
              <ConfigureOsItens
                agendamento
                listTags={listTags}
                listItens={listItens}
                handleListItensChange={handleListItensChange}
              />
            ),
          },
        ]),
    {
      label: "Intervalo de Coleta",
      description: (
        <Agendamento
          dataInicial={dataInicial}
          setDataInicial={setDataInicial}
          periodoColeta={periodoColeta}
          setPeriodoColeta={setPeriodoColeta}
          terminaEm={terminaEm}
          setTerminaEm={setTerminaEm}
          etapas={etapas}
          setEtapas={setEtapas}
          dataFinal={dataFinal}
          setDataFinal={setDataFinal}
          agendarRelatorio={true}
        />
      ),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Paper
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          padding: "18px",
          mt: "20px",
          minWidth: "840px",
        }}
      >
        <Typography fontSize={"24px"} fontWeight={700} color={"primary.main"}>
          Gerar Relatórios
        </Typography>
        <Typography fontWeight={400}>
          Siga os passos para gerar um relatório
        </Typography>
        <Box>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === steps.length - 1 ? (
                      <Typography variant="caption">Último passo</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {!(index === steps.length - 1) ? (
                        <Button
                          variant="contained"
                          onClick={() => {
                            index === steps.length - 1
                              ? tipoProduto === "easyflow"
                                ? handleApiCreateRelatorioFlow()
                                : handleApiCreateRelatorio()
                              : handleNext();
                          }}
                          sx={{ mt: 1.5, mr: 1 }}
                          disabled={disableButtom[index]}
                        >
                          {index === steps.length - 1
                            ? "Finalizar"
                            : "Continue"}
                        </Button>
                      ) : (
                        <GeneratePdfRelatorio
                          setSnackbar={setSnackbar}
                          relatorio={relatorio}
                          loading={loadingButton}
                          setLoadingButton={setLoadingButton}
                          handleApiCreateRelatorioFlow={
                            handleApiCreateRelatorioFlow
                          }
                          handleApiCreateRelatorio={handleApiCreateRelatorio}
                          tipo={tipoProduto === "easyflow" ? 1 : 0}
                        />
                      )}
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1.5, mr: 1 }}
                      >
                        voltar
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Box>
      </Paper>
      <SnackBar snackbar={snackbar} setSnackbar={setSnackbar} />
    </Box>
  );
}
