import { MenuItem, Stack, TextField } from "@mui/material";

export default function AdicionaTitulo({
  produtos,
  setTitulo,
  setProduto,
  setTipoProduto,
  titulo,
  produto,
}) {
  return (
    <Stack direction={"column"} gap={1}>
      <Stack direction={"row"} gap={2}>
        <TextField
          label="Título"
          sx={{ width: "340px" }}
          size="small"
          value={titulo}
          onChange={(event) => {
            setTitulo(event.target.value);
          }}
          autoComplete="off"
        />
        <TextField
          select
          value={produto}
          sx={{ width: "210px" }}
          size="small"
          label="Selecione o produto"
          onChange={(event) => {
            setProduto(event.target.value);
          }}
        >
          {produtos?.map((p, index) => (
            <MenuItem
              onClick={() => {
                setTipoProduto(p.tipo);
              }}
              key={index}
              id={index}
              value={p.id}
            >
              {p.nome}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Stack>
  );
}
