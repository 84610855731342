import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import React from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";

// const COLOR_CHART = "#5B2E87";
const COLOR_CHART = "#585858";
export default function BooleanChart({ data }) {
  const formatDate = (tickItem) => {
    const result = `${new Date(tickItem).toLocaleDateString()} ${new Date(
      tickItem
    ).toLocaleTimeString()}`;
    return result;
  };

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    // let data = payload.value.split("T")[0];
    // let time = payload.value.split("T")[1].replace(".000Z", "");
    // time = time.split(":")[0] + ":" + time.split(":")[1];
    let time = dayjs(payload.value).format("DD/MM/YYYY");
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={20}
          y={10}
          //dy={50}
          fontSize={12}
          textAnchor="end"
          fill="black"
          transform="rotate(-20)"
        >
          {/* {payload.value.replace("T", " ").replace(".000Z", "")} 
          {data.split("-")[2] +
            "/" +
            data.split("-")[1] +
            "/" +
            data.split("-")[0] +
            " " +*/}
          {time}
        </text>
      </g>
    );
  };
  const CustomizedYaxisTick = ({ x, y, stroke, payload }) => {
    if (payload.value !== 0 && payload.value !== 1) {
      return null; // Retorna null para não renderizar ticks que não sejam 0 ou 1
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          //dy={50}
          fontSize={13}
          textAnchor="end"
          fill="black"
          //transform="rotate(-60)"
        >
          {payload.value == 1 ? "UP" : "DOWN"}
        </text>
      </g>
    );
  };
  const intervalo = parseInt(data.length / 16);
  return (
    <Box>
      <AreaChart
        data={data}
        width={790}
        height={200}
        margin={{ top: 20, right: 40, left: 5, bottom: 10 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="4%" stopColor={COLOR_CHART} stopOpacity={1} />
            <stop offset="96%" stopColor={COLOR_CHART} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="datetime"
          type="category"
          //interval={0}
          interval={intervalo}
          height={50}
          tick={<CustomizedAxisTick />}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis tick={<CustomizedYaxisTick />} />
        <Area
          type="step"
          dataKey="value"
          stroke={COLOR_CHART}
          fill="url(#colorUv)"
          //dot={false}
          //label={<CustomizedLabel />}
          isAnimationActive={false}
        />
      </AreaChart>
    </Box>
  );
}
