import { useState } from "react";

import { createSvgIcon, IconButton, Tooltip, useTheme } from "@mui/material";
import { ReactComponent as IconDots } from "../../../assets/easyMaps/iconsSvg/dots-menu.svg";
import { GetAppRounded, Settings } from "@mui/icons-material";

export default function Config({ setOpenConfig = () => {} }) {
  const theme = useTheme();
  const styles = {
    dotsIcon: {
      padding: "3px",
      fontSize: "29px",
      fill: theme.palette.text.primary,
      ":hover": { opacity: 1, transform: "rotate(90deg)" },
    },
  };
  return (
    <>
      <Tooltip
        title="Configurações Globais"
        sx={{ display: { xs: "none", xsm: "flex" } }}
      >
        <IconButton
          color="inherit"
          aria-label="notificacoes"
          onClick={(event) => {
            setOpenConfig((data) => ({ open: !data.open, tab: 0 }));
          }}
        >
          <Settings sx={styles.dotsIcon} />
        </IconButton>
      </Tooltip>
    </>
  );
}
