import {
  Paper,
  useTheme,
  createSvgIcon,
  IconButton,
  Tooltip,
  Divider,
  alpha,
} from "@mui/material";
import { ReactComponent as IconRouterM } from "../../../assets/easyMaps/Router.svg";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import CableIcon from "@mui/icons-material/Cable";
import SubMenuMaps from "./subMenuMap";
import { useState, useRef, useEffect, createElement } from "react";
import IconTransform from "../iconsElement";

export default function MenuMaps({
  setElementSelected,
  iconsElement,
  setConnectionSelected,
  checkChanges,
  setOpenAlertModal = () => {},
  saveUpdateCallback = () => {},
}) {
  const theme = useTheme();
  const [positionSub, setpositionSub] = useState(null);
  const [openSub, setOpenSub] = useState(false);
  const meuRef = useRef(null);
  const styles = {
    button: {
      borderRadius: "5px",
      width: "70px",
      "&:hover": { backgroundColor: alpha(theme.palette.secondary.main, 0.3) },
    },
    card: {
      borderRadius: "7px",
      position: "absolute",
      bottom: "15px",
      left: "0",
      right: "0",
      marginLeft: "auto",
      marginRight: "auto",
      p: "0px 5px",
      gap: "5px",
      display: "flex",
      direction: "row",
      height: "50px",
      width: "fit-content",
      alignItems: "center",

      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
      border: "1px solid " + theme.palette.color.appBarBorder,
    },
  };
  const updatePosition = () => {
    if (meuRef.current) {
      const elemento = meuRef.current;
      const { top, left, bottom, right } = elemento.getBoundingClientRect();
      setpositionSub({ x: (left + right) / 2, y: (bottom + top) / 2 });
    }
  };

  function createElement(tipo, icon) {
    setConnectionSelected(null);
    setElementSelected({ img: icon, tipo: tipo });
  }

  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  const IconButtonMaps = (nome, tipo) => {
    const icon = iconsElement.find((icon) => icon.nome == nome);
    if (tipo == 0) {
      return (
        <Tooltip title={"elementos"}>
          <IconButton
            onClick={(event) => {
              setOpenSub(true);
            }}
            aria-label="delete"
            sx={styles.button}
          >
            <IconTransform img={icon?.img} />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={nome.replace("_", "")}>
          <IconButton
            onClick={() => {
              if (checkChanges)
                setOpenAlertModal({
                  open: true,
                  callback: (tipo, iconId) => {
                    saveUpdateCallback();
                    createElement(tipo, iconId);
                  },
                  params: [tipo, icon.id],
                  severity: "info",
                  buttonText: "Salvar",
                  buttonCancelText: "Voltar",
                  text: "As alterações feitas não foram salvas. Deseja Salvá-las?",
                });
              else createElement(tipo, icon.id);
            }}
            aria-label="delete"
            sx={styles.button}
          >
            <IconTransform img={icon?.img} />
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <>
      <SubMenuMaps
        iconsElement={iconsElement.filter(
          (icon) => icon.nome != "_mapa" && icon.nome != "_pop"
        )}
        setConnectionSelected={setConnectionSelected}
        openSub={openSub}
        position={positionSub}
        setOpenSub={setOpenSub}
        setElementSelected={setElementSelected}
        setOpenAlertModal={setOpenAlertModal}
        checkChanges={checkChanges}
        saveUpdateCallback={saveUpdateCallback}
      />
      <Paper ref={meuRef} elevation={0} sx={styles.card}>
        {IconButtonMaps("_roteador", 0)}
        <Divider orientation="vertical" sx={{ height: "20px" }} />
        {IconButtonMaps("_mapa", 1)}
        <Divider orientation="vertical" sx={{ height: "20px" }} />
        {IconButtonMaps("_pop", 2)}
      </Paper>
    </>
  );
}
