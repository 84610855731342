import L from "leaflet";
import { Box, useTheme, alpha } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import IconTransform from "../../../../../componentes/easyMaps/iconsElement";
import * as ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import styled, { css, keyframes } from "styled-components";
import { useOutletContext } from "react-router-dom";

const pulseRingAnimation = keyframes`
0% {
  transform: scale(0.33);
}
80%, 100% {
  opacity: 0;
}
`;

const alternateBorder = keyframes`
0% {
  stroke: #eb9e9e8f;
}
100% {
   stroke: #EE1127 ;
}
`;

const Pulse = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: -1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  &:before {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    margin-top: -40px;
    margin-left: -40px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    animation: ${pulseRingAnimation} 2s infinite;
  }
`;

const Select = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); // Aumenta o tamanho em 10%
  }
  100% {
    transform: scale(1); // Retorna ao tamanho original
  }
`;

const AnimatedStackDrop = styled.div`
  width: 100%;
  filter: drop-shadow(
    0px 0px 10px
      ${({ $selected, $border, $isDown }) =>
        $selected ? ($isDown ? "#EE1127" : $border) : "none"}
  );
  height: 100%;
  position: relative;
  opacity: ${({ $disable }) => ($disable ? 0.4 : 1)};
  pointer-events: ${({ $disable }) => ($disable ? css`none` : css`initial`)};
  animation: ${({ $selected }) =>
      $selected
        ? css`
            ${Select} 2s infinite
          `
        : "none"},
    ${({ $isDown, $editable }) =>
      $isDown
        ? css`
            ${alternateBorder} 0.5s infinite alternate
          `
        : "none"};
`;

const Paragraph = styled.p`
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  max-width: 150px; /* Garante que o texto não ultrapasse 200px */
  display: ${({ $showLabel }) => ($showLabel ? "block" : "none")};
  font-size: 13px;
  color: ${({ $color }) => $color || "white"};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

const AnimatedSVG = styled.svg`
  width: 100%;

  height: 100%;
  animation: ${({ $isDown, $editable }) =>
    $isDown && !$editable
      ? css`
          ${alternateBorder} 0.5s infinite alternate
        `
      : "none"};
`;

export const NodeIcon = memo(
  ({
    size = 10,
    showLabel,
    element,
    color,
    editable = false,
    onNodeClick = () => {},
    iconsElement = () => {},
    selected = false,
    disable = false,
    configLocal,
  }) => {
    const { config } = useOutletContext();
    const theme = useTheme();
    const [severity, setSeverity] = useState(1);
    const [isDown, setIsdown] = useState(false);
    const [sameDown, setSameDown] = useState(false);
    const [isLow, setLow] = useState(false);

    const [colors, setColors] = useState({
      background: alpha(theme.palette.background.default, 0.5),
      border: alpha(theme.palette.background.default, 0.9),
    });

    const styles = {
      iconBox: {
        width: 0.5 * size * 6 + "px",
        height: 0.6 * size * 6 + "px",
        position: "relative",
        opacity: disable ? 0.4 : 1,
        pointerEvents: disable ? "none" : "initial",
      },
      icon: {
        position: "absolute",
        left: 0,
        right: 0,
        top: -size * 0.8,
        bottom: 0,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    };

    function colorEnlace(power) {
      if (power == null || power == 0 || power === Infinity || !config) {
        return theme.palette.enlaces.offline.main;
      }
      const result = config?.escalas?.ftth
        .filter((scale) => parseFloat(scale?.label) >= power)
        .pop();

      if (!result) return theme.palette.enlaces.offline.main;
      return result?.cor;
    }

    useEffect(() => {
      if (element.tipoBgpIxc == 0) {
        if (element.data.potencia.rx <= -40) {
          setSeverity(5);
          setIsdown(true);
        }
        setColors({
          background: alpha(colorEnlace(element.data.potencia.rx), 0.6),
          border: colorEnlace(element.data.potencia.rx),
        });

        //}
      } else if (element.tipoBgpIxc == 1) {
        // elementos ONU

        if (element.data.status.value == 0 || element.data.potencia.rx == -40) {
          setIsdown(true);
        }

        setColors({
          background: alpha(colorEnlace(element.data.potencia.rx), 0.6),
          border: colorEnlace(element.data.potencia.rx),
        });
      }
    }, [
      element.elementsClient,
      element.data,
      editable,
      theme,
      configLocal,
      config,
    ]);

    const CustomIcon = () => (
      <>
        <AnimatedStackDrop
          $size={size}
          $disable={disable}
          $selected={selected}
          $border={colors?.border}
          $isDown={isDown}
        >
          {severity > 3 &&
            (configLocal?.animacao?.alertas == null ||
              configLocal?.animacao?.alertas == true) && (
              <Pulse color={colors.border} />
            )}
          <Paragraph $showLabel={showLabel} $color={theme.palette.color.text}>
            {element?.titulo}
          </Paragraph>

          <AnimatedSVG
            stroke={colors?.border}
            viewBox="0 0 61 80"
            fill={colors?.background}
            strokeWidth={selected || isDown ? "3.5px" : "3px"}
            $isDown={isDown}
            $disable={disable}
          >
            <g clipPath="url(#clip0_3415_75702)">
              <path
                d="M60.5 32.3279C60.5 40.5441 55.6822 49.0818 49.3749 57.0649C44.3952 63.3676 38.5424 69.259 33.5186 74.316C32.3224 75.5201 31.1732 76.6769 30.0939 77.7807C29.1845 76.8332 28.2168 75.8377 27.2072 74.7991C22.32 69.7717 16.4508 63.734 11.4501 57.2306C5.27016 49.1939 0.5 40.5477 0.5 32.3279C0.5 15.7661 13.5677 0.5 30.0906 0.5C46.6302 0.5 60.5 15.7842 60.5 32.3279Z"
                filter="url(#filter0_d)"
              />
            </g>
          </AnimatedSVG>

          <Box style={styles.icon}>
            <IconTransform
              img={
                iconsElement?.find((icon) => icon.id == element.img)?.img ||
                iconsElement?.find(
                  (icon) =>
                    icon.nome ==
                    (element.tipo === 0
                      ? "_roteador"
                      : element.tipo === 1
                      ? "_mapa"
                      : "_pop")
                )?.img
              }
              color={severity > 4 ? "white" : color}
              size={size * 2}
            />
          </Box>
        </AnimatedStackDrop>
      </>
    );

    const iconPerson = () => {
      return L.divIcon({
        html: ReactDOMServer.renderToStaticMarkup(<CustomIcon />),

        // size > 7 ? <CustomIcon /> : <CustomIconCircle /> //<CustomIconC />

        // iconSize: [0.5 * size * 10, 0.6 * size * 10],
        iconSize: [0.5 * size * 7, 0.6 * size * 7],
        iconAnchor: [0.25 * size * 7, 0.6 * size * 7],
      });
    };

    return (
      <Marker
        key={element.id + "_marker"}
        eventHandlers={{
          click: (e) => {
            onNodeClick(e, element);
          },
        }}
        position={[
          element?.elementConfig?.config?.coordenadas?.lat || 0,
          element?.elementConfig?.config?.coordenadas?.long || 0,
        ]}
        icon={iconPerson()}
        rx={element?.data?.potencia?.rx}
        isDown={isDown}
        isLow={isLow}
        someDown={sameDown}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.size === nextProps.size &&
      prevProps.showLabel === nextProps.showLabel &&
      prevProps.color === nextProps.color &&
      prevProps.selected === nextProps.selected &&
      prevProps.disable === nextProps.disable &&
      JSON.stringify(prevProps.configLocal) ===
        JSON.stringify(nextProps.configLocal) &&
      prevProps.element.data.potencia.rx === nextProps.element.data.potencia.rx
    );
  }
);
