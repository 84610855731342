import ReactFlow, {
  Background,
  ConnectionMode,
  MarkerType,
  ReactFlowProvider,
  addEdge,
  getNodesBounds,
  updateEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import ToolBarMaps from "../../../../../componentes/easyMaps/toolbarMaps";
import ZoomFlow from "../../../../../componentes/easyMaps/ZoomFlow";
import NodeMenu from "../../../../../componentes/easyMaps/nodeMenu";
import { Box, Stack, useTheme } from "@mui/material";
import ElementNode from "../../../../../componentes/easyMaps/elementNode";
import PositionableEdge from "../../../../../componentes/easyMaps/positionableEdge";
import { useCallback, useEffect, useRef, useState } from "react";
import AlertModal from "../../../../../componentes/alertModal";
import ContextMenu from "../../../../../componentes/easyMaps/contextMenu";
import FilterElements from "../../../../../componentes/easyMaps/filterElements";
import TitleBar from "../../../../../componentes/easyMaps/titleBar";
import DescriptionBar from "../../../../../componentes/easyMaps/descriptionBar";
import RightBarEasyMaps from "../../../../../componentes/easyMaps/rightBarEasyMaps";
import EnlaceSideBarEasyMaps from "../../../../../componentes/easyMaps/enlaceSidebarEasyMaps";
import CreationSliderBarEasyMaps from "../../../../../componentes/easyMaps/creationSidebarEasymaps";
import api from "../../../../../services/api";
import { useParams } from "react-router-dom";
import { select } from "d3-selection";
import MenuMaps from "../../../../../componentes/easyMaps/menuMaps";
const nodeTypes = {
  element: ElementNode,
};
const edgeTypes = {
  positionableedge: PositionableEdge,
};
function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = JSON.parse(JSON.stringify(value ?? []));
  }, [value]);

  return ref.current;
}

const ReactFlowMapas = (props) => {
  const {
    connections,
    connectionsAnalog,
    setEdgeSelected,
    elements,
    elementSelected,
    setElementSelected,
    setElementsConnected,
    connectionSelected,
    setConnectionSelected,
    iconsElements,
    setOpenRightBar,
    focusNodeId,
    handleCreateElement,
    handleUpdateElement,
    handleDeleteElement,
    handleCreateLink,
    handleDeleteLink,
    handleUpdateLink,
    handleCancelLink,
    editMode,
    setEditMode,
    fullScreem,
    setFullScreem,
    acessoProdutoId,
    mapId,
    title,
    setSnackbar,
    checkChanges,
    setCoordenadasFlow = () => {},
    setIconsElements,
    setElements,
    setConnections,
    edgeSelected,
    coordenadasFlow,
    elementsConnected,
    openRightBar,
    setFocusNodeId,
    socketCliente,
    mapas,
    addMapInMaps,
    produtos,
    changeConnection,
    setCheckChanges,
    updateDataElementSocket = () => {},
    loadinButtonEnlace,
    setEasyMapsConfig = () => {},
    easyMapsConfig,
  } = props;
  const tipo = "easymon";
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const bounds = getNodesBounds(nodes.filter((node) => !!node?.id));
  const [lockMode, setLockMode] = useState(false);
  //const [fullScreem, setFullScreem] = useState(false);
  const [showLabel, setShowLabel] = useState(true);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  //const [menuCreation, setMenuCreation] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuFlow, setContextMenuFlow] = useState(null);
  const [elementsFilter, setElementsFilter] = useState([]);
  const [openConnection, setOpenConnection] = useState(null);
  const [showLabelEnlace, setShowLabelEnlace] = useState(true);
  const previousHandlers = usePrevious(edgeSelected?.data?.positionHandlers);
  const previousConnection = usePrevious(edgeSelected?.id);
  const ref = useRef();
  const creationRef = useRef();
  const enlaceBarRef = useRef();
  const { id } = useParams();

  const theme = useTheme();
  const styles = {
    stackContainer: {
      position: fullScreem ? "fixed" : "relative",
      top: 0,
      left: 0,
      width: fullScreem ? "100vw" : "100%",
      height: fullScreem ? "100vh" : "calc(100vh - 70px)",
      zIndex: fullScreem ? 1202 : 0,
      background: theme.palette.background.default,
      overflow: "visible",
    },
  };
  useEffect(() => {
    setCoordenadasFlow({
      x: bounds.x + bounds.width / 2,
      y: bounds.y + bounds.height / 2,
    });
  }, [nodes]);

  // useEffect(() => {
  //   const positionHandlers = edgeSelected?.data?.positionHandlers;
  //   if (
  //     !checkChanges &&
  //     !!previousHandlers &&
  //     !!positionHandlers &&
  //     previousConnection === edgeSelected?.id &&
  //     JSON.stringify(previousHandlers) !== JSON.stringify(positionHandlers)
  //   ) {
  //     console.log("mudou");

  //     //monitora se ouve alteração no positionHandlers de edgeSelected e se a alteração foi na mesma edgeSelected e muda checkChanges para true
  //     setCheckChanges(true);
  //   }
  // }, [
  //   edgeSelected?.data?.positionHandlers,
  //   edgeSelected?.data?.positionHandlers?.length,
  // ]);

  useEffect(() => {
    setContextMenu(null);
    setContextMenuFlow(null);
    setElementSelected(null);
    setConnectionSelected(null);
    //setOpenConnection(null);
    setElementsConnected(null);
    setNodes(handleFormaterNodes(elements, editMode, showLabel));
    setEdges(handleFormaterEdges(connections, editMode, showLabel));
  }, [editMode, showLabel, showLabelEnlace, elementsFilter, easyMapsConfig]);

  useEffect(() => {
    if (!elements && !connections) return;

    let formaterElements = elements;
    if (elementSelected) {
      formaterElements = [
        ...elements.filter((ele) => ele?.id !== elementSelected?.id),
        elementSelected,
      ];
    }
    let formaterConnections = connections;
    if (connectionSelected) {
      formaterConnections = [
        ...connections.filter((conn) => conn?.id !== connectionSelected?.id),
        {
          ...connectionSelected,
          positionHandlers: edgeSelected?.data?.positionHandlers,
        },
      ];
    }
    setNodes(handleFormaterNodes(formaterElements, editMode, showLabel));
    setEdges(handleFormaterEdges(formaterConnections, editMode, showLabel));
  }, [elements, connections]);

  // transformar em useCallback, caso queira.
  function updateElements() {
    if (!elementSelected) {
      setNodes(handleFormaterNodes(elements, editMode, showLabel));
    } else {
      let formaterElements = elements;
      formaterElements = [
        ...elements.filter((ele) => ele?.id !== elementSelected?.id),
        elementSelected,
      ];
      setNodes(handleFormaterNodes(formaterElements, editMode, showLabel));
    }
  }

  function updateConnection() {
    if (!connectionSelected) {
      setEdges(handleFormaterEdges(connections, editMode, showLabel));
    } else {
      let formaterConnections = connections;
      formaterConnections = [
        ...connections.filter((conn) => conn?.id !== connectionSelected?.id),
        {
          ...connectionSelected,
          positionHandlers: edgeSelected?.data?.positionHandlers,
        },
      ];
      setEdges(handleFormaterEdges(formaterConnections, editMode, showLabel));
    }
  }
  useEffect(() => {
    updateElements();
  }, [elementSelected]);

  useEffect(() => {
    updateConnection();
  }, [connectionSelected]);

  useEffect(() => {
    if (!connectionSelected) {
      setEdges(handleFormaterEdges(connections, editMode, showLabel));
    } else {
      let formaterConnections = connections;
      formaterConnections = [
        ...connections.filter((conn) => conn?.id !== connectionSelected?.id),
        {
          ...connectionSelected,
          positionHandlers: edgeSelected?.data?.positionHandlers,
        },
      ];
      setEdges(handleFormaterEdges(formaterConnections, editMode, showLabel));
    }
  }, [connectionSelected]);
  //-----funcoes para formatação de dados

  const handleFormaterNodes = (
    elements,
    editMode = false,
    showLabel = true,
    entangled = true,
    selected = false
  ) => {
    const filter = elementsFilter.map((value) => value.tipo);
    const newElements =
      elementsFilter.length > 0
        ? elements.filter(({ tipo }) => filter.includes(tipo))
        : elements;

    const result = newElements.map(
      ({ id, elementConfig, titulo, alerts, img, tipo }) => {
        return {
          id,
          type: "element",
          draggable: elementSelected && elementSelected?.id == id && editMode,
          position: {
            x: parseFloat(elementConfig?.config?.coordenadas?.long),
            y: parseFloat(elementConfig?.config?.coordenadas?.lat),
          },
          selected: elementSelected?.id === id ? true : false,
          data: {
            img:
              iconsElements?.find((icon) => icon.id == img)?.img ||
              iconsElements?.find(
                (icon) =>
                  icon.nome ==
                  (tipo === 0 ? "_roteador" : tipo === 1 ? "_mapa" : "_pop")
              )?.img,

            editMode,
            showLabel,
            entangled,
            title: titulo,
            hosts: elementConfig?.config?.hosts,
            tipoMapa: 1,
            alerts: editMode ? [] : alerts,
            easyMapsConfig: JSON.parse(JSON.stringify(easyMapsConfig)),
            value: 123,
          },
        };
      }
    );
    return result;
  };
  const handleFormaterEdges = (
    conections,
    editMode = false,
    showLabel = true,
    entangled = true
  ) => {
    const result = conections.map(
      ({ id, referenceId, destinationId, positionHandlers, config, data }) => {
        return {
          id,
          source: referenceId,
          type: "positionableedge",
          animation: true,
          sourceHandle: config?.sourceHandle,
          targetHandle: config?.targetHandle,
          target: destinationId,
          animated: !editMode && config?.animation !== "desativada",
          updatable: "target",
          markerEnd: editMode
            ? {
                type: MarkerType.ArrowClosed,
                color: "#5e5e5e",
              }
            : "",
          labelStyle: { fill: "#26721f", fontWeight: 700 },
          selected: connectionSelected?.id === id ? true : false,
          data: {
            itens: data,
            editMode,
            showLabel,
            showLabelEnlace,
            entangled,
            lineType: config?.type,
            capacidade: config?.capacidade,
            positionHandlers: JSON.parse(
              JSON.stringify(positionHandlers ?? [])
            ), //perigo eminente, sob hipótese nenhuma mexa nessa linha de código!
            label: "999.99Mbps",
            animation: config?.animation,
            easyMapsConfig: JSON.parse(JSON.stringify(easyMapsConfig)),
          },
        };
      }
    );
    return result;
  };
  //----funcoes do menu
  const onNodeContextMenu = useCallback(
    (event, node) => {
      event.preventDefault();
      if (editMode) {
        setContextMenu(
          contextMenu === null
            ? {
                id: node.id,
                top: event.clientY - 6,
                left: event.clientX + 2,
              }
            : null
        );
      }
    },
    [setContextMenu, editMode]
  );

  //-----react flow
  const onContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      setContextMenuFlow(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null
      );
    },
    [setContextMenuFlow, editMode]
  );

  const updateMemberStatus = (elements, ids, updateData) => {
    return elements.map((element) => ({
      ...element,
      data: {
        ...element.data,
        ...updateData(ids.includes(element.id)),
      },
    }));
  };
  const proceedUpdateMode = () => {
    if (elementSelected) {
      creationRef?.current?.update();
    }
    if (connectionSelected) {
      enlaceBarRef?.current?.update();
    }
    setEditMode(!editMode);
    setFullScreem(false);
  };

  const proceedUpdateElement = useCallback(
    (id) => {
      const selected = JSON.parse(
        JSON.stringify(elements.find((ele) => ele.id === id))
      );

      if (selected?.id === elementSelected?.id) {
        setElementSelected(null);
        return;
      }
      setConnectionSelected(null);
      setOpenConnection(null);
      setEdgeSelected(null);
      setElementSelected(selected);

      updateConnection();
    },
    [elementSelected, elements, connectionSelected]
  );

  const saveUpdateCallback = () => {
    if (elementSelected) {
      creationRef?.current?.update();
    }
    if (connectionSelected) {
      enlaceBarRef?.current?.update();
    }
  };
  const cancelUpdateElement = useCallback(() => {
    setElementSelected({ ...elementSelected });
    setConnectionSelected(null);
    setEdgeSelected(null);
    updateElements();
    updateConnection();
  }, [elementSelected]);

  const proceedUpdateConnection = useCallback(
    (id) => {
      const conn = connections.find((conn) => conn.id === id);
      let edge = edges.find((edge) => id === edge.id);
      setEdgeSelected(edge);

      if (!editMode) {
        setOpenConnection(id);
        setElementSelected(
          JSON.parse(
            JSON.stringify(elements.find(({ id }) => id == conn?.referenceId))
          )
        );
        setEdges((edg) =>
          edg.map(({ data, ...rest }) => ({
            ...rest,
            data: { ...data, entangled: true },
          }))
        );
      } else {
        setElementSelected(null);
      }
      setConnectionSelected({
        ...conn,
        positionHandlers: edge?.data?.positionHandlers,
      });
      updateElements();
    },
    [connectionSelected, elementSelected, edges, connections, elements]
  );

  const cancelUpdateConnection = useCallback(() => {
    setConnectionSelected({
      ...connectionSelected,
      positionHandlers: edgeSelected?.data?.positionHandlers,
    });
    setEdgeSelected({ ...edgeSelected });
    setElementSelected(null);
    updateElements();
    updateConnection();
  }, [connectionSelected, edgeSelected]);

  const onNodeClick = (event, node) => {
    //verificações de modificação
    if (editMode && checkChanges) {
      if (connectionSelected && !elementSelected) {
        setOpenAlertModal({
          id: node.id,
          open: true,
          severity: "info",
          buttonText: "Salvar",
          buttonCancelText: "Voltar",
          text: "As alterações feitas não foram salvas. Deseja Salvá-las?",
          callback: (id) => {
            enlaceBarRef?.current?.update();
            proceedUpdateElement(id);
          },
          callbackCancel: cancelUpdateConnection,
        });
        return;
      }
      if (
        elementSelected?.id !== node?.id &&
        elementSelected &&
        !connectionSelected
      ) {
        setOpenAlertModal({
          id: node.id,
          open: true,
          severity: "info",
          buttonText: "Salvar",
          buttonCancelText: "Voltar",
          text: "As alterações feitas não foram salvas. Deseja Salvá-las?",
          callback: (id) => {
            creationRef?.current?.update();
            proceedUpdateElement(id);
          },
          callbackCancel: cancelUpdateElement,
        });
        return;
      }
      return;
    }
    // Lógica normal
    proceedUpdateElement(node.id);
  };

  const onNodeDrag = (event, node) => {
    setCheckChanges(true);

    if (!elementSelected || elementSelected?.id !== node?.id) {
      setNodes(handleFormaterNodes(elements, editMode, showLabel));

      if (edgeSelected) setEdgeSelected({ ...(edgeSelected || null) });
      if (connectionSelected)
        setConnectionSelected({ ...(connectionSelected || null) });
      return;
    }
  };

  const onEdgeClick = (event, edge) => {
    if (editMode && checkChanges) {
      if (
        connectionSelected?.id !== edge?.id &&
        connectionSelected &&
        !elementSelected
      ) {
        setOpenAlertModal({
          id: edge.id,
          open: true,
          severity: "info",
          buttonText: "Salvar",
          buttonCancelText: "Voltar",
          text: "As alterações feitas não foram salvas. Deseja salvar?",
          callback: (id) => {
            enlaceBarRef?.current?.update();
            proceedUpdateConnection(id);
          },
          callbackCancel: cancelUpdateConnection,
        });
        return;
      }
      if (elementSelected && !connectionSelected) {
        setOpenAlertModal({
          id: edge.id,
          open: true,
          severity: "info",
          buttonText: "Salvar",
          buttonCancelText: "Voltar",
          text: "As alterações feitas não foram salvas. Deseja salvar?",

          callback: (id) => {
            creationRef?.current?.update();
            proceedUpdateConnection(id);
          },
          callbackCancel: cancelUpdateElement,
        });
        return;
      }
    }

    if (connectionSelected?.id !== edge?.id) proceedUpdateConnection(edge.id);
    else {
      setEdgeSelected(edges?.find((edge) => edge.id === edgeSelected?.id));
    }
  };
  const onEdgeUpdate = (oldEdge, newConnection) => {
    setCheckChanges(true);
    if (newConnection.source === newConnection.target) {
      return false;
    }
    let selected = connections.find(({ id }) => id === oldEdge.id);
    setConnectionSelected(selected);
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
    setEdgeSelected((edge) => {
      return { ...edge, ...newConnection };
    });
    if (selected) {
      selected.destinationId = newConnection.target;
      const line = selected?.config?.type;
      const capacidade = selected?.config?.capacidade;
      const animation = selected?.config?.animation;
      handleUpdateLink(selected, newConnection, line, capacidade, animation);
    }
  };

  const onConnect = useCallback(
    (params) => {
      // if (!isValidConnection(params)) {
      //   return;
      // }
      const formateParams = {
        id: Math.random(),
        type: "positionableedge",
        target: params.target,
        source: params.source,
        //animation: false,
        animated: false,
        sourceHandle: params.sourceHandle,
        targetHandle: params.targetHandle,
        markerEnd: editMode
          ? {
              type: MarkerType.ArrowClosed,
              color: "#5e5e5e",
            }
          : "",
        //labelStyle: { fill: "#26721f", fontWeight: 700 },
        data: {
          editMode: true,
          showLabel,
          entangled: true,
          lineType: "reta",
          positionHandlers: [],
          label: "999.99Mbps",
        },
      };
      const referenceElem = elements.find(({ id }) => id === params.source);
      let hostOriginId = null;
      if (referenceElem.tipo == 0) {
        hostOriginId = referenceElem?.elementConfig?.config?.hosts[0]?.hostid;
      }
      const acessoProdutoId = referenceElem?.elementConfig?.config?.produtoId;
      const newConnection = {
        id: "",
        destinationId: params.target,
        referenceId: params.source,
        positionHandlers: [],
        config: {
          type: "reta",
          sourceHandle: params.sourceHandle,
          targetHandle: params.targetHandle,
          capacidade: "0",
          animation: "desativada",
          hostOriginId,
        },
        item: [
          {
            acessoProdutoId,
            hostOriginId,
          },
        ],
      };
      setCheckChanges(true);
      setConnectionSelected(newConnection);
      setEdgeSelected(formateParams);
      setEdges((els) => addEdge(formateParams, els));
    },
    [setEdges, elements, connections, edges]
  );

  const onPaneClick = (e) => {
    setContextMenu(null);
    if (editMode) {
      if (edgeSelected && connectionSelected) {
        setEdgeSelected({ ...edgeSelected });
        setConnectionSelected({ ...connectionSelected });
        //cancelUpdateConnection();
      }
      if (elementSelected) setElementSelected({ ...elementSelected });
    } else {
      setConnectionSelected(null);
      setOpenConnection(null);
      setEdgeSelected(null);
      setNodes(handleFormaterNodes(elements, editMode, showLabel, true, false));
      setEdges(
        handleFormaterEdges(connections, editMode, showLabel, true, false)
      );
      setElementsConnected(null);
    }
  };

  const onNodeDragStop = (event, node) => {
    if (elementSelected && elementSelected?.id === node?.id) {
      if (
        elementSelected?.elementConfig?.config?.coordenadas?.long !==
          node?.position?.x ||
        elementSelected?.elementConfig?.config?.coordenadas?.lat !==
          node?.position?.y
      ) {
        setElementSelected((ele) => ({
          ...ele,
          elementConfig: {
            config: {
              ...ele?.elementConfig?.config,
              coordenadas: { long: node?.position?.x, lat: node?.position?.y },
            },
          },
        }));
        setCheckChanges(true);
      }
    }
    //handleUpdateElement(element, node);
  };
  const onEdgeMouseLeave = useCallback(
    (event, edge) => {
      const select = edges?.find(({ id }) => edge?.id === id);
      if (select?.data?.positionHandlers?.find(({ active }) => active === 1)) {
        setEdgeSelected((edg) => ({
          ...edg,
          data: {
            ...edg.data,
            positionHandlers: select.data?.positionHandlers?.map((handler) => ({
              ...handler,
              active: -1,
            })),
          },
        }));
      }
    },
    [edges, edgeSelected]
  );
  useEffect(() => {
    if (checkChanges || !editMode) return;

    if (
      (Array.isArray(previousConnection) && previousConnection.length == 0) ||
      !previousConnection ||
      previousConnection != edgeSelected?.id
    )
      return;

    if (
      JSON.stringify(previousHandlers) ==
      JSON.stringify(edgeSelected?.data?.positionHandlers)
    )
      return;

    setCheckChanges(true);
  }, [
    edges,
    edgeSelected?.data?.positionHandlers,
    edgeSelected?.data?.positionHandlers?.length,
  ]);

  const onEdgesDelete = (edge) => {
    if (editMode) handleDeleteLink(edge[0]?.id);
  };
  const onNodesDelete = (nodes) => {
    if (editMode) handleDeleteElement(nodes[0]?.id);
  };
  const isValidConnection = ({ source, target }) => {
    if (source === target) {
      return false;
    }
    const isDuplicate = edges.some(
      (edge) =>
        (edge.source === source && edge.target === target) ||
        (edge.source === target && edge.target === source)
    );
    if (isDuplicate) {
      return false;
    }
    return true;
  };
  return (
    <>
      <Box sx={{ overflow: "visible", zIndex: 1203 }}>
        <TitleBar title={title} id={mapId} setSnackbar={setSnackbar} />
      </Box>
      <Stack sx={styles.stackContainer}>
        <ReactFlowProvider>
          <ToolBarMaps
            setEasyMapsConfig={setEasyMapsConfig}
            easyMapsConfig={JSON.parse(JSON.stringify(easyMapsConfig))}
            editMode={editMode}
            proceedUpdateMode={proceedUpdateMode}
            setEditMode={setEditMode}
            fullScreem={fullScreem}
            setFullScreem={setFullScreem}
            showLabel={showLabel}
            setShowLabel={setShowLabel}
            lockMode={lockMode}
            setLockMode={setLockMode}
            setShowLabelEnlace={setShowLabelEnlace}
            showLabelEnlace={showLabelEnlace}
            setOpenAlertModal={setOpenAlertModal}
            checkChanges={checkChanges}
            tipo={1}
          >
            <ToolBarMaps.InputZoomTop />
          </ToolBarMaps>
          {!editMode && <DescriptionBar elementSelected={!!elementSelected} />}
          <FilterElements
            iconsElement={iconsElements}
            elementsFilter={elementsFilter}
            setElementsFilter={setElementsFilter}
          />
          <ZoomFlow
            id={id}
            focusNodeId={focusNodeId}
            setFocusNodeId={setFocusNodeId}
          />
          <ReactFlow
            ref={ref}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            snapToGrid={false}
            onEdgeUpdate={onEdgeUpdate}
            onConnect={onConnect}
            onPaneClick={onPaneClick}
            onContextMenu={onContextMenu}
            onNodeContextMenu={onNodeContextMenu}
            elementsSelectable={!lockMode}
            nodesConnectable={!lockMode && editMode}
            nodesDraggable={elementSelected != null && !lockMode && editMode}
            attributionPosition="bottom-left"
            fitView
            connectionMode={ConnectionMode.Loose}
            onNodeClick={onNodeClick}
            //onNodeDrag={onNodeDrag}
            onNodeDragStop={onNodeDragStop}
            onEdgeMouseLeave={onEdgeMouseLeave}
            onEdgeContextMenu={(e) => {
              setEdgeSelected(
                edges?.find((edge) => edge.id === edgeSelected?.id)
              );
            }}
            onEdgeDoubleClick={(e) => {
              setEdgeSelected(
                edges?.find((edge) => edge.id === edgeSelected?.id)
              );
            }}
            onEdgeClick={onEdgeClick}
            onEdgesDelete={onEdgesDelete}
            onNodesDelete={onNodesDelete}
            minZoom={0.5}
            maxZoom={3}
            proOptions={{ hideAttribution: true }}
          >
            <Background />
            {contextMenu && editMode && !connectionSelected && (
              <NodeMenu
                open={contextMenu !== null}
                onClose={onPaneClick}
                handleDelete={handleDeleteElement}
                handleCreate={handleCreateElement}
                {...contextMenu}
              />
            )}
          </ReactFlow>{" "}
          {editMode && (
            <MenuMaps
              setElementSelected={setElementSelected}
              iconsElement={iconsElements.filter((icon) =>
                icon?.nome?.startsWith("_")
              )}
              setConnectionSelected={setConnectionSelected}
              checkChanges={checkChanges}
              setOpenAlertModal={setOpenAlertModal}
              saveUpdateCallback={saveUpdateCallback}
            />
          )}
          <RightBarEasyMaps
            setIconsElements={setIconsElements}
            connectionsFull={connections}
            setFocusNodeId={setFocusNodeId}
            open={openRightBar}
            setOpen={setOpenRightBar}
            elements={elements}
            connections={connections}
            setElement={setElementSelected}
            setConnections={setElementsConnected}
            iconsElement={iconsElements}
            element={!editMode && elementSelected?.id ? elementSelected : null}
            fullScreem={fullScreem}
            openConnection={openConnection}
            setOpenConnection={setOpenConnection}
            link={`/easyMaps/${
              elementSelected?.mapRef?.tipo === 0 ? "geo" : "top"
            }/${elementSelected?.mapRefId ?? ""}`}
          />
          <CreationSliderBarEasyMaps
            ref={creationRef}
            setOpenAlertModal={setOpenAlertModal}
            checkChanges={checkChanges}
            setCheckChanges={setCheckChanges}
            iconsElement={iconsElements}
            setIconsElement={setIconsElements}
            mapId={id}
            socket={socketCliente}
            element={editMode ? elementSelected : null}
            setElementSelected={setElementSelected}
            connections={connections}
            elements={elements}
            setElements={setElements}
            produtos={produtos}
            setConnections={setConnections}
            api={api}
            setSnackbar={setSnackbar}
            maps={mapas}
            addMapInMaps={addMapInMaps}
            tipoMapa={1}
            coordenadasFlow={coordenadasFlow}
            setFocusNodeId={setFocusNodeId}
            updateDataElementSocket={updateDataElementSocket}
            deleteElementSocket={(id) =>
              setOpenAlertModal({
                open: true,
                id: id,
                severity: "error",
                tipo: "element",
              })
            }
          />
          <EnlaceSideBarEasyMaps
            ref={enlaceBarRef}
            setOpenAlertModal={setOpenAlertModal}
            setEdgeSelected={setEdgeSelected}
            connection={editMode ? connectionSelected : null}
            setConnection={setConnectionSelected}
            edgeSelected={edgeSelected}
            setSnackbar={setSnackbar}
            api={api}
            produtos={produtos}
            iconsElement={iconsElements}
            elements={elements}
            saveConnection={
              connectionSelected?.id ? handleUpdateLink : handleCreateLink
            }
            handleCancel={handleCancelLink}
            //handleDelete={handleDeleteLink}
            changeConnection={changeConnection}
            fullScreem={fullScreem}
            tipoMapa={1}
            checkChanges={checkChanges}
            setCheckChanges={setCheckChanges}
            loadingButton={loadinButtonEnlace}
            handleDelete={(id) =>
              setOpenAlertModal({
                open: true,
                id: id,
                severity: "error",
                tipo: "enlace",
              })
            }
          />
        </ReactFlowProvider>{" "}
        <ContextMenu
          contextMenu={contextMenuFlow}
          setContextMenu={setContextMenuFlow}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        <AlertModal
          openModal={openAlertModal?.open ?? false}
          setOpenModal={(data) => setOpenAlertModal({ open: data })}
          buttonText={openAlertModal?.buttonText}
          buttonCancelText={openAlertModal?.buttonCancelText}
          confirmar={false}
          handleButton={() => {
            setCheckChanges(false);
            setOpenAlertModal({ open: false });
            if (openAlertModal.callback) {
              openAlertModal.callback(openAlertModal?.id);
              return;
            } else {
              setElementSelected(null);
              setConnectionSelected(null);
              setEdgeSelected(null);
            }
            if (openAlertModal?.severity == "error") {
              if (openAlertModal.tipo == "enlace")
                handleDeleteLink(openAlertModal?.id);
              if (openAlertModal.tipo == "element")
                handleDeleteElement(openAlertModal?.id);
              return;
            }
          }}
          handleCancel={() => {
            if (openAlertModal?.callbackCancel) {
              openAlertModal.callbackCancel();
              return;
            }
            setOpenAlertModal({ open: false });
          }}
          severity={openAlertModal?.severity}
          singleButton={false}
          textContent={openAlertModal?.text}
        />
      </Stack>{" "}
    </>
  );
};
export default ReactFlowMapas;
