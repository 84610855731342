import React from "react";
import { Autocomplete, TextField, ListItem, Checkbox } from "@mui/material";
import { FixedSizeList } from "react-window";

// Componente customizado para virtualização
export const VirtualizedListbox = React.forwardRef(function VirtualizedListbox(
  props,
  ref
) {
  const { children, ...other } = props;

  const ITEM_HEIGHT = 60; // Altura de cada item
  const items = React.Children.toArray(children);
  const itemCount = items.length;

  return (
    <div ref={ref} {...other}>
      <FixedSizeList
        height={items.length < 5 ? ITEM_HEIGHT * itemCount : 300} // Máximo de 8 itens visíveis
        itemSize={ITEM_HEIGHT}
        itemCount={itemCount}
        overscanCount={5} // Renderiza 5 itens extras fora da viewport
        width="100%"
      >
        {({ index, style }) => (
          <div style={style}>{items[index]}</div> // Use <div> aqui para garantir foco e eventos corretos
        )}
      </FixedSizeList>
    </div>
  );
});
