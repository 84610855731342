import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import React from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { converteUnidades } from "../utils";
// const COLOR_CHART = "#5B2E87";
const COLOR_CHART = "#585858";
export default function NumberChart({ data, unit }) {
  const formatDate = (tickItem) => {
    const result = `${new Date(tickItem).toLocaleDateString()} ${new Date(
      tickItem
    ).toLocaleTimeString()}`;
    return result;
  };

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    // let data = payload.value.split("T")[0];
    let time = dayjs(payload.value).format("DD/MM/YYYY");
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={10}
          y={10}
          //dy={50}
          fontSize={12}
          textAnchor="end"
          fill="black"
          transform="rotate(-20)"
        >
          {/* {payload.value.replace("T", " ").replace(".000Z", "")} */}
          {time}
        </text>
      </g>
    );
  };
  const CustomizedYaxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          //dy={50}
          fontSize={13}
          textAnchor="end"
          fill="black"
          //transform="rotate(-60)"
        >
          {/*payload.value + " " + unit*/}
          {converteUnidades(payload.value, unit)}
        </text>
      </g>
    );
  };

  const intervalo = parseInt(data.length / 16);
  return (
    <Box>
      <AreaChart
        data={data}
        width={790}
        height={200}
        margin={{ top: 20, right: 20, left: 15, bottom: 10 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#5B2E87" stopOpacity={1} />
            <stop offset="95%" stopColor="#5B2E87" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          interval={intervalo}
          dataKey="datetime"
          type="category"
          //interval={50}
          height={50}
          tick={<CustomizedAxisTick />}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          domain={[0, Math.max(...data.map((a) => a.value))]}
          tick={<CustomizedYaxisTick />}
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#5B2E87"
          fill="url(#colorUv)"
          //dot={false}
          //label={<CustomizedLabel />}
          isAnimationActive={false}
        />
      </AreaChart>
    </Box>
  );
}
