import { Paper, Typography, Stack, Box, Link, useTheme } from "@mui/material";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { MapOutlined, ReportProblem } from "@mui/icons-material";

export default function CardMaps(props) {
  const {
    variant = "selected",
    link,
    title,
    alertas,
    tipo,
    onClick = () => {},
  } = props;

  const theme = useTheme();

  const SvgMenu = ({ selected, sx }) => {
    return (
      <svg
        style={sx}
        width="190"
        height="40"
        viewBox="0 0 163 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.50119H127.163L163 22.5012H157.798L126.007 2.5477H0V0.50119Z"
          fill={theme.palette.color.iconsDisable}
        />{" "}
        {/* Primeiro path */}
        <path
          d="M146.799 21.8481L120 4.50119H124.937L157.377 24.9094L163 24.8261L160.904 29.5012H154.556L146.799 24.9094V21.8481Z"
          fill={theme.palette.primary.main}
        />{" "}
        {/* Segundo path */}
      </svg>
    );
  };

  const styles = {
    iconLinked: {
      fontSize: "35px",
      color: theme.palette.color.icons,
    },
    subTitle: {
      fontSize: "12px",
      textDecoration: "none",
      textTransform: "none",
      color: theme.palette.text,
    },
    icon: {
      pointerEvents: "none",
      marginBottom: "-32px",
    },
    title: {
      mt: "3px",
      fontSize: "1.1em",
      maxWidth: "155px",
      overflow: "hidden",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    line: {
      backgroundColor: "primary.main",
      height: "60px",
      width: "3px",
    },

    cardlinked: {
      width: "100%",
      backgroundColor: theme.palette.background.paperSelectedMaps,
      boxShadow: "0px 0px 5px  #0000001a",
      position: "relative",
      padding: "0px 15px",
      display: "flex",
      direction: "row",
      height: "90px",
      alignItems: "center",
      gap: 2,
      "&:hover": {
        backgroundColor: theme.palette.background.hover,
      },
    },
    cardType: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      right: 10,
      top: 0,
      backgroundColor: "primary.main",
      height: "16px",
      width: "50px",
    },
  };

  return (
    <Paper elevation={0} sx={[styles.cardlinked]} onClick={onClick}>
      <MapOutlined sx={[styles.iconLinked]} />

      <Stack direction={"column"}>
        <Stack direction="row" alignItems={"center"} gap={0.5}>
          <ReportProblem
            sx={{
              fontSize: "13px",
              color: "primary.main",
            }}
          />
          <Typography sx={styles.subTitle}>{alertas + " Alertas"}</Typography>
        </Stack>
        <SvgMenu selected={true} sx={styles.icon} />

        <Typography sx={styles.title}>{title}</Typography>
      </Stack>

      <Box sx={styles.cardType}>
        <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "white" }}>
          {tipo == 0 ? "GEO" : "TOP"}
        </Typography>
      </Box>
    </Paper>
  );
}
