import { CloudOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  Paper,
  Stack,
  Typography,
  createSvgIcon,
  useTheme,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { Handle, NodeToolbar, Position } from "reactflow";
import IconTransform from "../iconsElement";

export default function Element({
  id,
  type,
  severity,
  sourcePosition = Position.Left,
  targetPosition = Position.Right,
  data,
  selected,
}) {
  //  if (selected) console.log(selected);

  const theme = useTheme();
  const [colors, setColors] = useState({
    background: theme.palette.color?.zabbixSeverty?.warningTranslucid,
    border: theme.palette.color?.zabbixSeverty?.warning,
  });
  const [alertCount, setAlertCount] = useState(0);
  const [levitate, setLevitate] = useState(5);
  const [animationAlert, setAnimationAlert] = useState(true);
  //const [size, setSize] = useState(data.size ? data.size : 10);
  const {
    hosts,
    entangled,
    showLabel,
    tipoMapa,
    alerts,
    title,
    editMode,
    img,
    easyMapsConfig,
  } = data;
  const SvgElement = ({ stroke, fill, style, levitate, children, ...rest }) => {
    return (
      <Stack
        sx={{
          mb: tipoMapa == 0 ? "-10px" : null,
          maxWidth: 0.5 * data?.size * 10 + "px",
          maxHeight: data?.size * data?.size + "px",
        }}
        direction={"column"}
        spacing={levitate + "px"}
        alignItems={"center"}
        justifyContent={"center"}
        style={style}
        {...rest}
      >
        <Stack
          //marginBottom={levitate}
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 61 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_3415_75702)">
              <path
                d="M60.5 32.3279C60.5 40.5441 55.6822 49.0818 49.3749 57.0649C44.3952 63.3676 38.5424 69.259 33.5186 74.316C32.3224 75.5201 31.1732 76.6769 30.0939 77.7807C29.1845 76.8332 28.2168 75.8377 27.2072 74.7991C22.32 69.7717 16.4508 63.734 11.4501 57.2306C5.27016 49.1939 0.5 40.5477 0.5 32.3279C0.5 15.7661 13.5677 0.5 30.0906 0.5C46.6302 0.5 60.5 15.7842 60.5 32.3279Z"
                fill={fill}
                stroke={stroke}
                strokeWidth={"2px"}
              />
            </g>
          </svg>
          <Box sx={{ position: "absolute", mt: "-15px" }}>{children}</Box>
        </Stack>
        <Stack></Stack>
      </Stack>
    );
  };
  useEffect(() => {
    if (!easyMapsConfig) return;
    setAnimationAlert(easyMapsConfig?.animacao?.alertas != false);
  }, [easyMapsConfig]);

  useEffect(() => {
    let severity = "0";
    let count = 0;
    alerts?.map((alert) => {
      count++;
      if (alert.severity > severity) {
        severity = alert.severity;
      }
    });

    if (severity === "5") {
      setColors({
        background: theme.palette.color?.zabbixSeverty?.disaster,
        border: theme.palette.color?.zabbixSeverty?.disaster,
      });
    } else if (severity === "4") {
      setColors({
        background: theme.palette.color.zabbixSeverty.highTranslucid,
        border: theme.palette.color.zabbixSeverty.high,
      });
    } else if (severity === "3") {
      setColors({
        background: theme.palette.color.zabbixSeverty.averageTranslucid,
        border: theme.palette.color.zabbixSeverty.average,
      });
    } else if (severity === "2") {
      setColors({
        background: theme.palette.color.zabbixSeverty.warningTranslucid,
        border: theme.palette.color.zabbixSeverty.warning,
      });
    } else {
      setColors({
        background: theme.palette.color.zabbixSeverty.default,
        border: theme.palette.color.zabbixSeverty.selectedTranslucid,
      });
    }

    setAlertCount(count);
  }, [data, selected, theme]);

  const styles = {
    paperContainer: {
      minWidth: "50px",
      minHeight: "50px",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.background,
      border: "1px solid " + colors.border,
    },
    bow: {
      position: "absolute",
      border: "1px solid " + colors.border,
      left: "-10%",
      top: "-10%",
      borderRadius: "50%",
      width: "119.5%",
      height: "119.5%",
    },
    title: {
      opacity: entangled ? 1 : 0.7,
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      marginTop: -levitate + "px",
      userSelect: "none",
      cursor: "pointer",
    },
    svgElement: {
      width: "50px",
      height: "70px",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      minWidth: "20px",
      minHeight: "20px",
      alignItems: "center",
      justifyContent: "center",
    },
    iconGeo: {
      width: "50px",
      height: "50px",
    },
    handle: {
      marginTop: "1px",
      background: editMode ? "#b1b1b7" : "transparent",
      border: editMode
        ? "1px solid " + theme.palette.background.default
        : "transparent",
    },
    handleGeo: {
      background: "transparent",
      border: "transparent",
    },
    badge: {
      "& .MuiBadge-badge": {
        backgroundColor: colors.border,
        margin: 1,
        boxShadow: "0px 3px 3px #00000052",
        marginTop: +8 - levitate + "px",
      },
    },
    pulse: {
      position: "absolute",
      zIndex: -1,
      left: "50%",
      top: "50%",
      transform: "translateX(-50%) translateY(-50%)",
      width: "40px",
      height: "40px",
      "&:before": {
        content: '""',
        position: "relative",
        display: "block",
        width: "300%",
        height: "300%",
        boxSizing: "border-box",
        marginLeft: "-100%",
        marginTop: "-100%",
        borderRadius: "50%",
        backgroundColor: colors.border,
        animation: "pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite",
      },

      "@keyframes pulse-ring": {
        "0%": {
          transform: "scale(.33)",
        },
        "80%, 100%": {
          opacity: 0,
        },
      },
      "@keyframes pulse-dot": {
        "0%": {
          transform: "scale(.8)",
        },
        "50%": {
          transform: "scale(1)",
        },
        "100%": {
          transform: "scale(.8)",
        },
      },
    },
  };
  return (
    <div
      style={{
        opacity: entangled ? 1 : 0.7,
      }}
    >
      {showLabel && (
        <NodeToolbar
          isVisible={true}
          position={Position.Top}
          style={styles.title}
        >
          <Typography variant="textFieldTitle">
            {title?.length > 30 ? title.substring(0, 30) + "..." : title}
          </Typography>
          {/* <Typography variant="small">{data?.title}</Typography> */}
        </NodeToolbar>
      )}
      {tipoMapa === 0 ? (
        <Badge badgeContent={alertCount} sx={styles.badge}>
          {alerts?.length > 0 && <Box sx={styles.teste} />}
          <SvgElement
            onMouseEnter={() => {
              setLevitate(12);
            }}
            onMouseLeave={() => {
              setLevitate(5);
            }}
            stroke={colors.border}
            fill={colors.background}
            style={styles.svgElement}
            levitate={levitate}
          >
            <IconTransform
              img={img}
              size={26 - (10 - data?.size) * 3 + "px"}
              //color="#fff"
            />
          </SvgElement>
        </Badge>
      ) : (
        <Badge badgeContent={alertCount} sx={styles.badge}>
          {alerts?.length > 0 && animationAlert && <Box sx={styles.pulse} />}
          {selected && <Box sx={styles.bow} />}
          <Paper variant="outlined" type="p" sx={styles.paperContainer}>
            <IconTransform
              img={img}
              size="26px" //color="#fff"
            />
          </Paper>
        </Badge>
      )}
      {tipoMapa === 0 ? (
        <>
          <Handle
            type="source"
            position={Position.Bottom}
            id="a"
            isConnectable={true}
            style={styles.handleGeo}
          />
          <Handle
            type="target"
            position={Position.Bottom}
            id="a"
            isConnectable={true}
            style={styles.handleGeo}
          />
        </>
      ) : (
        <>
          <Handle
            type="source"
            position={Position.Bottom}
            id="bottom"
            isConnectable={editMode}
            style={styles.handle}
          />
          <Handle
            type="source"
            position={Position.Top}
            id="top"
            isConnectable={editMode}
            style={styles.handle}
          />
          <Handle
            type="source"
            position={Position.Left}
            id="left"
            isConnectable={editMode}
            style={styles.handle}
          />
          <Handle
            type="source"
            position={Position.Right}
            id="right"
            isConnectable={editMode}
            style={styles.handle}
          />
        </>
      )}
    </div>
  );
}
