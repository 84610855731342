import {
  Add,
  AddAPhotoOutlined,
  AddPhotoAlternateOutlined,
  Delete,
  Refresh,
  Upload,
} from "@mui/icons-material";
import {
  Stack,
  TextField,
  IconButton,
  Autocomplete,
  Fade,
  LinearProgress,
  Tooltip,
  FormControlLabel,
  Typography,
  Checkbox,
  Button,
  Chip,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import api from "../../../../services/api";
import { FormGroup } from "@mui/material";

const styles = {
  autcomplete: { mt: 1, width: "500px" },
  tooltipSpan: { mt: 10 },
};
const SelecaoItens = (props) => {
  const {
    key,
    index,
    hosts,
    loadingHosts,
    selectedHosts,
    setSelectedHosts = () => {},
    setSnackbar = () => {},
  } = props;
  const [items, setItems] = useState([]);
  const getHostsLabel = (host) => host?.nome || "";
  const getInterfacesLabel = (item) => item?.nome || "";
  const [loadingItems, setLoadinItems] = useState(false);
  const [disableItems, setDisableItems] = useState(
    selectedHosts[index]?.nome ? false : true
  );

  async function handleApiGetKeyItems(hostId) {
    try {
      setLoadinItems(true);

      const response = await api.post("/easyReport/Items/find", {
        hostId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Interfaces",
          severity: "error",
        });
      } else {
        setItems(response.data);
      }
    } catch (error) {
      console.error(error);

      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadinItems(false);
      setDisableItems(false);
    }
  }
  const removeSelectedHosts = (index) => {
    const newSelectedHost = [...selectedHosts];
    newSelectedHost.splice(index, 1);

    setSelectedHosts(newSelectedHost);
  };
  const addSelectedHosts = () => {
    if (selectedHosts.length) {
      setSelectedHosts([
        ...selectedHosts,
        {
          id: "",
          nome: "",
          itens: [],
          randomKey: Math.random(),
        },
      ]);
    }
  };
  const theme = useTheme();
  const handleSelectHostsChange = (index, event) => {
    const { name, value } = event.target;
    let newSelectedHost = [...selectedHosts];
    newSelectedHost[index][name] = value;
    setSelectedHosts(newSelectedHost);
  };
  return (
    <Fragment key={key}>
      <Typography color={"primary"}>Seleção o hosts e seus itens</Typography>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Autocomplete
          name={"host"}
          disabled={loadingHosts}
          size="small"
          sx={styles.autcomplete}
          value={
            {
              id: selectedHosts[index]?.id,
              nome: selectedHosts[index]?.nome,
            } || null
          }
          onChange={(event, newValue) => {
            let eventAutocompleteId = {
              target: { name: "id", value: newValue?.id || "" },
            };
            let eventAutocompleteName = {
              target: { name: "nome", value: newValue?.nome || "" },
            };

            let eventAutocompleteItems = {
              target: { name: "itens", value: [] },
            };
            handleSelectHostsChange(index, eventAutocompleteId);
            handleSelectHostsChange(index, eventAutocompleteName);
            handleSelectHostsChange(index, eventAutocompleteItems);
            //setHost(newValue);
            if (newValue?.id) {
              handleApiGetKeyItems(newValue?.id);
            }
          }}
          options={hosts}
          getOptionLabel={getHostsLabel}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <>
              <TextField {...params} label="Selecione um Host" />
              <Fade in={loadingHosts}>
                <LinearProgress />
              </Fade>
            </>
          )}
        />
        {index === 0 ? (
          <span>
            <Tooltip title="Adicionar mais um host">
              <IconButton onClick={addSelectedHosts}>
                <Add color="primary" />
              </IconButton>
            </Tooltip>
          </span>
        ) : (
          <Tooltip title="Remove este host">
            <IconButton
              onClick={() => {
                removeSelectedHosts(index);
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Autocomplete
          disableCloseOnSelect
          name={"itens"}
          disabled={disableItems}
          sx={styles.autcomplete}
          value={selectedHosts[index]?.itens || []}
          onChange={(event, newValue) => {
            let eventAutocompleteItems = {
              target: { name: "itens", value: newValue },
            };

            handleSelectHostsChange(index, eventAutocompleteItems);
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Tooltip
                  placement="top-start"
                  title={option.name}
                  arrow
                  disableInteractive
                  slotProps={{
                    arrow: {
                      sx: { color: theme.palette.background.card },
                    },
                    tooltip: {
                      sx: {
                        color: theme.palette.text.primary,
                        bgcolor: theme.palette.background.card,
                        maxWidth: "none",
                        fontSize: 14,
                        boxShadow: 1,
                      },
                    },
                  }}
                >
                  <Chip
                    key={key}
                    label={option.name}
                    size="small"
                    {...tagProps}
                    // disabled={items.includes(option)}
                  />
                </Tooltip>
              );
            })
          }
          multiple
          defaultValue={[]}
          options={items}
          getOptionLabel={getInterfacesLabel}
          size="small"
          renderInput={(params) => (
            <>
              <TextField {...params} label="Selecione os Itens" />
              <Fade in={loadingItems}>
                <LinearProgress />
              </Fade>
            </>
          )}
        />
        <Tooltip title="Atualize a lista de interfaces" sx={styles.tooltip}>
          <span>
            <IconButton
              disabled={disableItems}
              onClick={() => {
                handleApiGetKeyItems(selectedHosts[index].id);
              }}
            >
              <Refresh
                sx={{
                  color: disableItems ? "chip.arquive" : "chip.waitingReview",
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Fragment>
  );
};
export default function ConfiguraRelatorioMon(props) {
  const {
    setSnackbar,
    selectedHosts,
    setSelectedHosts = () => {},
    setOcultarGraficos = () => {},
    ocultarGraficos,
  } = props;

  const [hosts, setHosts] = useState([]);
  const [loadingHosts, setLoadingHosts] = useState(false);

  useEffect(() => {
    handleApiGetHosts();
  }, []);

  /*useEffect(() => {
    if (selectedHosts[index]?.nome) {
      setHost(selectedHosts[index].nome);
    }
  }, [selectedHosts]);*/

  const handleOcultarGraficos = (event) => {
    const { name, value, checked } = event.target;
    const newFeature = checked;
    setOcultarGraficos(newFeature);
  };

  async function handleApiGetHosts() {
    {
      try {
        setLoadingHosts(true);

        const response = await api.get("/easyReport/HostName/find");

        if (response.data.status === "Error") {
          console.error(response.data);
          setSnackbar({
            children: "Error: Não foi possível buscar hosts",
            severity: "error",
          });
        } else {
          setHosts(response.data);
        }
      } catch (error) {
        setSnackbar({
          children: "Error: Falha ao buscar hosts no conectar com o servidor",
          severity: "error",
        });

        console.error(error);
      } finally {
        setLoadingHosts(false);
      }
    }
  }

  return (
    <Stack direction={"column"} gap={"8px"}>
      <Stack>
        <Typography color={"primary"}>Ocultar gráficos</Typography>
        <FormGroup sx={{ width: "fit-content" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={ocultarGraficos}
                size="small"
                name="chart"
                onChange={(event) => {
                  handleOcultarGraficos(event);
                }}
              />
            }
            label="Ocultar gráficos"
          />
        </FormGroup>
      </Stack>
      {selectedHosts.map((value, index) => (
        <SelecaoItens
          key={`${value.randomKey}-${index}`}
          index={index}
          hosts={hosts}
          loadingHosts={loadingHosts}
          selectedHosts={selectedHosts}
          setSelectedHosts={setSelectedHosts}
          setSnackbar={setSnackbar}
        />
      ))}
    </Stack>
  );
}
