import {
  Paper,
  Stack,
  Tabs,
  Tab,
  Box,
  Divider,
  Typography,
  useTheme,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";

import React, { useEffect } from "react";
import { useState } from "react";

//icons
import MenuSort from "./menuSort";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import SearchIcon from "@mui/icons-material/Search";

import VirtualizedAlerts from "./cardAlerts/virtualizacao";
import VirtualizedONU from "./cardONU/vitualizacao";
import VirtualizedCTOS from "./cardCTOs/virtualizacao";
import {
  FilterList,
  FilterListOff,
  Home,
  SignalCellularConnectedNoInternet1BarOutlined,
  SignalCellularNodataOutlined,
  SwapVertOutlined,
} from "@mui/icons-material";
import IconTransform from "../iconsElement";

export default function SliderBarMapsRp(props) {
  const {
    map,
    iconsElements,
    setFocusNodeId = () => {},
    mapRef,
    elements,
    onusInvalidas = [],
  } = props;

  const [alertasTotais, setAlertasTotais] = useState([]);
  const [CTOs, setCTOs] = useState([]);
  const [ONUS, setONUs] = useState([]);
  const [filterHosts, setFilterHosts] = useState("");
  const [filterAlerts, setFilterAlerts] = useState(-1);
  const [filterMaps, setFilterMaps] = useState("");
  const [valueTab, setValueTab] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sort, setSort] = useState(0);
  const [directionSort, setDirectionSort] = useState(0);
  const [sortOptions, setSortOptions] = useState([
    ["Alertas", "ONU's"],
    [],
    ["Nome", "Potência", "Pendentes"],
  ]);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const styles = {
    textAdorment: {
      ml: "-5px",
      fontSize: "12px",
      fontWeight: 800,
      color: theme.palette.color.bgIcon,
    },
    data: {
      overflow: "auto",
      height: "100%",
      width: "100%",
    },
    menuItem: {
      display: "flex",
      alignItems: "center",
    },
    iconItem: { mr: 1 },
    containerData: {
      overflow: "hidden",
      height: "100%",
    },
    paper: {
      borderRadius: "0px",
      height: "calc(100vh - 65px)",
      position: "sticky",
      top: "65px",
      maxWidth: "310px!important",
      minWidth: "310px!important",
      padding: "0px 10px",
      backgroundColor: theme.palette.background.sideBar,
      borderRight: "1px solid" + theme.palette.color.appBarBorder,
    },
    cardNoMaps: {
      background: theme.palette.background.paperSelectedMaps,
      height: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: "0.875rem",
      color: theme.palette.color.text,
      //textTransform: "none",
    },
    tabs: {
      "& button": {
        color: theme.palette.text.disabled,
        textTransform: "none",
        minHeight: "20px",
        Height: "100%",
        borderRadius: "5px",
        paddingX: "10px",
      },
      "& .MuiTab-icon": { marginRight: "2px" },

      "& button.Mui-selected": {
        backgroundColor: theme.palette.background.tab,
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
        border: "none",
      },
    },
    card: {
      borderRadius: "5px",
      backgroundColor: theme.palette.background.paperSelectedMaps,
      boxShadow: "0px 0px 3px  #0000001a",
      border: "none",
      "&::before": {
        display: "none",
      },
    },
    textCount: {
      fontSize: "0.8rem",
      textAlign: "start",
      p: "5px 10px",
      mt: "-5px",
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.background.paperSelectedMaps,
      mb: "10px",
    },
  };
  useEffect(() => {
    setAlertasTotais([
      ...elements
        ?.flatMap((ele) => ele?.elementsClients?.flatMap((onu) => onu.alert))
        .filter((a) => a != null),
      ...elements?.flatMap((a) => a?.alerts).filter((a) => a != null),
    ]);
    setCTOs(elements);
    setONUs([...elements?.flatMap((e) => e.elementsClients), ...onusInvalidas]);
  }, [elements, onusInvalidas]);

  const CardNoMaps = () => {
    return (
      <Paper elevation={0} sx={styles.cardNoMaps}>
        <Typography
          sx={styles.text}
          color={theme.palette.mode == "dark" ? "#ffffff6e" : ""}
        >
          Não há equipamentos
        </Typography>
      </Paper>
    );
  };
  const CountList = ({ list }) => {
    return (
      <Typography sx={styles.textCount}>
        {(list.length || 0) + " itens encontrados"}
      </Typography>
    );
  };

  function search(id) {
    setFocusNodeId(id);
    if (mapRef?.current) {
      mapRef.current.focusZoomExt(id);
    }
  }
  const sortCtos = () => {
    if (valueTab != 1) return [];
    let data = CTOs?.filter((cto) =>
      cto?.titulo?.toLowerCase()?.includes(filterMaps.toLowerCase())
    );
    if (sort == 0) {
      return data.sort((a, b) => {
        const alertasA = a.elementsClients.flatMap(
          (client) => client.alert
        )?.length;
        const alertasB = b.elementsClients.flatMap(
          (client) => client.alert
        )?.length;

        return directionSort == 0 ? alertasA - alertasB : alertasB - alertasA; // Ordenação crescente
      });
    } else {
      return data.sort((a, b) => {
        const onusA = a?.elementsClients.length || -41;
        const onusB = b?.elementsClients.length || -41;

        return directionSort == 0 ? onusA - onusB : onusB - onusA; // Ordenação crescente
      });
    }
  };
  const sortONUS = () => {
    if (valueTab != 3) return [];
    let data = ONUS?.filter(
      (onu) =>
        onu?.titulo?.toLowerCase()?.startsWith(filterHosts?.toLowerCase()) ||
        onu?.data?.contrato?.value
          ?.toString()
          ?.toLowerCase()
          ?.startsWith(filterHosts.toLowerCase()) ||
        onu?.data?.usuario?.value
          ?.toLowerCase()
          ?.startsWith(filterHosts.toLowerCase())
    );
    if (sort == 0) {
      return data.sort((a, b) => {
        const tituloA = a.titulo?.toLowerCase();
        const tituloB = b.titulo?.toLowerCase();

        if (tituloA < tituloB) return directionSort == 0 ? -1 : 1;
        if (tituloA > tituloB) return directionSort == 0 ? 1 : -1;
        return 0;
      });
    } else if (sort == 1) {
      return data.sort((a, b) => {
        const potenciaA = a.data.potencia.rx || -41;
        const potenciaB = b.data.potencia.rx || -41;

        return directionSort == 0
          ? potenciaA - potenciaB
          : potenciaB - potenciaA; // Ordenação crescente
      });
    } else {
      return data.sort((a, b) => {
        const getPriority = (item) => {
          if (item.isValid && item.hasCto) return 0;
          if (!item.isValid && item.hasCto) return 1;
          if (item.isValid && !item.hasCto) return 2;
          return 3;
        };
        const aPriority = getPriority(a);
        const bPriority = getPriority(b);

        return directionSort == 0
          ? aPriority - bPriority
          : bPriority - aPriority;
      });
    }
  };

  return (
    <>
      <Stack sx={styles.paper}>
        <Stack direction="column" gap={2} height="calc(100vh - 65px)">
          <Stack sx={[styles.card, { p: 0.5, mt: 2, height: "50px" }]}>
            <Tabs
              variant="fullWidth"
              value={valueTab}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              sx={styles.tabs}
            >
              <Tab
                icon={
                  valueTab == 1 ? (
                    <Box sx={{ height: "24px" }}>
                      <IconTransform
                        img={
                          iconsElements?.find((icon) => icon.nome == "_cto")
                            ?.img
                        }
                        color={valueTab == 1 && theme.palette.primary.main}
                        size="24px"
                      />
                    </Box>
                  ) : null
                }
                label="CTOs"
                value={1}
                sx={styles.text}
                iconPosition={"start"}
              />
              <Tab
                sx={styles.text}
                icon={valueTab == 2 ? <BoltOutlinedIcon /> : null}
                label="Alertas"
                value={2}
                iconPosition={"start"}
              />
              <Tab
                sx={styles.text}
                icon={valueTab == 3 ? <Home /> : null}
                label="ONUs"
                value={3}
                iconPosition={"start"}
              />
            </Tabs>
          </Stack>

          <Stack gap={2} sx={styles.containerData}>
            {valueTab == 1 && (
              <>
                <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Buscar"
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    value={filterMaps}
                    onChange={(event) => setFilterMaps(event.target.value)}
                    sx={{ width: "100%" }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <SearchIcon
                            sx={{ color: theme.palette.color.iconsDisable }}
                          />
                        ),
                      },
                    }}
                  />
                  <IconButton
                    size="small"
                    sx={{ width: "40px", height: "40px" }}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <SwapVertOutlined />
                  </IconButton>
                </Stack>

                <VirtualizedCTOS
                  lista={sortCtos()}
                  style={styles.data}
                  search={search}
                  iconsElements={iconsElements}
                />
                <CountList list={sortCtos()} />
              </>
            )}

            {valueTab == 2 && (
              <>
                <TextField
                  select
                  size="small"
                  value={filterAlerts}
                  onChange={(event) => setFilterAlerts(event.target.value)}
                  placeholder="Severidade"
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography sx={styles.textAdorment}>
                            ALERT
                          </Typography>
                        </InputAdornment>
                      ),
                    },
                  }}
                >
                  <MenuItem value={-1}>
                    <FilterListOff sx={{ mr: 1 }} />
                    Todos
                  </MenuItem>
                  <Divider />
                  <MenuItem value={0}>
                    <SignalCellularNodataOutlined sx={{ mr: 1 }} />
                    Offline
                  </MenuItem>
                  <MenuItem value={1}>
                    <SignalCellularConnectedNoInternet1BarOutlined
                      sx={{ mr: 1 }}
                    />
                    Baixa Potência
                  </MenuItem>
                </TextField>
                <VirtualizedAlerts
                  alertasTotais={alertasTotais.filter(
                    (a) => a.severity == filterAlerts || filterAlerts == -1
                  )}
                  style={styles.data}
                  search={search}
                />
                <CountList
                  list={alertasTotais.filter(
                    (a) => a.severity == filterAlerts || filterAlerts == -1
                  )}
                />
              </>
            )}

            {valueTab == 3 && (
              <>
                <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Buscar"
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    value={filterHosts}
                    onChange={(event) => setFilterHosts(event.target.value)}
                    sx={{ width: "100%" }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <SearchIcon
                            sx={{ color: theme.palette.color.iconsDisable }}
                          />
                        ),
                      },
                    }}
                  />
                  <IconButton
                    size="small"
                    sx={{ width: "40px", height: "40px" }}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <SwapVertOutlined />
                  </IconButton>
                </Stack>

                <VirtualizedONU
                  lista={sortONUS()}
                  style={styles.data}
                  search={search}
                  iconsElements={iconsElements}
                />

                <CountList list={sortONUS()} />
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      <MenuSort
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        options={sortOptions[valueTab - 1]}
        direction={directionSort}
        setDirection={setDirectionSort}
        sort={sort}
        setSort={setSort}
      />
    </>
  );
}
