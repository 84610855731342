import { Add, Delete, Refresh } from "@mui/icons-material";
import {
  Stack,
  TextField,
  IconButton,
  Autocomplete,
  Fade,
  LinearProgress,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const styles = {
  autcomplete: { mt: 1, width: "380px" },
  tooltipSpan: { mt: 10 },
};

export default function ClientesFlow(props) {
  const {
    clientes,
    index,
    selectedClientes,
    setSelectedClientes,
    loadingClientes,
  } = props;
  const getClientesLabel = (cliente) => cliente?.client || "";

  const addSelectedClientes = () => {
    if (selectedClientes.length) {
      setSelectedClientes([
        ...selectedClientes,
        {
          client: "",
          types: [],
          randomKey: Math.random(),
        },
      ]);
    }
  };

  const handleSelectClientesChange = (index, event) => {
    const { name, value } = event.target;

    let newSelectedCliente = [...selectedClientes];
    newSelectedCliente[index][name] = value;
    setSelectedClientes(newSelectedCliente);
  };

  const removeSelectedClientes = (index) => {
    const newSelectedClientes = [...selectedClientes];
    newSelectedClientes.splice(index, 1);

    setSelectedClientes(newSelectedClientes);
  };

  return (
    <Stack direction={"column"}>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Autocomplete
          name={"cliente"}
          size="small"
          sx={styles.autcomplete}
          value={{
            id: selectedClientes[index]?.id || "",
            client: selectedClientes[index]?.client || "",
            types: selectedClientes[index]?.types || "",
          }}
          onChange={(event, newValue, reason) => {
            let eventAutocompleteId = {
              target: { name: "id", value: newValue?.id || "" },
            };
            let eventAutocompleteCliente = {
              target: { name: "client", value: newValue?.client || "" },
            };

            let eventAutocompleteTypes = {
              target: { name: "types", value: newValue?.types || [] },
            };

            let eventAutocompleteTypesValue = {
              target: {
                name: "typesValue",
                value:
                  (Array.isArray(newValue?.types) &&
                    newValue.types.map(() => {
                      return true;
                    })) ||
                  [],
              },
            };

            handleSelectClientesChange(index, eventAutocompleteId);
            handleSelectClientesChange(index, eventAutocompleteCliente);
            handleSelectClientesChange(index, eventAutocompleteTypes);
            handleSelectClientesChange(index, eventAutocompleteTypesValue);
          }}
          options={clientes}
          getOptionLabel={getClientesLabel}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <>
              <TextField {...params} label="Selecione um Cliente" />
              <Fade in={loadingClientes}>
                <LinearProgress />
              </Fade>
            </>
          )}
        />

        {Array.isArray(selectedClientes[index]?.types) &&
        selectedClientes[index]?.types?.length > 0 ? (
          selectedClientes[index]?.types?.map((value, indexMap) => {
            return (
              <FormControlLabel
                value={value}
                label={value}
                labelPlacement="start"
                control={
                  <Checkbox
                    key={indexMap}
                    name={value}
                    checked={selectedClientes[index]?.typesValue?.[indexMap]}
                    onChange={(event) => {
                      let newArray = selectedClientes[index]?.typesValue;
                      newArray[indexMap] = event.target.checked;
                      const eventCheck = {
                        target: {
                          name: "typesValue",
                          value: newArray,
                        },
                      };

                      handleSelectClientesChange(index, eventCheck);
                    }}
                  />
                }
              />
            );
          })
        ) : (
          <></>
        )}
        {index === 0 ? (
          <span>
            <Tooltip title="Adiciona mais um cliente">
              <IconButton onClick={addSelectedClientes}>
                <Add color="primary" />
              </IconButton>
            </Tooltip>
          </span>
        ) : (
          <Tooltip title="Remove este cliente">
            <IconButton
              onClick={() => {
                removeSelectedClientes(index);
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
