import L from "leaflet";
import {
  Box,
  Stack,
  useTheme,
  Paper,
  Modal,
  Typography,
  alpha,
  getContrastRatio,
} from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import styled, { css, keyframes } from "styled-components";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useOutletContext } from "react-router-dom";

const pulseRingAnimation = keyframes`
0% {
  transform: scale(0.33);
}
80%, 100% {
  opacity: 0;
}
`;

const Pulse = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: -1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  &:before {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    margin-top: -40px;
    margin-left: -40px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    animation: ${pulseRingAnimation} 2s infinite;
  }
`;

const AnimatedStackDrop = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  opacity: ${({ $disable }) => ($disable ? 0.4 : 1)};
  pointer-events: ${({ $disable }) => ($disable ? css`none` : css`initial`)};
`;

const Paragraph = styled.p`
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  max-width: 150px; /* Garante que o texto não ultrapasse 200px */
  display: ${({ show }) => (show ? "block" : "none")};
  font-size: 13px;
  color: ${({ color }) => color || "white"};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export const ClusterIcon = ({
  size = 10,
  disable = false,
  children,
  mapRef,
  setElementSelected = () => {},
  easyMapsConfig,
  noCluster,
}) => {
  const { config } = useOutletContext();
  const theme = useTheme();
  const [id, setId] = useState(Math.random());

  useEffect(() => {
    setId(
      JSON.stringify(easyMapsConfig) +
        children.length +
        noCluster +
        JSON.stringify(config) +
        disable
    );
  }, [disable, easyMapsConfig, children.length, noCluster, config]);

  const severityColors = {
    5: {
      background: theme.palette.color?.zabbixSeverty?.disaster,
      border: theme.palette.color?.zabbixSeverty?.disaster,
    },
    4: {
      background: theme.palette.color.zabbixSeverty.highTranslucid,
      border: theme.palette.color.zabbixSeverty.high,
    },
    3: {
      background: theme.palette.color.zabbixSeverty.averageTranslucid,
      border: theme.palette.color.zabbixSeverty.average,
    },
    2: {
      background: theme.palette.color.zabbixSeverty.warningTranslucid,
      border: theme.palette.color.zabbixSeverty.warning,
    },
    1: {
      background: theme.palette.color.zabbixSeverty.default,
      border: theme.palette.color.zabbixSeverty.selectedTranslucid,
    },
    0: {
      background: "#ffffff36",
      border: "#ffffff8f",
    },
  };
  const styles = {
    icon: {
      color: theme.palette.text,
      fontSize: "14px",
      fontWeight: 600,
      position: "absolute",
      left: 0,
      right: 0,
      top: -size * 0.7,
      bottom: 0,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  function colorEnlace(power) {
    if (power == null || power == 0 || power === Infinity || !config) {
      return theme.palette.enlaces.offline.main;
    }
    const result = config?.escalas?.ftth
      .filter((scale) => parseFloat(scale?.label) >= power)
      .pop();

    if (!result) return theme.palette.enlaces.offline.main;
    return result?.cor;
  }

  const CustomIcon = ({ cluster }) => {
    let colors;
    //if (easyMapsConfig?.estilizacao?.cto == 1) {
    let ctos = cluster
      .getAllChildMarkers()
      ?.map((marker) => marker?.options?.rx)
      .flat();

    const media =
      ctos.reduce((soma, valor) => soma + (!!valor ? valor : 0), 0) /
      ctos.length;

    if (!media) {
      colors = severityColors[1];
    } else {
      colors = {
        background: alpha(colorEnlace(media), 0.6),
        border: colorEnlace(media),
      };
    }
    // } else {
    //   let ctos = cluster
    //     .getAllChildMarkers()
    //     ?.map((marker) => marker?.options)
    //     .flat();

    //   const resp = ctos.reduce(
    //     (soma, cto) => {
    //       return [
    //         soma[0] || cto?.isDown, // Atualiza o primeiro valor se cto.isDown for verdadeiro
    //         soma[1] || cto?.isLow || cto?.someDown, // Atualiza o segundo valor se cto.isLow for verdadeiro
    //       ];
    //     },
    //     [false, false] // Valor inicial
    //   );

    //   if (resp[0]) colors = severityColors[4];
    //   else if (resp[1]) colors = severityColors[2];
    //   else colors = severityColors[1];
    // }

    //regras de alertas

    return (
      <>
        <AnimatedStackDrop $size={size} $disable={disable} $selected={false}>
          {/* <Paragraph show={showLabel} color={theme.palette.color.text}>
              CTOs
            </Paragraph> */}

          <svg
            stroke={colors?.border}
            viewBox="0 0 61 80"
            fill={colors?.background}
            strokeWidth={"3px"}
            width="100%"
            height="100%"
          >
            <g clipPath="url(#clip0_3415_75702)">
              <path
                d="M60.5 32.3279C60.5 40.5441 55.6822 49.0818 49.3749 57.0649C44.3952 63.3676 38.5424 69.259 33.5186 74.316C32.3224 75.5201 31.1732 76.6769 30.0939 77.7807C29.1845 76.8332 28.2168 75.8377 27.2072 74.7991C22.32 69.7717 16.4508 63.734 11.4501 57.2306C5.27016 49.1939 0.5 40.5477 0.5 32.3279C0.5 15.7661 13.5677 0.5 30.0906 0.5C46.6302 0.5 60.5 15.7842 60.5 32.3279Z"
                filter="url(#filter0_d)"
              />
            </g>
          </svg>

          <Typography
            style={{
              ...styles.icon,
              fontSize: cluster.getChildCount() > 99 && "13px",
              color:
                getContrastRatio(colors.background, "#ff0000ff") < 1.12
                  ? "#fff"
                  : null,
            }}
          >
            {cluster.getChildCount() > 99 ? "+99" : cluster.getChildCount()}
          </Typography>
        </AnimatedStackDrop>
      </>
    );
  };

  const iconPerson = (cluster) => {
    return L.divIcon({
      html: ReactDOMServer.renderToString(<CustomIcon cluster={cluster} />),
      iconSize: [0.5 * size * 6, 0.6 * size * 6],
      iconAnchor: [0.25 * size * 6, 0.61 * size * 6],
    });
  };

  return (
    <MarkerClusterGroup
      onClick={() => setElementSelected(null)}
      key={id}
      chunkInterval={200}
      chunkedLoading
      disableClusteringAtZoom={18}
      spiderfyOnMaxZoom={true}
      removeOutsideVisibleBounds={true}
      maxClusterRadius={60}
      animate={true}
      iconCreateFunction={iconPerson}
      polygonOptions={{ stroke: false, color: "transparent" }}
    >
      {children}
    </MarkerClusterGroup>
  );
};
