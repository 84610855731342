import {
  CheckCircleOutline,
  ErrorOutline,
  ErrorOutlineOutlined,
  SignalCellular3BarOutlined,
  SignalCellular4BarOutlined,
  SignalCellular2BarOutlined,
  SignalCellular1BarOutlined,
  SignalCellularNodataOutlined,
  WarningAmberOutlined,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellularConnectedNoInternet4Bar,
} from "@mui/icons-material";
import { useOutletContext } from "react-router-dom";
import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

export default function StatusPot({ potenciaRx }) {
  const theme = useTheme();
  const { config } = useOutletContext();
  const [qualidade, setQualidade] = useState(1);
  const [color, setColor] = useState(0);
  const [nivel, setnivel] = useState({ icon: null, text: null });

  const styles = {
    icons: {
      color: color,
      fontSize: "20px",
    },
    info: {
      color: theme.palette.color.textDisabled,
      width: "100%",
    },
    statusCard: {
      backgroundColor: "transparent",
      paddingX: "10px",
      paddingY: "0px",
      display: "flex",
      gap: 2,
      height: "60px",
    },
    line: { backgroundColor: "#FFFFFF", width: "3px", height: "100%" },
  };

  const niveis = [
    {
      icon: <SignalCellular4BarOutlined sx={styles.icons} />,
      text: "A recepção  do sinal está em condições excelentes",
    },
    {
      icon: <SignalCellular3BarOutlined sx={styles.icons} />,
      text: "A recepção do sinal está em boas condições",
    },
    {
      icon: <SignalCellular2BarOutlined sx={styles.icons} />,
      text: "A recepção do sinal está razoável, apresentando qualidade moderada",
    },
    {
      icon: <SignalCellular1BarOutlined sx={styles.icons} />,
      text: "A recepção do sinal está ruim e instável",
    },
  ];

  function colorEnlace(power) {
    if (power == null || power == 0 || power === Infinity || !config) {
      setColor(theme.palette.enlaces.offline.main);
      return;
    }
    const escalas = config?.escalas?.ftth || [];
    const filtered = escalas
      .map((scale, index) => ({ ...scale, index })) // Adiciona a posição original
      .filter((scale) => parseFloat(scale?.label) >= power);
    const result = filtered.pop(); // Obtém o último elemento que atende à condição

    if (potenciaRx == -40) {
      setnivel({
        icon: <SignalCellularConnectedNoInternet4Bar sx={styles.icons} />,
        text: "Equipamento offline, sem conexão ativa!",
      });
      setColor(escalas[escalas.length - 1].cor);
    } else if (result == undefined) {
      setnivel({
        icon: <SignalCellularConnectedNoInternet4Bar sx={styles.icons} />,
        text: "Equipamento com valor de potência fora da escala configurada!",
      });
      setColor(escalas[0].cor);
    } else {
      selectIcon(potenciaRx);
      setColor(result?.cor);
    }
  }

  function selectIcon(pot) {
    if (pot > -16) {
      setnivel(niveis[0]);
    } else if (pot > -23) {
      setnivel(niveis[1]);
    } else if (pot > -33) {
      setnivel(niveis[2]);
    } else if (pot > -40) {
      setnivel(niveis[3]);
    }
  }
  useEffect(() => {
    colorEnlace(potenciaRx);
  }, [potenciaRx, color]);

  return (
    <Paper sx={styles.statusCard} elevation={0} variant="miniCards">
      <Stack direction={"column"} width={"100%"} gap={0.5}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="default">{"Status da potência"}</Typography>
          {nivel?.icon}
        </Stack>
        {
          <Typography sx={styles.info} variant="small">
            {nivel?.text}
          </Typography>
        }
      </Stack>
    </Paper>
  );
}
