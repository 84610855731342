import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { maskCurrency } from "../../utils";
import { Fragment, useEffect, useState } from "react";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import ClientesFlow from "./clientesFlow";
import api from "../../../../services/api";

export default function ConfiguraRelatorioFlow({
  fna,
  setFna,
  ggc,
  setGgc,
  ixPpt,
  setIxPpt,
  transIp,
  setTransIp,
  ocaNetflix,
  setOcaNetflix,
  agruparPorNome,
  setAgruparPorNome,
  selectedClientes,
  setSelectedClientes,
  filterInterfaceFlow,
  setFilterInterfaceFlow,
  ocultarGraficos,
  setOcultarGraficos,
  tarifacaoExcedente,
  setTarifacaoExcedente,
  setSnackbar,
}) {
  const [filterStatusFlow, setFilterStatusFlow] = useState(false);
  const [interfacesFlow, setInterfacesFlow] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [clientes, setClientes] = useState([]);

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    handleApiFindClientsFlowDB();
    handleApiFindInterfacesFlow();
  }, []);

  useEffect(() => {
    setFilterInterfaceFlow([]);
  }, [filterStatusFlow]);

  async function handleApiFindClientsFlowDB() {
    try {
      setLoadingClientes(true);

      const response = await api.get("/easyReport/findClientsFlowDB");

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setClientes(response.data);
      }
    } catch (error) {
      console.error(error);

      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingClientes(false);
      // setDisableClientes(false);
    }
  }

  async function handleApiFindInterfacesFlow(params) {
    try {
      const response = await api.get("/easyReport/findInterfacesFlowDB");

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Interfaces do Flow",
          severity: "error",
        });
      } else {
        setInterfacesFlow(response.data);
      }
    } catch (error) {
      console.error(error);

      setSnackbar({
        children: "Error: Não foi possível Buscar Interfaces do Flow",
        severity: "error",
      });
    }
  }

  const handleTarifacaoExedente = (event) => {
    const { name, value, checked } = event.target;
    const newTarifacao = { ...tarifacaoExcedente };
    newTarifacao[name] =
      name === "status"
        ? checked
        : name === "limite"
        ? value
        : maskCurrency(value);
    setTarifacaoExcedente(newTarifacao);
  };

  const handleAgruparPorNome = (event) => {
    const { name, value, checked } = event.target;
    const newAgruparPorNome = { ...agruparPorNome };
    newAgruparPorNome[name] =
      name === "status" ? checked : name === "cliente" ? value : null;

    setAgruparPorNome(newAgruparPorNome);
  };

  const handleOcultarGraficos = (event) => {
    const { name, value, checked } = event.target;
    const newFeature = checked;
    setOcultarGraficos(newFeature);
  };

  return (
    <Stack direction={"column"} gap={2}>
      {" "}
      <Stack>
        <Typography color={"primary"}>Ocultar gráficos</Typography>
        <FormGroup sx={{ width: "fit-content" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={ocultarGraficos}
                size="small"
                name="chart"
                onChange={(event) => {
                  handleOcultarGraficos(event);
                }}
              />
            }
            label="Ocultar gráficos"
          />
        </FormGroup>
      </Stack>
      <Stack>
        {" "}
        <Typography color={"primary"}>Adiciona valor por Mbps</Typography>
        <FormGroup sx={{ width: "fit-content" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={tarifacaoExcedente.status}
                size="small"
                name="status"
                onChange={(event) => {
                  handleTarifacaoExedente(event);
                }}
              />
            }
            label="Cobrança por excedente"
          />
        </FormGroup>
      </Stack>
      {!tarifacaoExcedente.status && (
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <TextField
            label="FNA"
            value={fna}
            onChange={(event) => {
              setFna(maskCurrency(event.target.value));
            }}
            autoComplete="off"
            size="small"
            sx={{ width: "200px" }}
          />
          <TextField
            label="GGC"
            value={ggc}
            onChange={(event) => {
              setGgc(maskCurrency(event.target.value));
            }}
            autoComplete="off"
            size="small"
            sx={{ width: "200px" }}
          />
          <TextField
            value={ixPpt}
            label="IX/PTT"
            size="small"
            onChange={(event) => {
              setIxPpt(maskCurrency(event.target.value));
            }}
            autoComplete="off"
            sx={{ width: "200px" }}
          />
          <TextField
            value={transIp}
            label="TRANS/IP"
            size="small"
            onChange={(event) => {
              setTransIp(maskCurrency(event.target.value));
            }}
            autoComplete="off"
            sx={{ width: "200px" }}
          />
          <TextField
            value={ocaNetflix}
            label="OCA|NETFLIX"
            size="small"
            onChange={(event) => {
              setOcaNetflix(maskCurrency(event.target.value));
            }}
            autoComplete="off"
            sx={{ width: "200px" }}
          />
        </Stack>
      )}
      {tarifacaoExcedente.status && (
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <TextField
            label="valor por Mbps"
            value={tarifacaoExcedente.valor}
            name="valor"
            onChange={(event) => {
              handleTarifacaoExedente(event);
            }}
            autoComplete="off"
            size="small"
            sx={{ width: "200px" }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              },
            }}
          />
          <TextField
            label="limite de tráfego"
            value={tarifacaoExcedente.limite}
            helperText="Em Mbps"
            name="limite"
            type="number"
            onChange={(event) => {
              handleTarifacaoExedente(event);
            }}
            autoComplete="off"
            size="small"
            sx={{ width: "200px" }}
            //InputLabelProps={{ shrink: true }}
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
          <TextField
            label="valor do excedente"
            value={tarifacaoExcedente.valorExcedente}
            name="valorExcedente"
            onChange={(event) => {
              handleTarifacaoExedente(event);
            }}
            autoComplete="off"
            size="small"
            sx={{ width: "200px" }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              },
            }}
          />
        </Stack>
      )}
      <Typography color={"primary"}>Selecione o(s) cliente(s)</Typography>
      <FormGroup sx={{ width: "fit-content" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agruparPorNome.status}
              size="small"
              name="status"
              onChange={(event) => {
                handleAgruparPorNome(event);
              }}
            />
          }
          label="Agrupar por nome"
        />
      </FormGroup>
      {agruparPorNome.status && (
        <TextField
          sx={{ width: "380px" }}
          name="cliente"
          size="small"
          autoComplete="off"
          label={"Digite o nome desejado"}
          onChange={(event) => {
            handleAgruparPorNome(event);
          }}
          helperText={
            <Typography
              sx={{
                fontSize: "0.75rem",
                opacity: 0.7,
                marginLeft: -1.3,
                fontWeight: 600,
              }}
            >
              O relatório irá agrupar a informação de todos os clientes que
              contiverem o texto acima.
            </Typography>
          }
        />
      )}
      {!agruparPorNome.status &&
        selectedClientes.map((value, index) => (
          <Fragment key={`${value.randomKey}-${index}`}>
            <ClientesFlow
              index={index}
              clientes={clientes}
              selectedClientes={selectedClientes}
              setSelectedClientes={setSelectedClientes}
              loadingClientes={loadingClientes}
            />
          </Fragment>
        ))}
      <Typography color={"primary"}>Selecione o(s) filtros</Typography>
      <FormGroup sx={{ width: "fit-content" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filterStatusFlow}
              size="small"
              name="status"
              onChange={(event) => {
                setFilterStatusFlow(event.target.checked);
              }}
            />
          }
          label="Adicionar filtro de interface"
        />
      </FormGroup>
      {filterStatusFlow && (
        <Autocomplete
          multiple
          id="interfaces-flow"
          options={interfacesFlow}
          value={filterInterfaceFlow}
          onChange={(event, newValue) => {
            setFilterInterfaceFlow(newValue);
          }}
          disableCloseOnSelect
          getOptionLabel={(option) => option.interface}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.interface}
            </li>
          )}
          size="small"
          renderInput={(params) => (
            <TextField
              sx={{ width: "380px" }}
              {...params}
              label="Interfaces"
              helperText={
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    opacity: 0.7,
                    marginLeft: -1.3,
                    fontWeight: 600,
                  }}
                >
                  Este filtro irá remover as interfaces selecionadas.
                </Typography>
              }
            />
          )}
        />
      )}
    </Stack>
  );
}
