import { useTheme } from "@emotion/react";
import {
  Beenhere,
  InfoOutlined,
  NotificationImportant,
  NotificationImportantOutlined,
  Report,
  ReportProblem,
} from "@mui/icons-material";
import { alpha, darken, lighten, Stack, Typography } from "@mui/material";

export default function Alerts({
  title,
  message = "Alert!",
  severity = "info",
  sx = {},
}) {
  const theme = useTheme();
  let color =
    severity == "info"
      ? theme.palette.color.alerts.info
      : severity == "error"
      ? theme.palette.color.alerts.error
      : severity == "warning"
      ? theme.palette.color.alerts.warning
      : severity == "success"
      ? theme.palette.color.alerts.success
      : theme.palette.background.paper;

  const styles = {
    alert: {
      maxWidth: "100%",
      background: alpha(color, 0.2),
      color:
        theme.palette.mode != "dark" ? darken(color, 0.2) : lighten(color, 0.2),
      padding: "10px",
      borderRadius: "3px",
    },
  };
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{ ...styles.alert, ...sx }}
      spacing={1.5}
    >
      {severity == "info" && <InfoOutlined />}
      {severity == "warning" && <ReportProblem />}
      {severity == "success" && <Beenhere />}
      {severity == "error" && <Report />}
      <Stack>
        <Typography sx={{ fontWeight: 500, fontSize: "0.9rem" }}>
          {title}
        </Typography>{" "}
        <Typography sx={{ fontWeight: 400, fontSize: "0.85rem" }}>
          {message}
        </Typography>
      </Stack>
    </Stack>
  );
}
