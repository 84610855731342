import {
  Paper,
  Stack,
  TextField,
  Divider,
  Typography,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Autocomplete,
  Tooltip,
  Chip,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { useEffect, useState } from "react";
import { ReactComponent as InfoIcon } from "../../../../assets/easyMaps/iconsSvg/info.svg";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { VirtualizedListbox } from "../../vitualizationAutoComplete";

export default function CardConnection(
  {
    idElement,
    hostsConfig,
    typeElement,
    productId,
    id,
    index,
    connection,
    elements,
    itens,
    updateConexao,
    expanded,
    setExpanded,
    removeConexao,
    coordinatesLat,
    coordinatesLon,
  },
  key
) {
  const [host, setHost] = useState(null);
  const [element, setElement] = useState(null);
  const [itemConnection, setItemConnection] = useState([]);
  const [otherItensConnection, setOtherItensConnection] = useState([]);
  const [capacidade, setCapacidade] = useState(0);
  const [interation, setInteration] = useState(false);
  const theme = useTheme();
  const styles = {
    stackOption: {
      flexDirection: "row",
      width: "100%",
      p: "5px",
      alignItems: "center",
    },
    textoLimitado: {
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limita o texto a 2 linhas
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    line: {
      position: "absolute",
      backgroundColor: "primary.main",
      height: "100%", //typeElement == "Host" ? "150px" : "230px",
      minWidth: "3px",
    },
    text: {
      fontSize: "14px",
    },
    textField: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: theme.palette.background.paperLinkedMaps,
        boxShadow: "none",
        width: "245px",
        "&:hover": {
          backgroundColor: theme.palette.background.paperLinkedMaps,
        },
      },
    },
  };

  useEffect(() => {
    if (elements?.length == 0) return;
    let elemnt = elements?.find((ele) => ele.id == connection?.destinationId);
    let capacidade = connection?.config?.capacidade;

    let hostId = connection?.config?.hostOriginId;
    if (!hostId) {
      //verifica o host pelos itens da conexão
      if (Array.isArray(connection.item) && connection.item.length > 0) {
        hostId = connection?.item[0]?.hostOriginId;
      } else {
        let elementoReference = elements.find(
          (a) => a.id == connection.referenceId
        );
        if (elementoReference?.tipo == 0) {
          hostId = elementoReference?.elementConfig?.config?.hosts[0]?.hostid;
        }
      }
    }
    let host = hostsConfig?.find((host) => host.hostid == hostId);

    if (capacidade) setCapacidade(capacidade);
    if (elemnt) setElement(elemnt);
    if (host) setHost(host);
  }, [elements, hostsConfig]);

  useEffect(() => {
    if (itens?.length == 0) {
      setItemConnection([]);
      setOtherItensConnection([]);
    } else {
      let itensfull = [
        ...new Map(itens.map((item) => [item.itemid, item])).values(),
      ];
      let item = itensfull?.filter((item) =>
        connection?.item?.some(
          (con) =>
            con.itemId === item.itemid &&
            (con?.key_?.toLowerCase().includes("inoctets") ||
              con?.key_?.toLowerCase().includes("outoctets"))
        )
      );

      let outrosItem = itensfull?.filter((item) =>
        connection?.item?.some(
          (con) =>
            con.itemId === item.itemid &&
            !con?.key_?.toLowerCase().includes("inoctets") &&
            !con?.key_?.toLowerCase().includes("outoctets")
        )
      );

      if (item) setItemConnection(item);
      if (outrosItem) setOtherItensConnection(outrosItem);
    }
  }, [itens]);

  useEffect(() => {
    if (!interation) return;
    setInteration(false);

    const difLat =
      parseFloat(coordinatesLat) -
      element?.elementConfig?.config?.coordenadas?.lat;
    const difLon =
      parseFloat(coordinatesLon) -
      element?.elementConfig?.config?.coordenadas?.long;
    const sourceHandle =
      Math.abs(difLat) >= Math.abs(difLon)
        ? difLat >= 0
          ? "bottom"
          : "top"
        : difLon >= 0
        ? "left"
        : "right";
    const targetHandle =
      Math.abs(difLat) >= Math.abs(difLon)
        ? difLat >= 0
          ? "top"
          : "bottom"
        : difLon >= 0
        ? "right"
        : "left";
    updateConexao(index, {
      id: id,
      destinationId: element?.id || null,
      item: [
        ...itemConnection?.map((item) => ({
          itemId: item?.itemid || null,
          itemName: item?.name || null,
          hostOriginId: item?.hostid || null,
          acessoProdutoId: productId || null,
          key_: item?.key_ || null,
        })),
        ...otherItensConnection.map((item) => ({
          itemId: item?.itemid || null,
          itemName: item?.name || null,
          hostOriginId: item?.hostid || null,
          acessoProdutoId: productId || null,
          key_: item?.key_ || null,
        })),
      ],
      config: {
        type: "reta",
        sourceHandle,
        targetHandle,
        capacidade,
        animation: connection?.config?.animation,
        hostOriginId: host?.hostid || null, // redundância para caso aconexão não tenha itens
      },
      positionHandlers: connection?.positionHandlers,
    });
  }, [interation]);

  function handleChange(selected) {
    setExpanded(selected == expanded ? null : index);
  }
  const onDelete = (event) => {
    event.stopPropagation();
    removeConexao(id);
    setHost(null);
    setElement(null);
    setItemConnection([]);
    setOtherItensConnection([]);
  };

  const isOptionEqualToValue = (option, value) => {
    return option?.host === value?.host;
  };
  const filterRemoveTraffic = () => {
    let filteredItems = itens.filter(
      (a) =>
        !a?.key_.toLowerCase()?.includes("inoctets") &&
        !a?.key_.toLowerCase()?.includes("outoctets")
    );

    filteredItems.sort((a, b) => a?.name?.localeCompare(b.name));
    return [
      ...new Map(filteredItems.map((item) => [item?.itemid, item])).values(),
    ];
  };

  const filterTraffic = () => {
    let filteredItems = itemConnection?.some(
      (a) =>
        a?.key_.toLowerCase()?.includes("inoctets") ||
        a?.key_.toLowerCase()?.includes("outoctets")
    )
      ? []
      : itens.filter(
          (a) =>
            a?.key_.toLowerCase()?.includes("inoctets") ||
            a?.key_.toLowerCase()?.includes("outoctets")
        );
    let uniqueItems = [];
    let trafficSet = new Set();
    filteredItems.forEach((item) => {
      if (item?.name?.includes("Tráfego")) {
        let baseName = item?.name
          .replace(" de Entrada", "")
          .replace(" de Saída", "");
        if (!trafficSet.has(baseName)) {
          trafficSet.add(baseName);
          uniqueItems.push(item);
        }
      } else {
        uniqueItems.push(item);
      }
    });

    // Ordenar os itens pelo nome
    uniqueItems.sort((a, b) => a.name.localeCompare(b.name));

    return uniqueItems;
  };

  const getDisplayName = (option) => {
    if (option?.name.includes("Tráfego")) {
      return option?.name.replace("de Entrada", "").replace("de Saída", "");
    }
    return option?.name;
  };
  return (
    <Accordion
      key={key}
      disableGutters
      expanded={expanded == index && itens?.length != 0}
      onChange={() => handleChange(index)}
      sx={{
        padding: "0px 5px",
        borderRadius: "5px",
        backgroundColor: theme.palette.background.paperSelectedMaps,
        boxShadow: "0px 0px 5px  #0000001a",
        border: "none",
        "&::before": {
          display: "none",
        },
        "&:hover": {
          backgroundColor: theme.palette.background.hover,
        },
      }}
    >
      <AccordionSummary
        expandIcon={itens?.length != 0 && <ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          padding: "0px",
          flexDirection: "row-reverse",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          ml={1}
        >
          <Stack width={"100%"} direction="row" alignItems="center" gap={1}>
            {itens?.length != 0 && (
              <>
                {" "}
                <LinkOutlinedIcon
                  sx={{
                    color: theme.palette.mode == "dark" ? "#565656" : "#9A9A9A",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "0.9em",
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {element?.titulo || "Nova Conexão "}
                </Typography>{" "}
              </>
            )}

            {itens?.length == 0 && (
              <>
                <InfoIcon
                  style={{
                    fill: theme.palette.color.icons,
                    height: "16px",
                    width: "16px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "0.8em",
                    maxWidth: "210px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Itens não encontrados no Elemento"}
                </Typography>
              </>
            )}
          </Stack>
          <IconButton aria-label="delete" onClick={onDelete}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" gap={2} sx={{ position: "relative" }}>
          <Box sx={styles.line} ml="-5px" />
          <Stack direction="column" width="100%" ml="10px" gap={1}>
            {typeElement != "Host" && (
              <>
                <Typography sx={styles.text}>
                  Selecione o host de origem
                </Typography>
                <Autocomplete
                  getOptionLabel={(option) => option?.host}
                  isOptionEqualToValue={isOptionEqualToValue}
                  sx={{
                    width: "100%",
                    backgroundColor: theme.palette.background.paperLinked,
                  }}
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  color="primary"
                  options={hostsConfig || []}
                  value={host || null}
                  onChange={(event, newValue) => {
                    setItemConnection([]);
                    setOtherItensConnection([]);
                    setHost(newValue);
                    setInteration(true);
                  }}
                  ListboxComponent={VirtualizedListbox}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{ padding: "0px" }}
                      key={option.itemid}
                    >
                      <Tooltip title={option.titulo} placement="left-start">
                        <Stack sx={styles.stackOption}>
                          <Checkbox checked={selected} />
                          <span style={styles.textoLimitado}>
                            {option.titulo}
                          </span>
                        </Stack>{" "}
                      </Tooltip>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="primary"
                      variant="outlined"
                      label="Host"
                      sx={styles.textField}
                    />
                  )}
                />
              </>
            )}

            <Typography sx={styles.text}>
              Selecione o elemento de destino
            </Typography>
            <Autocomplete
              getOptionLabel={(option) => option?.titulo}
              sx={{
                width: "100%",
              }}
              disablePortal
              id="combo-box-demo"
              size="small"
              color="primary"
              options={elements?.filter((ele) => ele.id !== idElement)}
              value={element || null}
              onChange={(event, newValue) => {
                setElement(newValue);
                setInteration(true);
              }}
              ListboxComponent={VirtualizedListbox}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ padding: "0px" }} key={option.itemid}>
                  <Tooltip title={option.titulo} placement="left-start">
                    <Stack sx={styles.stackOption}>
                      <Checkbox checked={selected} />
                      <span style={styles.textoLimitado}>{option.titulo}</span>
                    </Stack>{" "}
                  </Tooltip>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={styles.textField}
                  color="primary"
                  variant="outlined"
                  placeholder="Elemento"
                />
              )}
            />
            <Typography sx={styles.text}>
              Selecione o itens da conexão
            </Typography>

            <Autocomplete
              multiple
              disableCloseOnSelect
              key="itensTrafficAutocomplete"
              sx={{ width: "245px" }}
              disabled={typeElement != "Host" && host == null}
              //disablePortal
              id="itensTraffic"
              size="small"
              color="primary"
              options={
                typeElement == "Host"
                  ? filterTraffic()
                  : filterTraffic().filter(
                      (item) => item.hostid === host?.hostid
                    )
              }
              ListboxComponent={VirtualizedListbox}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ padding: "0px" }} key={option.itemid}>
                  <Tooltip
                    title={getDisplayName(option)}
                    placement="left-start"
                  >
                    <Stack sx={styles.stackOption}>
                      <Checkbox checked={selected} />
                      <span style={styles.textoLimitado}>
                        {getDisplayName(option)}
                      </span>
                    </Stack>{" "}
                  </Tooltip>
                </li>
              )}
              value={[...itemConnection]}
              onChange={(event, newValue) => {
                setItemConnection(newValue);
                setInteration(true);
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Tooltip title={option.name} key={index + "toolTraffic"}>
                    <Chip label={option.name} {...getTagProps({ index })} />
                  </Tooltip>
                ));
              }}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) =>
                option.itemid === value.itemid
              }
              renderInput={(params) => (
                <TextField
                  multiple
                  key="itensTrafficText"
                  {...params}
                  sx={styles.textField}
                  color="primary"
                  placeholder="Item de tráfego"
                  helperText={
                    typeElement == "Host"
                      ? itens?.length == 0 && "Esse elemento não contém itens"
                      : itens?.filter((item) => item.hostid === host?.hostid)
                          ?.length == 0 &&
                        host != null &&
                        "O host selecionado não contém itens"
                  }
                />
              )}
            />

            <Autocomplete
              multiple
              disableCloseOnSelect
              key="itensAutocomplete"
              sx={{ width: "245px" }}
              disabled={typeElement != "Host" && host == null}
              //disablePortal
              id="itensHost"
              size="small"
              color="primary"
              options={
                typeElement == "Host"
                  ? filterRemoveTraffic()
                  : filterRemoveTraffic().filter(
                      (item) => item.hostid === host?.hostid
                    )
              }
              value={[...otherItensConnection]}
              onChange={(event, newValue) => {
                setOtherItensConnection(newValue);
                setInteration(true);
              }}
              renderTags={(value, getTagProps) => {
                return value?.map((option, index) => (
                  <Tooltip
                    title={option.name}
                    key={option.itemid + "toolItens"}
                  >
                    <Chip label={option.name} {...getTagProps({ index })} />
                  </Tooltip>
                ));
              }}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) =>
                option.itemid === value.itemid
              }
              ListboxComponent={VirtualizedListbox}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ padding: "0px" }} key={option.itemid}>
                  <Tooltip title={option.name} placement="left-start">
                    <Stack sx={styles.stackOption}>
                      <Checkbox checked={selected} />
                      <span style={styles.textoLimitado}>{option.name}</span>
                    </Stack>{" "}
                  </Tooltip>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  key="itensAutoText"
                  color="primary"
                  sx={styles.textField}
                  placeholder="Outros itens"
                  helperText={
                    typeElement == "Host"
                      ? itens?.length == 0 && "Esse elemento não contém itens"
                      : itens?.filter((item) => item.hostid === host?.hostid)
                          ?.length == 0 &&
                        host != null &&
                        "O host selecionado não contém itens"
                  }
                />
              )}
            />
            <Typography sx={styles.text}>
              Selecione a capacidade máxima (Gb)
            </Typography>
            <TextField
              placeholder="Capacidade"
              size="small"
              value={capacidade}
              type="number"
              onChange={(event) => {
                setCapacidade(event.target.value);
                setInteration(true);
              }}
              sx={styles.textField}
            />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
