import React, { memo } from "react";
import { FixedSizeList as List } from "react-window";
import { Box, Stack } from "@mui/material";
import CardCTO from "./index";

const VirtualizedCTOS = memo(
  ({ lista, search, style, iconsElements = [] }) => {
    const Row = ({ index, style }) => {
      const item = lista[index];
      return (
        <Box sx={style}>
          <CardCTO cto={item} search={search} iconsElements={iconsElements} />
        </Box>
      );
    };

    return (
      <Stack direction="column" gap={1} sx={[style, { paddingRight: "0px" }]}>
        <List
          key={lista.length + "listaCto"}
          height={window.innerHeight}
          itemCount={lista?.length}
          itemSize={100}
          width="100%"
        >
          {Row}
        </List>
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(
        prevProps.lista.map((item) => ({
          titulo: item.titulo,
          elementsClients: item.elementsClients,
        }))
      ) ==
      JSON.stringify(
        nextProps.lista.map((item) => ({
          titulo: item.titulo,
          elementsClients: item.elementsClients,
        }))
      )
    );
  }
);

export default VirtualizedCTOS;
