import {
  Box,
  Paper,
  Tabs,
  Tab,
  Stack,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Snackbar,
  Alert,
  Skeleton,
  Typography,
  Autocomplete,
  Checkbox,
  Grid,
  alpha,
} from "@mui/material";
import Alerte from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useEffect, useState } from "react";
import {
  Add,
  Search,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import GroupMaps from "../../../../componentes/easyMaps/groupMaps";
import { useOutletContext } from "react-router-dom";
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    mt: 2,
    mb: 1,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: 1,
  },
  headerBox: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },

  addIcon: { mt: -0.2 },
  headerPaper: {
    boxShadow: "0px 0px 5px  #0000001a",
    mb: "15px",

    padding: "16px",
    display: "flex",
    gap: "16px",
    flexDirection: "column",
    position: "relative",
    width: "100%",
  },

  button: { width: "148px", minWidth: "148px" },
  buttonDialog: {
    color: theme?.palette?.color?.buttonConfirm || "primary.main",
  },
  textfield: {
    width: "360px",
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paperLinkedMaps,
      boxShadow: "none",

      "&:hover": {
        backgroundColor: theme.palette.background.paperLinkedMaps,
      },
    },
  },
});

export default function GruposEasyMaps() {
  const { mapGroups, maps, loadingPage, setMaps, setMapGroups, api } =
    useOutletContext();
  const [tabValue, setTabValue] = useState(0);
  const [openNovoGrupoDialog, setOpenNovoGrupoDialog] = useState(false);

  const [loadingButtom, setLoadingButtom] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const handleCloseSnackbar = () => setSnackbar(null);
  const [mapsId, setMapsId] = useState([]);
  const theme = useTheme();
  const styles = useStyles(theme);
  const [titulo, setTitulo] = useState("");

  useEffect(() => {
    setTextFilter("");
  }, [tabValue]);

  const groupMapsFilter =
    mapGroups?.filter((group) => {
      return group.titulo.toLowerCase().includes(textFilter.toLowerCase());
    }) || [];

  const addNewMapInGroup = (newMap, index) => {
    let newMaps = [...mapGroups];
    if (!newMaps[index].maps) {
      newMaps[index].maps = [];
    }
    newMaps[index].maps.push(newMap);
    setMapGroups(newMaps);
  };

  const removeManyMapInGroup = (indexGroup, idMapArray) => {
    let newMaps = [...mapGroups];

    newMaps[indexGroup].maps = newMaps[indexGroup].maps.filter(
      (map) => !idMapArray.some((idObj) => idObj.id === map.id)
    );
    setMapGroups(newMaps);
  };

  const updateTitleGroup = (newTitle, indexGroup) => {
    const newMaps = [...mapGroups];
    newMaps[indexGroup].titulo = newTitle;
    setMapGroups(newMaps);
  };
  const removeGroup = (indexGroup) => {
    const newMaps = [...mapGroups];
    newMaps.splice(indexGroup, 1);
    setMapGroups(newMaps);
  };

  const addMapsInGroup = (mapArrayId, indexGroup) => {
    const newMaps = [...mapGroups];
    const mapsArray = maps.filter((map) =>
      mapArrayId.some((idObj) => idObj.id === map.id)
    );
    newMaps[indexGroup].maps = [...newMaps[indexGroup].maps, ...mapsArray];
    setMapGroups(newMaps);
  };

  const addMapInMaps = (map) => {
    const newMaps = [...maps];
    newMaps.push(map);
    setMaps(newMaps);
  };

  const handleApiNewGroupMap = async (titulo, mapsId) => {
    try {
      setLoadingButtom(true);
      const response = await api.post("/easyMaps/groupMaps/create", {
        titulo,
        mapsId: mapsId.map((map) => {
          return { mapsId: map.id };
        }),
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível criar mapa",
          severity: "error",
        });
      } else {
        if (response.data) {
          let newMaps = [...mapGroups];
          newMaps.push(response.data);
          let mapsArray = maps.filter((map) =>
            mapsId.some((idObj) => idObj.id === map.id)
          );
          newMaps[newMaps.length - 1].maps = [...mapsArray];
          setMapGroups(newMaps);
          setOpenNovoGrupoDialog(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingButtom(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.body}>
        <Stack width="100%">
          <Paper sx={styles.headerPaper}>
            <Box sx={styles.headerBox}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexWrap={"wrap"}
                gap={1}
              >
                <TextField
                  placeholder={"Buscar"}
                  autoComplete="off"
                  size="small"
                  value={textFilter}
                  onChange={(event) => {
                    setTextFilter(event.target.value);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: theme.palette.background.paperLinkedMaps,
                      boxShadow: "none",
                      width: "300px",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.background.paperLinkedMaps,
                      },
                    },
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    },
                  }}
                />

                <Button
                  color="primary"
                  variant="contained"
                  sx={styles.button}
                  endIcon={<Add sx={styles.addIcon} />}
                  onClick={() => {
                    setOpenNovoGrupoDialog(true);
                  }}
                >
                  Novo Grupo
                </Button>
              </Stack>
            </Box>
          </Paper>
        </Stack>
        {loadingPage ? (
          Array.from({ length: 4 }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              sx={{ width: "100%", height: "58px", borderRadius: "6px" }}
            />
          ))
        ) : (
          <>
            {Array.isArray(groupMapsFilter) &&
              groupMapsFilter.length > 0 &&
              groupMapsFilter.map((mapsGroup, index) => (
                <GroupMaps
                  groupsId={mapsGroup.id}
                  title={mapsGroup.titulo}
                  mapsGroup={mapsGroup.maps}
                  key={index}
                  index={index}
                  setSnackbar={setSnackbar}
                  addNewMapInGroup={addNewMapInGroup}
                  removeManyMapInGroup={removeManyMapInGroup}
                  updateTitleGroup={updateTitleGroup}
                  removeGroup={removeGroup}
                  maps={maps}
                  addMapInMaps={addMapInMaps}
                  addMapsInGroup={addMapsInGroup}
                />
              ))}
          </>
        )}
      </Box>
      <Dialog
        open={openNovoGrupoDialog}
        onClose={() => {
          setOpenNovoGrupoDialog(false);
        }}
      >
        <DialogTitle>Criar novo grupo</DialogTitle>
        <DialogContent>
          <Typography>Título</Typography>
          <TextField
            variant={"outlined"}
            placeholder="Título do grupo"
            sx={styles.textfield}
            autoComplete="off"
            onChange={(event) => {
              setTitulo(event.target.value);
            }}
          />
          <Typography>Adicionar mapas existentes</Typography>
          <Autocomplete
            multiple
            id="checkboxes-maps"
            options={maps}
            disableCloseOnSelect
            onChange={(event, newValue) => {
              setMapsId(newValue);
            }}
            getOptionLabel={(option) => (option?.titulo ? option?.titulo : "")}
            renderOption={(props, option, { selected }) => (
              <Box component="li" {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option?.titulo}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"outlined"}
                placeholder="Seleciona os mapas"
                sx={styles.textfield}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenNovoGrupoDialog(false);
            }}
          >
            CANCELAR
          </Button>
          <Button
            sx={styles.buttonDialog}
            onClick={() => {
              handleApiNewGroupMap(titulo, mapsId);
            }}
            disabled={loadingButtom || titulo === ""}
          >
            SALVAR
          </Button>
        </DialogActions>
      </Dialog>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
